import {Grid, Input, InputAdornment} from "@mui/material";
import {Search} from "@mui/icons-material";
import React, {useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import {NavigateFunction, useNavigate} from "react-router-dom";
import segmentEditorStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {getSegmentPath} from "../../../../routes/EditorRoute";
import MatchCaseAdornment from "./MatchCaseAdornment";

type SegmentsFiltersViewProperties = {
    filter: SegmentListFilter
};

export default function SegmentsFiltersView(props: SegmentsFiltersViewProperties) {
    const [source, setSource] = useState("");
    const [target, setTarget] = useState("");
    const [sourceMatchCase, setSourceMatchCase] = useState(false);
    const [targetMatchCase, setTargetMatchCase] = useState(false);

    const navigate = useNavigate();

    return (
        <Toolbar>
            <Grid container spacing={1} mt={0.5}>
                <Grid item xs={6}>
                    <Input size={"small"} fullWidth placeholder={"Search in source..."}
                           onChange={event => setSource(event.target.value)}
                           value={source}
                           onBlur={() => handleSaveFilter(
                               props.filter,
                               source,
                               target,
                               sourceMatchCase,
                               targetMatchCase,
                               setSource,
                               setTarget,
                               setSourceMatchCase,
                               setTargetMatchCase,
                               navigate)
                           }
                           onKeyDown={e => handelOnCompleteEdit(
                               e,
                               props.filter,
                               source,
                               target,
                               sourceMatchCase,
                               targetMatchCase,
                               setSource,
                               setTarget,
                               setSourceMatchCase,
                               setTargetMatchCase,
                               navigate)
                           }
                           startAdornment={
                               <InputAdornment position={"start"}>
                                   <Search/>
                               </InputAdornment>
                           }
                           endAdornment={<MatchCaseAdornment matchCase={sourceMatchCase}
                                                             setMatchCase={setSourceMatchCase}/>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input size={"small"} fullWidth placeholder={"Search in target..."}
                           onChange={event => setTarget(event.target.value)}
                           value={target}
                           onBlur={() => handleSaveFilter(
                               props.filter,
                               source,
                               target,
                               sourceMatchCase,
                               targetMatchCase,
                               setSource,
                               setTarget,
                               setSourceMatchCase,
                               setTargetMatchCase,
                               navigate)
                           }
                           onKeyDown={e => handelOnCompleteEdit(
                               e,
                               props.filter,
                               source,
                               target,
                               sourceMatchCase,
                               targetMatchCase,
                               setSource,
                               setTarget,
                               setSourceMatchCase,
                               setTargetMatchCase,
                               navigate)
                           }
                           startAdornment={
                               <InputAdornment position={"start"}>
                                   <Search/>
                               </InputAdornment>
                           }
                           endAdornment={<MatchCaseAdornment matchCase={targetMatchCase}
                                                             setMatchCase={setTargetMatchCase}/>}
                    />
                </Grid>
            </Grid>
        </Toolbar>
    );
}

function handleSaveFilter(filter: SegmentListFilter,
                          source: string,
                          target: string,
                          sourceMatchCase: boolean,
                          targetMatchCase: boolean,
                          setSource: (s: string) => void,
                          setTarget: (s: string) => void,
                          setSourceMatchCase: (v: boolean) => void,
                          setTargetMatchCase: (v: boolean) => void,
                          navigate: NavigateFunction) {
    let updatedFilter = filter.set("sourceMatchCase", sourceMatchCase).set("targetMatchCase", targetMatchCase);
    if (source)
        updatedFilter = updatedFilter.set("source", source);

    if (target)
        updatedFilter = updatedFilter.set("target", target);

    const currentSegment = segmentEditorStore.getState().segment;
    if (!currentSegment)
        return;
    navigate(getSegmentPath(updatedFilter, currentSegment.order), {replace: true});

    setSource("");
    setTarget("");
    setSourceMatchCase(false);
    setTargetMatchCase(false);
}

function handelOnCompleteEdit(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
                              filter: SegmentListFilter,
                              source: string,
                              target: string,
                              sourceMatchCase: boolean,
                              targetMatchCase: boolean,
                              setSource: (s: string) => void,
                              setTarget: (s: string) => void,
                              setSourceMatchCase: (v: boolean) => void,
                              setTargetMatchCase: (v: boolean) => void,
                              navigate: NavigateFunction) {
    if (event.key === "Enter") {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement) {
            activeElement.blur();
            handleSaveFilter(
                filter,
                source,
                target,
                sourceMatchCase,
                targetMatchCase,
                setSource,
                setTarget,
                setSourceMatchCase,
                setTargetMatchCase,
                navigate);
        }
    }
}