import {deletePermissions, fetchPermissions, savePermissions} from "../../../api/PermissionsApi";
import {CommonListActions} from "../../common/list/CommonListActions";
import {Permission} from "../../../model/Permission";
import PermissionsFilter from "../PermissionsFilter";
import {AvailableResourceType} from "../../../model/AvailableResources";

export const permissionsListActions
    = new CommonListActions<Permission, PermissionsFilter>(
    fetchPermissions,
    deletePermissions,
    savePermissions,
    AvailableResourceType.Permissions)