export type PretranslateMode = {
    mode: string,
    presentation: string;
}

const pretranslateModes: PretranslateMode[] = [
    {mode: 'Source', presentation: '100% Source'},
    {mode: 'SourceAndPrevOrNext', presentation: '101% Source with previous or next source'},
    {mode: 'SourceAndPrevAndNext', presentation: '102% Source with previous and next source'},
    {mode: 'SourceAndId', presentation: '103% Source and source ID'}
];

export default pretranslateModes;