import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    Container,
    ListItemSecondaryAction,
    ListItemText,
    Pagination,
    TextField
} from "@mui/material";
import Immutable from "immutable";

import List from "@mui/material/List";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {SegmentComment} from "../../../../model/SegmentComment";
import {Add} from "@mui/icons-material";
import commentListStore from "../../../../flux/comment/CommentListStore";

import {dateTimeFormat} from "../../../../globals/Utils";
import authStore from "../../../../flux/auth/AuthStore";
import {
    createCommentAction,
    deleteCommentAction,
    setCommentListPageNumberAction,
    updateCommentAction
} from "../../../../flux/comment/CommentListActions";

export default function CommentList() {
    const state = commentListStore.getState();
    const [page, setPage] = useState(state.page);
    const [isLoading, setIsLoading] = useState(state.isLoading);
    const [commentToUpdate, setCommentToUpdate] = useState<SegmentComment | null>(null);
    const [commentToCreate, setCommentToCreate] = useState<SegmentComment | null>(null);

    useEffect(() => {
        const commentListListener = commentListStore.addListener(() => {
            const state = commentListStore.getState();
            setPage(state.page);
            setIsLoading(state.isLoading);
        });

        return () => commentListListener.remove();
    }, []);

    if (isLoading)
        return <CircularProgress/>;

    const listItems = generateListItems(page.list,
        commentToCreate,
        setCommentToCreate,
        commentToUpdate,
        setCommentToUpdate);

    let addButton = <Container/>
    if (!commentToCreate && !commentToUpdate)
        addButton = generateAddButton(setCommentToCreate);

    return (
        <Container maxWidth={false}>
            <List>
                {listItems}
            </List>
            <Pagination count={page.totalPages}
                        onChange={(_event, pageNumber) => setCommentListPageNumberAction(pageNumber)}
                        page={page.number}/>
            {addButton}
        </Container>
    );
}

function generateAddButton(setCommentToCreate: React.Dispatch<React.SetStateAction<SegmentComment | null>>) {
    return (
        <ListItem key={`comment-add`}>
            <Button variant="contained"
                    startIcon={<Add/>}
                    onClick={() => {
                        const state = commentListStore.getState();
                        const toCreate = new SegmentComment(
                            {
                                segmentId: state.segmentId,
                                languageCode: state.languageCode
                            }
                        );
                        setCommentToCreate(toCreate);
                    }}>
                Add
            </Button>
        </ListItem>
    )
}

function generateListItems(list: Immutable.List<SegmentComment>,
                           commentToCreate: SegmentComment | null,
                           setCommentToCreate: React.Dispatch<React.SetStateAction<SegmentComment | null>>,
                           commentToUpdate: SegmentComment | null,
                           setCommentToUpdate: React.Dispatch<React.SetStateAction<SegmentComment | null>>) {
    let result = list
        .map((comment) => {
            if (commentToUpdate && comment.id === commentToUpdate.id)
                return generateUpdatableComment(comment, setCommentToUpdate);
            else
                return generateReadOnlyComment(comment, setCommentToUpdate);
        });

    if (commentToCreate)
        result = result.insert(0, generateCreateComment(commentToCreate, setCommentToCreate));

    return result;
}

function generateReadOnlyComment(comment: SegmentComment,
                                 setCommentToUpdate: React.Dispatch<React.SetStateAction<SegmentComment | null>>) {
    const author = authorText(comment)
    return (
        <ListItemButton key={`comment-${comment.id}-readonly`}>
            <ListItemSecondaryAction>
                {generateDeleteIcon(comment)}
            </ListItemSecondaryAction>
            <ListItemText primary={comment.text}
                          secondary={author}
                          onClick={() => {
                              if (commentIsAvailable(comment))
                                  setCommentToUpdate(comment)
                          }}
            />
        </ListItemButton>

    )
}

function generateUpdatableComment(comment: SegmentComment,
                                  setCommentToUpdate: React.Dispatch<React.SetStateAction<SegmentComment | null>>) {
    const onEdit = (text: string) => {
        if (text.trim() === "")
            return;

        const updated = comment.set("text", text);
        updateCommentAction(updated);
        setCommentToUpdate(null);
    };

    return (
        <ListItem secondaryAction={generateDeleteIcon(comment)} dense={true} key={`comment-${comment.id}`}>
            <TextField sx={{width: "100%"}}
                       autoFocus
                       helperText={authorText(comment)}
                       defaultValue={comment.text}
                       onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onPressEnter(e, onEdit)}
                       onBlur={(e) => onEdit(e.target.value)}
                       variant="outlined"/>
        </ListItem>)
}

function generateCreateComment(comment: SegmentComment,
                               setCommentToCreate: React.Dispatch<React.SetStateAction<SegmentComment | null>>) {
    const onEdit = (text: string) => {
        setCommentToCreate(null);
        if (text.trim() === "")
            return;

        const created = comment.set("text", text);
        createCommentAction(created);
    };
    return (
        <ListItem dense={true} key={`comment-${comment.id}-create`}>
            <TextField sx={{width: "100%"}}
                       autoFocus
                       helperText={"add your comment"}
                       defaultValue={comment.text}
                       onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onPressEnter(e, onEdit)}
                       onBlur={(e) => onEdit(e.target.value)}
                       variant="outlined"/>
        </ListItem>)
}

function generateDeleteIcon(comment: SegmentComment) {
    if (!commentIsAvailable(comment))
        return null;

    return (
        <IconButton edge="end"
                    aria-label="delete"
                    onClick={() => deleteCommentAction(comment.id)}>
            <DeleteIcon/>
        </IconButton>)
}

function authorText(comment: SegmentComment) {
    return `Created ${dateTimeFormat(comment.created)} by ${comment.authorName}`;
}

function onPressEnter(e: React.KeyboardEvent<HTMLInputElement>,
                      onEdit: (text: string) => void) {
    if (e.key !== 'Enter')
        return;

    e.preventDefault();
    let target = e.target as HTMLInputElement;
    onEdit(target.value);
}

function commentIsAvailable(comment: SegmentComment) {
    const user = authStore.getState().user;
    if (!user)
        return false;

    return user.id === comment.authorId;
}