import Placeholder from "../../../model/Placeholder";
import {List} from "immutable";
import {fetchPlaceholdersSetListAction} from "../list/PlaceholdersSetListActions";
import {dispatcher} from "../../Dispatcher";
import {setPlaceholders, setPlaceholdersSet} from "./PlaceholdersSetEditorPayload";
import {
    deletePlaceholdersSet,
    fetchPlaceholdersSet,
    fetchPlaceholdersSetList,
    updatePlaceholdersSet
} from "../../../api/PlaceholdersSetsApi";
import {Page} from "../../../model/Page";
import placeholdersSetsListStore from "../list/PlaceholdersSetListStore";
import {setPlaceholdersSetsList} from "../list/PlaceholdersSetListPayload";

export async function newPlaceholdersSetAction() {
    await fetchPlaceholdersSetListAction();
    dispatcher.dispatch(setPlaceholdersSet(null));
    dispatcher.dispatch(setPlaceholders(new Page<Placeholder>()));
}

export async function fetchPlaceholdersSetAction(id: number) {
    const set = await fetchPlaceholdersSet(id);
    dispatcher.dispatch(setPlaceholdersSet(set));
}

export async function updatePlaceholdersSetAction(currentName: string,
                                                  name: string,
                                                  placeholders: List<string>) {
    await updatePlaceholdersSet(currentName, name, placeholders);
    await fetchPlaceholdersSetListAction();
}

export async function deletePlaceholdersSetAction(id: number) {
    await deletePlaceholdersSet(id);
    await fetchPlaceholdersSetListAction();
}

export async function setPlaceholdersSetsPageNumberAction(pageNumber: number) {
    const page = placeholdersSetsListStore.getState().placeholdersSets.setPageNumber(pageNumber);
    const placeholdersSets = await fetchPlaceholdersSetList(page.pageable);
    dispatcher.dispatch(setPlaceholdersSetsList(placeholdersSets));
}