import Dropzone from "react-dropzone";
import {
    Button,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    ListItemText,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Immutable from "immutable";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import projectEditorStore from "../../../flux/project/editor/ProjectEditorStore";
import {styled} from "@mui/material/styles";
import {
    setMultiLanguageModeAction,
    setProjectFilesAction,
    setStepAction
} from "../../../flux/project/editor/ProjectEditorActions";

const FilesList = styled(List)({
    height: 200,
    overflow: 'auto',
    bgColor: '#ffffff',
    opacity: '0.75'
});

const MainContainer = styled('div')({
    height: 500,
    position: "relative"
});

const DropzoneContentContainer = styled('div')({
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    width: 360,
    height: 360,
    backgroundColor: "#f5f5f5",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)"
});

export default function ProjectFilesUploading() {
    const [files, setFiles]
        = useState<Immutable.List<File>>(Immutable.List<File>());
    const [multiLanguage, setMultiLanguage] = useState(false);

    useEffect(() => {
        const projectEditorListener = projectEditorStore.addListener(() => {
            const state = projectEditorStore.getState();
            setFiles(state.files);
            setMultiLanguage(state.multiLanguage);
        });
        return () => projectEditorListener.remove();
    });

    const drawSubmitButton = () => {
        return (
            <Button variant={"text"} onClick={() => setStepAction(1)}>
                {files.size === 0 ? "Skip" : "Next"}
            </Button>
        );
    }

    return (
        <Dropzone onDrop={acceptedFiles => handleFilesDrop(acceptedFiles, files)} noClick={true}
                  accept={{
                      'application/xliff+xml': ['.xliff', '.html', '.htm', '.docx', '.pptx', '.xlsx', '.po', '.md',
                          '.txt']
                  }}>
            {({getRootProps, getInputProps, open}) => {
                return (<div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()}/>
                    <MainContainer>
                        <DropzoneContentContainer>
                            <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
                                {drawFilesLoadingArea(files)}
                                <Stack alignItems={"center"} justifyContent={"space-around"}
                                       spacing={2}>
                                    <Button variant={"contained"} onClick={open}>Upload files</Button>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Switch onChange={event =>
                                                setMultiLanguageModeAction(event.target.checked)}
                                                    checked={multiLanguage}/>}
                                                          label={"Multi-language"}/>
                                    </FormGroup>
                                    {drawSubmitButton()}
                                </Stack>
                            </Stack>
                        </DropzoneContentContainer>
                    </MainContainer>
                </div>);
            }}
        </Dropzone>);
}

function handleFilesDrop(acceptedFiles: File[], files: Immutable.List<File>) {
    setProjectFilesAction(files.concat(acceptedFiles.filter(file =>
        !files.map(existingFile => existingFile.name).includes(file.name))));
}

function drawFilesLoadingArea(files: Immutable.List<File>) {
    if (files.size !== 0) {
        return (
            <FilesList>
                {files.map((file, index) => [
                    <ListItem key={file.name}>
                        <ListItemText primary={file.name}/>
                        <IconButton edge="end" aria-label="delete"
                                    onClick={() => setProjectFilesAction(files.delete(index))}>
                            <DeleteIcon/>
                        </IconButton>
                    </ListItem>,
                    <Divider key={"divider-" + index}/>
                ])}
            </FilesList>
        );
    } else {
        return (
            <Stack alignItems={"center"} justifyContent={"center"}>
                <img src={"/CloudArrowUp.svg"} alt={"Upload files"} width={120} height={120}/>
                <Typography fontSize={20} fontWeight={500}>Upload files</Typography>
                <Typography fontSize={14} fontWeight={400}>or just drag them here</Typography>
            </Stack>
        );
    }
}