import {Record} from "immutable";
import languageListStore from "../flux/language/list/LanguageListStore";

interface ILanguage {
    id: string,
    name: string,
    languageToolSupported: boolean
}

const LanguageRecord = Record<ILanguage>({
    id: "",
    name: "",
    languageToolSupported: false
});

export class Language extends LanguageRecord {
    static Empty = new Language();

    public isEmpty() {
        return this.id === "";
    }

    toString(): string {
        return this.id;
    }

    static fromSupported(code?: string) {
        if (!code)
            return Language.Empty;

        const supportedLanguages = languageListStore.getState().languages;
        const language = supportedLanguages.findByCode(code);

        if (language)
            return language;
        else
            return new Language({id: code, name: code});
    }
}