import Immutable, {List} from "immutable";
import {dispatcher} from "../../Dispatcher";
import {
    deleteVendorLinguists,
    deleteVendorManagers,
    fetchVendor,
    fetchVendorLinguists,
    fetchVendorManagers,
    saveVendor,
    saveVendorLinguists,
    saveVendorManagers
} from "../../../api/VendorApi";
import {setVendor} from "./VendorEditorPayload";
import {Vendor} from "../../../model/Vendor";
import {User} from "../../../model/User";
import {CommonListActions} from "../../common/list/CommonListActions";
import VendorUsersListFilter from "../VendorUsersListFilter";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {AvailableResourceType} from "../../../model/AvailableResources";

export async function fetchVendorAction(id: number) {
    const vendor = await fetchVendor(id);
    dispatcher.dispatch(setVendor(vendor));
    return vendor;
}

export async function saveVendorAction(vendor: Vendor) {
    const saved = await saveVendor(Immutable.List.of(vendor));
    return saved.toList().first();
}

export async function addVendorLinguistAction(vendor: Vendor, users: List<User>) {
    await saveVendorLinguists(vendor.id, users);
    await refresh();
}

export async function addVendorManagersAction(vendor: Vendor, users: List<User>) {
    await saveVendorManagers(vendor.id, users);
    await refresh();
}

export async function deleteVendorLinguistAction(vendor: Vendor, userIds: List<string>) {
    await deleteVendorLinguists(vendor.id, userIds);
    await refresh();
}

export async function deleteVendorManagersAction(vendor: Vendor, userIds: List<string>) {
    await deleteVendorManagers(vendor.id, userIds);
    await refresh();
}

async function refresh() {
    await vendorLinguistsListActions.refresh();
    await vendorManagersListActions.refresh();
}

export const vendorLinguistsListActions = new CommonListActions<User, VendorUsersListFilter>(
    fetchVendorLinguists,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation()
        return List<User>();
    },
    AvailableResourceType.Vendors
);

export const vendorManagersListActions = new CommonListActions<User, VendorUsersListFilter>(
    fetchVendorManagers,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation()
        return List<User>();
    },
    AvailableResourceType.Vendors
);

