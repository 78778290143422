import {Report, ReportState, ReportType} from "../../model/Report";

export function toReportModel(response: IReportResponse, variety: ReportType) {
    return new Report({
        id: response.id,
        state: ReportState[response.state as keyof typeof ReportState],
        created: new Date(Date.parse(response.created)),
        variety: variety
    });
}

export interface IReportResponse {
    id: number,
    state: string
    created: string
}