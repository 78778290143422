import axios from "axios";
import {Build} from "../model/Build";
import {toCatFileBuildModel} from "./model/CatFileApiModel";
import {downloadFile} from "../globals/ApiGlobals";

export function fetchFileBuild(build: Build) {
    return axios
        .get(endpoint + "/" + build.id)
        .then(response => toCatFileBuildModel(response.data));
}

export function downloadCatFile(build: Build) {
    downloadFile(endpoint, build);
}

const endpoint = "/api/projects/catFileBuilds"