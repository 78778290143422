import {Record} from "immutable";

interface IReportColumnType {
    field: string,
    headerName: string,
    width: number
}

const ReportColumnRecord = Record<IReportColumnType>({
    field: "",
    headerName: "",
    width: 150
});

export class ReportColumn extends ReportColumnRecord {
}
