import {deleteTranslationMemories, fetchTranslationMemories} from "../../../api/TranslationMemoryApi";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import TranslationMemory from "../../../model/TranslationMemory";
import {List} from "immutable";
import {CommonListActions} from "../../common/list/CommonListActions";
import TranslationMemoryFilter from "../TranslationMemoryFilter";
import {AvailableResourceType} from "../../../model/AvailableResources";

export const translationMemorySelectActions
    = new CommonListActions<TranslationMemory, TranslationMemoryFilter>(
    fetchTranslationMemories,
    deleteTranslationMemories,
    async () => {
        dispatchUnsupportedOperation();
        return List<TranslationMemory>();
    },
    AvailableResourceType.TranslationMemories
);