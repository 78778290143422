import {dispatchFetchListError} from "../globals/ApiGlobals";
import {List} from "immutable";
import {IUserResponse, toUserModel, toUserRequest} from "./model/UserApiModel";
import {User} from "../model/User";
import {Pageable} from "../model/Pageable";
import axios, {AxiosResponse} from "axios";
import {fetchUsers} from "./UserApi";
import UsersListFilter from "../flux/users/UsersListFilter";


export function fetchAllTenantsUsers(filter?: UsersListFilter,
                                     page?: Pageable) {
    return fetchUsers(filter, page, endpoint);
}

export function saveUsers(users: List<User>) {
    return axios
        .post(endpoint, users.map(value => toUserRequest(value)).toJSON())
        .then((response: AxiosResponse<IUserResponse>) => {
            return List.of(toUserModel(response.data));
        })
        .catch(dispatchFetchListError<User>);
}

const endpoint = "/api/common/all-tenants-users";
