import {Model} from "../../model/IModel";
import ListView, {ISelectViewProps} from "./ListView";
import {Button, Dialog} from "@mui/material";
import React from "react";
import {Set} from "immutable";
import {IFilter} from "../../model/Filter";

interface IProps<M extends Model, F extends IFilter<F>> extends ISelectViewProps<M, F> {
    open: boolean,
    onClose: () => void,
    onSelect: (selected: Set<M>) => void
}

export default function SelectDialog<M extends Model, F extends IFilter<F>>(props: IProps<M, F>) {

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <ListView {...props}/>
            <Button variant={"contained"}
                    onClick={() => {
                        props.onSelect(props.actions.selected);
                        props.actions.select(Set<M>());
                        props.onClose();
                    }}>
                Save
            </Button>
        </Dialog>
    )
}