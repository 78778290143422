import {List, Map, Record} from "immutable";
import {IFilter} from "../../model/Filter";
import {GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel} from "@mui/x-data-grid";
import {addSearchParam, updateWithGridFilterModel, updateWithGridSortingModel} from "../../utils/ListViewUtils";

export interface IGlossaryOperatorsFilter {
    nameOperator: string | null
}

export interface IGlossaryFilter extends IGlossaryOperatorsFilter {
    projectId: number | null,
    names: List<string> | null,
    name: string | null,
    orderBy: string | null,
    orderDirection: string | null
}

const GlossaryFilterRecord = Record<IGlossaryFilter>({
    projectId: null,
    names: null,
    name: null,
    nameOperator: null,
    orderBy: null,
    orderDirection: null
})

export default class GlossaryFilter extends GlossaryFilterRecord implements IFilter<GlossaryFilter> {
    updateWithGridFilterModel(model: GridFilterModel): GlossaryFilter {
        return updateWithGridFilterModel<IGlossaryFilter, GlossaryFilter>(this, model, AvailableGlossaryFilters);
    }

    updateWithGridSortingModel(model: GridSortModel): GlossaryFilter {
        return updateWithGridSortingModel<IGlossaryFilter, GlossaryFilter>(this, model, AvailableGlossarySortedFields);
    }

    toGridFilterModel(): GridFilterModel {
        const items: GridFilterItem[] = [];
        if (this.name && this.nameOperator)
            items.push({field: "name", operator: this.nameOperator, value: this.name});
        return {items: items};
    }

    toGridSortModel(): GridSortModel {
        if (this.orderBy && this.orderDirection) {
            const foundEntry = AvailableGlossarySortedFields
                .findEntry(value => value === this.orderBy);
            if (foundEntry === undefined)
                return [];
            return [{field: foundEntry[0], sort: this.orderDirection as GridSortDirection}];
        }
        return [];
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "name", this.name);
        addSearchParam(result, "nameOperator", this.nameOperator);
        addSearchParam(result, "orderBy", this.orderBy);
        addSearchParam(result, "orderDirection", this.orderDirection);

        return result.join("&");
    }

    static fromSearchParams(projectId: number | null,
                            names: List<string> | null,
                            searchParams: URLSearchParams) {
        let name = searchParams.get("name");
        if (name)
            name = decodeURIComponent(name);

        return new GlossaryFilter({
            projectId: projectId,
            names: names,
            name: name,
            nameOperator: searchParams.get("nameOperator"),
            orderBy: searchParams.get("orderBy"),
            orderDirection: searchParams.get("orderDirection")
        })
    }
}

const AvailableGlossaryFilters = Map<string, string>()
    .set('name', 'nameOperator');
const AvailableGlossarySortedFields = Map<string, string>()
    .set('name', 'name');