import {GridRenderCellParams} from "@mui/x-data-grid";
import {IconButton, ListItemIcon, Menu, MenuItem, Stack} from "@mui/material";
import React, {useState} from "react";
import PrivateComponent from "../PrivateComponent";
import {AvailableResourceType} from "../../model/AvailableResources";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Delete} from "@mui/icons-material";
import ProjectListItem from "../../model/ProjectListItem";
import {deleteProjectAction} from "../../flux/project/list/ProjectListActions";

type ProjectRowActionsProperties = {
    renderCellParams: GridRenderCellParams<ProjectListItem>,
}

export default function ProjectRowActions(props: ProjectRowActionsProperties) {
    const [anchorEl, setAnchorEl]
        = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    return (
        <Stack>
            <PrivateComponent resource={AvailableResourceType.Projects} deleteAllow={true}>
                <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                    <MoreVertIcon/>
                </IconButton>
            </PrivateComponent>
            <Menu open={menuOpen}
                  onClose={(e: Event) => handleMenuClosed(e, setAnchorEl)}
                  anchorEl={anchorEl}>
                <MenuItem onClick={e => handleDeleteClicked(e, setAnchorEl, props.renderCellParams.row)}>
                    <ListItemIcon>
                        <Delete/>
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
        </Stack>
    );
}

function handleMenuClosed(event: Event, setAnchorEl: (el: HTMLElement | null) => void) {
    setAnchorEl(null);
    event.stopPropagation();
}

function handleDeleteClicked(event: React.MouseEvent<HTMLElement>,
                             setAnchorEl: (el: HTMLElement | null) => void,
                             project: ProjectListItem) {
    setAnchorEl(null);
    deleteProjectAction(project.id);
    event.stopPropagation();
}