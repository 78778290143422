import PlaceholdersSet from "../../../model/PlaceholdersSet";
import {Page} from "../../../model/Page";

export enum PlaceholdersSetsListAction {
    SetList = "PlaceholdersSetsListAction.SetList"
}

interface ISetPlaceholdersSetListPayload {
    action: PlaceholdersSetsListAction.SetList,
    page: Page<PlaceholdersSet>
}

export type PlaceholdersSetListPayload = ISetPlaceholdersSetListPayload;

export function setPlaceholdersSetsList(placeholdersSets: Page<PlaceholdersSet>): PlaceholdersSetListPayload {
    return {
        action: PlaceholdersSetsListAction.SetList,
        page: placeholdersSets
    }
}