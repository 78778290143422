import {ReduceStore} from "flux/utils";
import {ProjectSearchAction, ProjectSearchPayload} from "./ProjectSearchPayload";
import {Record} from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";

interface IProjectSearchState {
    isOpen: boolean
}

const ProjectSearchStateFactory = Record<IProjectSearchState>({
    isOpen: false
});

class ProjectSearchState extends ProjectSearchStateFactory {
}

export class ProjectSearchStore extends ReduceStore<ProjectSearchState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ProjectSearchState {
        return new ProjectSearchState();
    }

    reduce(state: ProjectSearchState, payload: ProjectSearchPayload): ProjectSearchState {
        switch (payload.action) {
            case ProjectSearchAction.SetSearchMenuOpen:
                const isOpen = payload.searchMenuOpen;
                return isOpen ? state.set("isOpen", isOpen) : this.getInitialState();
            default:
                return state;
        }
    }
}

const projectSearchStore = new ProjectSearchStore();
export default projectSearchStore;