import {CommonBuildActions} from "./CommonBuildActions";
import Project from "../../../model/Project";
import {Build} from "../../../model/Build";
import {Model} from "../../../model/IModel";
import {buildProject, buildProjectTranslations} from "../../../api/ProjectApi";
import {downloadProject, fetchProjectBuild} from "../../../api/ProjectBuildApi";
import {CatFile} from "../../../model/CatFile";
import {buildFile, buildFileTranslations} from "../../../api/FileApi";
import {downloadCatFile, fetchFileBuild} from "../../../api/FileBuildApi";

class TranslationsBuildActions<M extends Model> extends CommonBuildActions<M> {
    constructor(build: (model: M) => Promise<Build>,
                buildTranslations: (model: M) => Promise<Build>,
                fetchBuild: (build: Build) => Promise<Build>,
                download: (build: Build) => void) {
        super( build, fetchBuild, download);
        this._buildTranslationsModel = buildTranslations;
    }

    private readonly _buildTranslationsModel: (model: M) => Promise<Build>;

    protected async receiveBuild(model: M, translationsMode: boolean) {
        if( translationsMode )
            return await this._buildTranslationsModel(model);
        return await this._buildModel(model);
    }
}

export const projectBuildActions
    = new TranslationsBuildActions<Project>(buildProject, buildProjectTranslations, fetchProjectBuild, downloadProject);
export const catFileBuildActions
    = new TranslationsBuildActions<CatFile>(buildFile, buildFileTranslations, fetchFileBuild, downloadCatFile);