import PrivateComponent from "../../../components/PrivateComponent";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {RollbackActivity} from "../../../components/activity/RollbackActivity";
import React from "react";

export default function ActivityRoute() {
    return (
        <PrivateComponent resource={AvailableResourceType.Events} readAllow={true}>
            <RollbackActivity/>
        </PrivateComponent>
    );
}

export const ActivityPath = "activity";
export const ActivityId = "Activity";