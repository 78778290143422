import axios, {AxiosResponse} from "axios";
import {
    buildUrl,
    dispatchError,
    dispatchFetchPageError,
    MultipartFormDataConfig,
    toParameters
} from "../globals/ApiGlobals";
import Glossary from "../model/Glossary";
import {List, Set} from "immutable";
import {
    IGlossaryBuildResponse,
    IGlossaryResponse,
    IPairSearchResponse,
    toGlossaryBuildModel,
    toGlossaryModel,
    toGlossaryPairSearchResultModel
} from "./model/GlossaryApiModel";
import {Page} from "../model/Page";
import GlossaryPairSearchResult from "../model/GlossaryPairSearchResult";
import {Build} from "../model/Build";
import {Pageable} from "../model/Pageable";
import GlossaryFilter from "../flux/glossary/GlossaryFilter";
import {EmbeddedResponse, EmbeddedResponseWithPagination} from "../globals/ApiModels";

export function fetchGlossaries(filter?: GlossaryFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<GlossaryFilter>(filter, page));
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponse<IGlossaryResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toGlossaryModel(value));
            return new Page<Glossary>()
                .setList(List(list));
        })
        .catch(dispatchFetchPageError<Glossary>)
}

export function buildGlossary(glossary: Glossary) {
    return axios
        .post(endpoint + "/" + encodeURIComponent(glossary.name) + "/build")
        .then((response: AxiosResponse<IGlossaryBuildResponse>) => toGlossaryBuildModel(response.data))
        .catch((error) => {
            dispatchError(error);
            return new Build();
        });
}

export function uploadGlossary(glossaryName: string, glossaryFile: File) {
    return axios
        .post(endpoint + "/" + encodeURIComponent(glossaryName),
            {file: glossaryFile},
            MultipartFormDataConfig)
        .then((response: AxiosResponse<IGlossaryResponse>) => toGlossaryModel(response.data))
        .catch((error) => {
            dispatchError(error);
            return new Glossary();
        });
}

export function deleteGlossaries(glossaries: Set<Glossary>) {
    return Promise
        .all(glossaries.map(value => deleteGlossary(value)))
        .then(() => {
        });
}

function deleteGlossary(glossary: Glossary) {
    return axios
        .delete(endpoint + "/" + glossary.id)
        .catch(dispatchError)
}

export function findPairs(text: string,
                          sourceLanguage: string,
                          targetLanguage: string,
                          glossaryNames: List<string>) {

    return axios
        .post(endpoint, {
            text: text,
            sourceLanguage: sourceLanguage,
            targetLanguage: targetLanguage,
            glossaryNames: glossaryNames.toArray()
        })
        .then((response: AxiosResponse<IPairSearchResponse>) => {
            const list = toGlossaryPairSearchResultModel(response.data);

            return new Page<GlossaryPairSearchResult>().setList(list);
        })
        .catch(dispatchFetchPageError<GlossaryPairSearchResult>);
}

export function isGlossaryNameExisted(name: string) {
    const filter = new GlossaryFilter({names: List.of(name)});
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<GlossaryFilter>(filter));
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IGlossaryResponse[]>>) => {
            return response
                .data
                ._embedded
                .length > 0;
        })
        .catch(error => {
            dispatchError(error)
            return false;
        });
}

const endpoint = "/api/glossaries/glossary";
