import {Vendor} from "../../model/Vendor";
import {emptyAsNull} from "../../globals/ApiGlobals";

export function toVendorModel(response: IVendorResponse) {
    return new Vendor({
        id: response.id,
        name: response.name
    });
}

export function toVendorRequest(vendor: Vendor) {
    return {
        id: emptyAsNull(vendor.id),
        name: vendor.name
    } as IVendorRequest
}

export interface IVendorResponse {
    id: number,
    name: string,
}

interface IVendorRequest {
    id: number | null,
    name: string,
}