import {Record} from "immutable";

export enum PermissionEntityPartType {
    Project = "Project",
    CatFile = "CatFile",
    Language = "Language",
    WorkflowStep = "WorkflowStep",
    Undefined = "Undefined"
}

interface IPermissionEntityPart {
    type: PermissionEntityPartType,
    id: string,
    title: string
}

const PermissionEntityPartRecord = Record<IPermissionEntityPart>({
    type: PermissionEntityPartType.Undefined,
    id: "",
    title: ""
});

export class PermissionEntityPart extends PermissionEntityPartRecord {
}
