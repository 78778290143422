import {List} from "immutable"
import {Permission} from "../../model/Permission";
import {PermissionEntityPart, PermissionEntityPartType} from "../../model/PermissionEntityPart";


export function toModel(response: IUserPermissionResponse) {
    const entityType = response
        .permissionEntity
        .map(value => new PermissionEntityPart({
            type: PermissionEntityPartType[value.type as keyof typeof PermissionEntityPartType],
            id: value.id,
            title: value.title
        }));

    return new Permission({
        id: response.id,
        userId: response.userId,
        username: response.username,
        permissionEntity: List(entityType),
        readAllowed: response.read
    });
}

export function toWebRequest(permissions: List<Permission>): IUserPermissionsRequest {
    const permissionsArray: IUserPermissionRequest[] = permissions
        .map(permission => {
            const permissionEntity = permission
                .permissionEntity
                .map(value => ({id: value.id, type: value.type} as IPermissionEntityPart))
                .toArray();

            return {
                id: permission.id,
                userId: permission.userId,
                permissionEntity: permissionEntity,
                read: permission.readAllowed
            }
        })
        .toArray();

    return {
        permissions: permissionsArray
    }
}

export interface IUserPermissionResponse {
    id: number,
    userId: string,
    username: string,
    permissionEntity: IPermissionEntityPart[],
    read: boolean
}

interface IUserPermissionsRequest {
    permissions: IUserPermissionRequest[]
}

interface IUserPermissionRequest {
    id: number,
    userId: string,
    permissionEntity: IPermissionEntityPart[],
    read: boolean
}

interface IPermissionEntityPart {
    type: string,
    id: string,
    title: string
}

