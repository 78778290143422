import {dispatcher} from "../Dispatcher";
import ResponseAlert, {ProjectAlert} from "../../model/Alert";
import {setAlert, setWaitingForAlert} from "./AlertPayload";

export function setAlertAction(alert: ResponseAlert | ProjectAlert) {
    dispatcher.dispatch(setAlert(alert));
}

export function setWaitingForAlertAction(waitingForAlert: boolean) {
    dispatcher.dispatch(setWaitingForAlert(waitingForAlert));
}

export function resetAlertAction() {
    dispatcher.dispatch(setAlert(null));
}
