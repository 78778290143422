export enum ProjectSearchAction {
    SetSearchMenuOpen = "ProjectSearchAction.SetSearchMenuOpen",
}

interface ISetSearchMenuOpen {
    action: ProjectSearchAction.SetSearchMenuOpen,
    searchMenuOpen: boolean
}

export type ProjectSearchPayload = ISetSearchMenuOpen;

export function setSearchMenuOpen(isOpen: boolean): ProjectSearchPayload {
    return {
        action: ProjectSearchAction.SetSearchMenuOpen,
        searchMenuOpen: isOpen
    }
}