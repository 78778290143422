import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError, dispatchFetchPageError} from "../globals/ApiGlobals";
import {SegmentComment} from "../model/SegmentComment";
import {Page} from "../model/Page";
import {List} from "immutable";
import {ICommentResponse, toModel} from "./model/CommentApiModel";
import {Pageable} from "../model/Pageable";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";

export function createComment(comment: SegmentComment) {
    return axios
        .post(endpoint, {
            segmentId: comment.segmentId,
            text: comment.text,
            languageCode: comment.languageCode
        })
        .catch(dispatchError);
}

export function updateComment(comment: SegmentComment) {
    const url = buildUrl(endpoint + "/:id", {id: comment.id});
    return axios
        .put(url, {
            segmentId: comment.segmentId,
            text: comment.text,
            languageCode: comment.languageCode
        })
        .catch(dispatchError);
}

export function deleteComment(id: number) {
    const url = buildUrl(endpoint + "/:id", {id: id});
    return axios
        .delete(url)
        .catch(dispatchError);
}

export function fetchComments(segmentId: number, languageCode: string, page: Pageable) {
    const backendPage = page.asBackendPage();
    const url = buildUrl(endpoint + "/search", {},
        {
            segmentId: segmentId,
            languageCode: languageCode,
            page: backendPage.number,
            size: backendPage.size
        });

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<ICommentResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toModel(value));
            return Page
                .fromBackendPage<SegmentComment>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<SegmentComment>);
}


const endpoint = "/api/projects/comments";