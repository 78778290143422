import {CatEventGroup} from "../../model/CatEventGroup";
import CatEventGroupFilter from "./CatEventGroupFilter";
import {fetchEventGroups, rollbackGroup} from "../../api/EventApi";
import {dispatchUnsupportedOperation} from "../../globals/ApiGlobals";
import {List} from "immutable";
import {AvailableResourceType} from "../../model/AvailableResources";
import {GroupedListActions} from "../common/list/GroupedListActions";
import {buildEventListActions} from "./EventListActions";
import {CatEvent} from "../../model/CatEvent";
import CatEventFilter from "./CatEventFilter";
import {endOfDay, startOfDay} from "../../globals/Utils";
import {checkRollbackInProgressAction, setRollbackInProgressAction} from "./rollback/RollbackActions";

class EventGroupListActions extends GroupedListActions<
    CatEventGroup,
    CatEventGroupFilter,
    CatEvent,
    CatEventFilter
> {

    constructor() {
        super(fetchEventGroups,
            async () => dispatchUnsupportedOperation(),
            async () => {
                dispatchUnsupportedOperation();
                return List<CatEventGroup>();
            },
            buildEventListActions,
            group => new CatEventFilter({
                catFileId: group.catFileId,
                eventType: group.eventType,
                projectId: group.projectId,
                eventObjectType: group.eventObjectType,
                userId: group.userId,
                from: startOfDay(group.date),
                to: endOfDay(group.date)
            }),
            AvailableResourceType.Events
        );
    }

    public rollback(group: CatEventGroup) {
        setRollbackInProgressAction(true);
        rollbackGroup(group);
    }
}

export const eventGroupListActions = new EventGroupListActions();

setInterval(() => {
    if (!eventGroupListActions)
        return;
    checkRollbackInProgressAction(eventGroupListActions.store.getState().filter)
}, 1000);

eventGroupListActions.store.addListener(() => {
    checkRollbackInProgressAction(eventGroupListActions.store.getState().filter)
})