import {Map, Record} from "immutable";
import {IFilter} from "../../model/Filter";
import {GridFilterItem, GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {addSearchParam, updateWithGridFilterModel, updateWithGridSortingModel} from "../../utils/ListViewUtils";

export interface IPermissionsOperatorsFilter {
    catFileOperator: string | null,
    languageOperator: string | null,
    workflowOperator: string | null,
    readAllowedOperator: string | null
}

export interface IPermissionsFilter extends IPermissionsOperatorsFilter {
    projectId: number,
    catFile: string | null,
    language: string | null,
    workflow: string | null,
    readAllowed: boolean | null,
    orderBy: string | null,
    orderDirection: string | null
}

const PermissionsFilterRecord = Record<IPermissionsFilter>({
    projectId: 0,
    catFile: null,
    catFileOperator: null,
    language: null,
    languageOperator: null,
    workflow: null,
    workflowOperator: null,
    readAllowed: null,
    readAllowedOperator: null,
    orderBy: null,
    orderDirection: null
})

export default class PermissionsFilter extends PermissionsFilterRecord implements IFilter<PermissionsFilter> {
    updateWithGridFilterModel(model: GridFilterModel): PermissionsFilter {
        return updateWithGridFilterModel<IPermissionsFilter, PermissionsFilter>(
            this,
            model,
            AvailablePermissionFilters);
    }

    updateWithGridSortingModel(model: GridSortModel): PermissionsFilter {
        return updateWithGridSortingModel<IPermissionsFilter, PermissionsFilter>(
            this,
            model,
            AvailablePermissionSortedFields);
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "catFile", this.catFile);
        addSearchParam(result, "catFileOperator", this.catFileOperator );
        addSearchParam(result, "language", this.language);
        addSearchParam(result, "languageOperator", this.languageOperator);
        addSearchParam(result, "workflow", this.workflow);
        addSearchParam(result, "workflowOperator", this.workflowOperator);
        addSearchParam(result, "readAllowed", this.readAllowed);
        addSearchParam(result, "readAllowedOperator", this.readAllowedOperator);

        return result.join("&");
    }

    toGridFilterModel(): GridFilterModel {
        const items: GridFilterItem[] = [];
        if (this.catFile && this.catFileOperator)
            items.push({field: "catFile", operator: this.catFileOperator, value: this.catFile});
        if (this.language && this.languageOperator)
            items.push({field: "language", operator: this.languageOperator, value: this.language});
        if (this.workflow && this.workflowOperator)
            items.push({field: "workflow", operator: this.workflowOperator, value: this.workflow});
        if (this.readAllowed && this.readAllowedOperator)
            items.push({field: "readAllowed", operator: this.readAllowedOperator, value: this.readAllowed});
        return {items: items};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }

    static fromSearchParams(projectId: number, searchParams: URLSearchParams) {
        let catFile = searchParams.get("catFile");
        if (catFile)
            catFile = decodeURIComponent(catFile);

        let language = searchParams.get("language");
        if (language)
            language = decodeURIComponent(language);

        let workflow = searchParams.get("workflow");
        if (workflow)
            workflow = decodeURIComponent(workflow);

        let readAllowed = null;
        switch(searchParams.get("readAllowed")){
            case "any":
                readAllowed = null;
                break;
            case "true":
                readAllowed = true;
                break;
            case "false":
                readAllowed = false;
                break;
        }

        return new PermissionsFilter({
            projectId: projectId,
            catFile: catFile,
            catFileOperator: searchParams.get("catFileOperator"),
            language: language,
            languageOperator: searchParams.get("languageOperator"),
            workflow: workflow,
            workflowOperator: searchParams.get("workflowOperator"),
            readAllowed: readAllowed,
            readAllowedOperator: searchParams.get("readAllowedOperator")
        });
    }
}

const AvailablePermissionFilters = Map<string, string>()
    .set('catFile', 'catFileOperator')
    .set('language', 'languageOperator')
    .set('workflow', 'workflowOperator')
    .set('readAllowed', 'readAllowedOperator');
const AvailablePermissionSortedFields = Map<string, string>();