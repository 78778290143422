import Immutable, {List} from "immutable";
import {Statistic} from "./Statistic";
import {GroupedStatistic} from "./GroupedStatistic";

interface IProjectListItem {
    id: number,
    name: string,
    source: string,
    targets: List<string>,
    statistic: List<Statistic>
}

const ProjectListItemRecord = Immutable.Record<IProjectListItem>({
    id: 0,
    name: '',
    source: '',
    targets: List(),
    statistic: List<Statistic>(),
})

export default class ProjectListItem extends ProjectListItemRecord {
    get groupedStatistic() {
        return new GroupedStatistic({
            statistic: this.statistic
        });
    }
}