import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import {selectGlossaryPairAction, selectTMSuggestAction} from "../../../../flux/segment/editor/SegmentEditorActions";
import React from "react";
import {BreakWordGrid, CustomChip, SearchResultGrid, SmallText} from "../../../../globals/CommonComponents";
import GlossaryPairSearchResult from "../../../../model/GlossaryPairSearchResult";
import {SearchResultPrefix} from "./ExistingTranslationsView";

type GlossarySearchResultProps = {
    index: number,
    isSelected: boolean,
    searchResult: GlossaryPairSearchResult,
    onClick: (index: number) => void,
    onDoubleClick: () => void
}

export default function GlossarySearchResult(props: GlossarySearchResultProps) {

    const searchResultId = SearchResultPrefix + props.index;

    return <Box sx={{padding: 1}} key={searchResultId}>
        <SearchResultGrid container alignItems={"flex-start"} justifyContent={"flex-start"}
                          isSelected={props.isSelected}
                          onClick={() => {
                              props.onClick(props.index);
                              selectGlossaryPairAction(props.searchResult);
                              selectTMSuggestAction(null);
                          }}
                          onDoubleClick={props.onDoubleClick}
                          id={searchResultId} tabIndex={0}>
            <Grid item xs={2} textAlign={"center"}>
                <SmallText>{props.index + 1}</SmallText>
            </Grid>
            <BreakWordGrid item xs={4}>
                <SmallText>{props.searchResult.source}</SmallText>
            </BreakWordGrid>
            <Grid item xs={2} textAlign={"center"}>
                <CustomChip label={"TB"} size={"small"}/>
            </Grid>
            <BreakWordGrid item xs={4}>
                <SmallText>{props.searchResult.target}</SmallText>
            </BreakWordGrid>
        </SearchResultGrid>
    </Box>;
}