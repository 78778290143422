import {ReduceStore} from "flux/utils";
import Immutable from "immutable";
import {AlertAction, AlertPayload} from "./AlertPayload";
import {dispatcher, Payload} from "../Dispatcher";
import ResponseAlert, {ProjectAlert} from "../../model/Alert";

interface IAlertState {
    alert: ResponseAlert | ProjectAlert | null,
    waitingForAlert: boolean
}

const AlertStateFactory = Immutable.Record<IAlertState>({
    alert: null,
    waitingForAlert: false
});

class AlertState extends AlertStateFactory {
}

export class AlertStore extends ReduceStore<AlertState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): AlertState {
        return new AlertState();
    }

    reduce(state: AlertState, payload: AlertPayload): AlertState {
        switch (payload.action) {
            case AlertAction.SetAlert:
                return state.set("alert", payload.alert);
            case AlertAction.SetWaitingForAlert:
                return state.set("waitingForAlert", payload.waitingForAlert);
            default:
                return state;
        }
    }
}

const alertStore = new AlertStore();
export default alertStore;
