import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError, dispatchFetchPageError} from "../globals/ApiGlobals";
import PlaceholdersSet from "../model/PlaceholdersSet";
import {List} from "immutable";
import {IPlaceholdersSetResponse, toPlaceholderSetModel} from "./model/PlaceholdersApiModel";
import {Page} from "../model/Page";
import {Pageable} from "../model/Pageable";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";

export function fetchPlaceholdersSetList(page: Pageable) {
    const backendPage = page.asBackendPage();
    const url = buildUrl(endpoint, {}, {
        size: backendPage.size,
        number: backendPage.number
    });

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IPlaceholdersSetResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toPlaceholderSetModel(value));
            return new Page<PlaceholdersSet>().setList(List(list));
        })
        .catch(dispatchFetchPageError<PlaceholdersSet>);
}

export function fetchPlaceholdersSet(id: number) {
    return axios
        .get(endpoint + "/" + id)
        .then((response: AxiosResponse<IPlaceholdersSetResponse>) => toPlaceholderSetModel(response.data))
        .catch(error => {
            dispatchError(error);
            return new PlaceholdersSet();
        });
}

export function updatePlaceholdersSet(currentName: string, name: string, placeholders: List<string>) {
    return axios
        .post(endpoint + "/" + currentName, {
            name: name,
            placeholders: placeholders.toArray()
        })
        .catch(dispatchError)
}

export function deletePlaceholdersSet(id: number) {
    return axios
        .delete(endpoint + "/" + id)
        .catch(dispatchError)
}

export function validatePlaceholder(placeholder: string): Promise<boolean> {
    return axios
        .post(endpoint + "/validate", {placeholder: placeholder})
        .then(() => true)
        .catch(() => false);
}

const endpoint = "/api/glossaries/placeholdersSets";