import {dispatcher} from "../../Dispatcher";
import {setPlaceholdersSetsList} from "./PlaceholdersSetListPayload";
import {fetchPlaceholdersSetList} from "../../../api/PlaceholdersSetsApi";
import placeholdersSetsListStore from "./PlaceholdersSetListStore";
import availableResourcesStore from "../../available-resources/AvailableResourcesStore";
import {AvailableResourceType} from "../../../model/AvailableResources";

export async function fetchPlaceholdersSetListAction() {
    const available = availableResourcesStore
        .getState()
        .resources
        .isAvailableResource(AvailableResourceType.Placeholders, true, false, false);

    if (!available)
        return;

    const page = placeholdersSetsListStore.getState().placeholdersSets;
    const placeholdersSetsList = await fetchPlaceholdersSetList(page.pageable);
    dispatcher.dispatch(setPlaceholdersSetsList(placeholdersSetsList));
}