import GroupedActivityList from "./GroupedActivityList";
import {Stack} from "@mui/material";
import {RollbackInProgress} from "./RollbackInProgress";
import {useProject} from "../project/ProjectView";
import {useEffect} from "react";
import {eventGroupListActions} from "../../flux/event/EventGroupListActions";
import CatEventGroupFilter from "../../flux/event/CatEventGroupFilter";

export function RollbackActivity() {
    const {project} = useProject();

    useEffect(() => {
        eventGroupListActions.fetch(new CatEventGroupFilter({projectId: project.id}));
    }, [project]);

    return (
        <Stack>
            <GroupedActivityList/>
            <RollbackInProgress/>
        </Stack>
    );
}
