import React from "react";
import {OverflowContainer} from "../../../globals/CommonComponents";
import SegmentListFilter from "../../../flux/segment/list/SegmentListFilter";
import SegmentListEditor from "./segments/SegmentListEditor";

type TopLeftFrameProperties = {
    filter: SegmentListFilter
}

export default function TopLeftFrame(props: TopLeftFrameProperties) {

    return (
        <OverflowContainer maxWidth={false}>
            <SegmentListEditor filter={props.filter}/>
        </OverflowContainer>
    );
}
