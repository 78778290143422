import {LanguageList} from "../../../model/LanguageList";

export enum LanguageListAction {
    SetList = "LanguageListAction.SetList"
}

interface ISetLanguageList {
    action: LanguageListAction.SetList,
    languages: LanguageList
}

export type LanguageListPayload = ISetLanguageList;

export function setLanguageList(languages: LanguageList): LanguageListPayload {
    return {
        action: LanguageListAction.SetList,
        languages: languages
    }
}