import TenantsList from "../../components/admin/TenantsList";

export default function TenantsRoute(){
    return (<TenantsList/>);
}

export function getTenantsPath() {
    return TenantsPath;
}

export const TenantsId = "Tenants";
export const TenantsPath = "tenants";