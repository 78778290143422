import TranslationMemory from "../../../model/TranslationMemory";

export enum TranslationMemoryEditorAction {
    SetTm = "TranslationMemoryEditorAction.SetTm",
    SetIsTmExisted = "TranslationMemoryEditorAction.SetIsTmExisted"
}

interface ISetTmPayload {
    action: TranslationMemoryEditorAction.SetTm,
    tm: TranslationMemory
}

interface ISetIsTmExistedPayload {
    action: TranslationMemoryEditorAction.SetIsTmExisted,
    isExisted: boolean
}

export type TranslationMemoryEditorPayload = ISetTmPayload
    | ISetIsTmExistedPayload;

export function setEditedTranslationMemory(tm: TranslationMemory): TranslationMemoryEditorPayload {
    return {
        action: TranslationMemoryEditorAction.SetTm,
        tm: tm
    }
}

export function setIsTmExistedPayload(isExisted: boolean): TranslationMemoryEditorPayload {
    return {
        action: TranslationMemoryEditorAction.SetIsTmExisted,
        isExisted: isExisted
    }
}
