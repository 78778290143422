import {Record} from "immutable";

interface IStatisticMetric {
    totalWords: number,
    translatedWords: number
}

const StatisticsMetricRecord = Record<IStatisticMetric>({
    totalWords: 0,
    translatedWords: 0
})

export class StatisticMetric extends StatisticsMetricRecord {
    public addTranslated(metric: StatisticMetric) {
        return new StatisticMetric({
            totalWords: this.totalWords,
            translatedWords: this.translatedWords + metric.translatedWords,
        })
    }

    public addTotalWords(metric: StatisticMetric) {
        return new StatisticMetric({
            totalWords: this.totalWords + metric.totalWords,
            translatedWords: this.translatedWords,
        })
    }

    public get percent() {
        if (this.totalWords === 0)
            return 0;

        return
    }
}