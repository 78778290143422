import availableResourcesStore from "../flux/available-resources/AvailableResourcesStore";
import {SimpleTab} from "../globals/CommonComponents";
import React, {useEffect, useState} from "react";
import {AvailableResourceType} from "../model/AvailableResources";
import {List} from "immutable";

type PrivateTabProperties = {
    readAllow: boolean,
    writeAllow: boolean,
    deleteAllow: boolean,
    resources: List<AvailableResourceType>,
    label: string
};

export default function PrivateTab({
                                       readAllow,
                                       writeAllow,
                                       deleteAllow,
                                       resources,
                                       label,
                                       ...props
                                   }: PrivateTabProperties) {
    const [availableResources, setAvailableResources]
        = useState(availableResourcesStore.getState().resources);

    useEffect(() => {
        const availableResourcesListener = availableResourcesStore.addListener(() => {
            const state = availableResourcesStore.getState();
            setAvailableResources(state.resources);
        });

        return () => availableResourcesListener.remove();
    }, []);

    const available = availableResources.isAvailableResources(resources, readAllow, writeAllow, deleteAllow);
    if (!available)
        return null;

    return <SimpleTab label={label} {...props}/>;
}