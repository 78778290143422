import {Container} from "@mui/material";
import React from "react";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "../common/ListView";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {CatEvent} from "../../model/CatEvent";
import {dateTimeFormat} from "../../globals/Utils";
import {eventListActions} from "../../flux/event/EventListActions";
import {eventTypePresentation} from "../../model/CatEventType";
import CatEventFilter from "../../flux/event/CatEventFilter";

export default function ActivityList() {
    return (
        <Container maxWidth={false}>
            <ListView actions={eventListActions} columns={columns} selection={false} navigateProps={null}
                      initialFilter={new CatEventFilter()}/>
        </Container>
    );
}

const columns: GridColDef[] = [
    {
        field: 'username',
        filterable: false,
        sortable: false,
        headerName: 'username',
        width: 150,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.username
    },
    {
        field: 'event',
        filterable: false,
        sortable: false,
        headerName: 'event',
        width: 150,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => eventTypePresentation(params.row.eventType)
    },
    {
        field: 'target',
        filterable: false,
        sortable: false,
        headerName: 'target',
        width: 350,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.dataAsJson.target
    },
    {
        field: 'workflowStepName',
        filterable: false,
        sortable: false,
        headerName: 'workflow',
        width: 120,
    },
    {
        field: 'bestMatchScore',
        filterable: false,
        sortable: false,
        headerName: 'TM match',
        width: 100,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.bestMatchScore
    },
    {
        field: 'date',
        filterable: false,
        sortable: false,
        headerName: 'date',
        width: 150,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => dateTimeFormat(params.row.date)
    },
]