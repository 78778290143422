import {Container} from "@mui/material";
import PageBreadcrumbs from "../PageBreadcrumbs";
import {useLoaderData} from "react-router-dom";
import PlaceholdersSetEditor from "./PlaceholdersSetEditor";
import PlaceholdersSet from "../../model/PlaceholdersSet";

export default function PlaceholdersSetView() {
    const loaderData = useLoaderData() as PlaceholdersSet;

    return (
        <Container maxWidth={false}>
            <PageBreadcrumbs/>
            <PlaceholdersSetEditor/>
        </Container>
    );
}