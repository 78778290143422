import {ReduceStore} from "flux/utils";
import {AvailableResourcesAction, AvailableResourcesPayload} from "./AvailableResourcesPayload";
import Immutable from "immutable";
import {dispatcher, Payload} from "../Dispatcher";
import {AvailableResources} from "../../model/AvailableResources";

interface IAvailableResourcesState {
    resources: AvailableResources
}

const AvailableResourcesStateFactory = Immutable.Record<IAvailableResourcesState>({
    resources: new AvailableResources()
});

class AvailableResourcesState extends AvailableResourcesStateFactory {
}

export class AvailableResourcesStore extends ReduceStore<AvailableResourcesState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): AvailableResourcesState {
        return new AvailableResourcesState();
    }

    reduce(state: AvailableResourcesState, payload: AvailableResourcesPayload): AvailableResourcesState {
        switch(payload.action) {
            case AvailableResourcesAction.Set:
               return state.set("resources", payload.resources);
            default:
                return state;
        }
    }
}

const availableResourcesStore = new AvailableResourcesStore();
export default availableResourcesStore;