import {createComment, deleteComment, fetchComments, updateComment} from "../../api/CommentsApi";
import {SegmentComment} from "../../model/SegmentComment";
import {dispatcher} from "../Dispatcher";
import {commentListIsLoadingPayload, setCommentListFiltersPayload, setCommentListPayload} from "./CommentListPayload";
import commentListStore from "./CommentListStore";
import {Pageable} from "../../model/Pageable";
import {updateCurrentSegmentInListAction} from "../segment/list/SegmentListActions";
import {Segment} from "../../model/Segment";

export async function fetchCommentsAction(segmentId: number, languageCode: string, page: Pageable) {
    dispatcher.dispatch(commentListIsLoadingPayload(true));

    const comments = await fetchComments(segmentId, languageCode, page);
    dispatcher.dispatch(setCommentListFiltersPayload(segmentId, languageCode));
    dispatcher.dispatch(setCommentListPayload(comments));
    dispatcher.dispatch(commentListIsLoadingPayload(false));
}

export async function setCommentListPageNumberAction(page: number) {
    const state = commentListStore.getState()
    await fetchCommentsAction(state.segmentId, state.languageCode, state.page.pageable.set("number", page));
}

export async function createCommentAction(comment: SegmentComment) {
    await createComment(comment);
    await refreshCommentList();
    updateCurrentSegmentInListAction((value: Segment | undefined) => value ? value.incrementCommentsCount() : value);
}

export async function updateCommentAction(comment: SegmentComment) {
    await updateComment(comment);
    await refreshCommentList();
}

export async function deleteCommentAction(id: number) {
    await deleteComment(id);
    await refreshCommentList();
    updateCurrentSegmentInListAction((value: Segment | undefined) => value ? value.decrementCommentsCount() : value);
}

export async function refreshCommentList() {
    const state = commentListStore.getState()
    await fetchCommentsAction(state.segmentId, state.languageCode, state.page.pageable);
}