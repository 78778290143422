import {SegmentComment} from "../../model/SegmentComment";

export function toModel(response: ICommentResponse) {
    return new SegmentComment({
        id: response.id,
        segmentId: response.segmentId,
        text: response.text,
        languageCode: response.target,
        authorName: response.authorName,
        authorId: response.authorId,
        created: new Date(Date.parse(response.created)),
    });
}

export interface ICommentResponse {
    id: number,
    segmentId: number,
    text: string,
    target: string,
    authorName: string,
    authorId: string,
    created: string
}
