import {List, Record} from "immutable";
import {IReportData} from "./ReportDataType";

interface IProjectReportDimension {
    catFileId: number
    catFileName: string,
    languageCode: string
}

interface IProjectReportData extends IReportData {
    dimension: IProjectReportDimension,
}

const ReportDataRecord = Record<IProjectReportData>({
    dimension: {
        catFileId: 0,
        catFileName: "",
        languageCode: ""
    },
    columns: List(),
    rows: List(),
});

export class ProjectReportData extends ReportDataRecord {
}

