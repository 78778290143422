import {ReduceStore} from "flux/utils";
import {ReportDataAction, ReportDataPayload} from "./ReportDataPayload";
import {dispatcher, Payload} from "../../Dispatcher";
import {Record} from 'immutable';
import {Report} from "../../../model/Report";
import {ReportDataType} from "../../../model/ReportDataType";
import {Page} from "../../../model/Page";

interface IReportDataState {
    report: Report,
    data: Page<ReportDataType>
    isLoading: boolean
}


const ReportDataStateFactory = Record<IReportDataState>({
    report: new Report(),
    data: new Page<ReportDataType>(),
    isLoading: false
});

class ReportDataState extends ReportDataStateFactory {
}

export class ReportDataStore extends ReduceStore<ReportDataState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ReportDataState {
        return new ReportDataState();
    }

    reduce(state: ReportDataState, payload: ReportDataPayload): ReportDataState {
        switch (payload.action) {
            case ReportDataAction.SetReport:
                return state
                    .set("report", payload.report)
                    .set("data", payload.data)
            case ReportDataAction.SetIsLoading:
                return state.set("isLoading", payload.isLoading)
        }
        return state;
    }
}

const reportDataStore = new ReportDataStore();
export default reportDataStore;