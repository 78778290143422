import Immutable from "immutable";

export enum ProjectEditorAction {
    SetIsOpen = "ProjectEditorAction.SetIsOpen",
    SetFiles = "ProjectEditorAction.SetFiles",
    SetStep = "ProjectEditorAction.SetStep",
    SetMultiLanguageMode = "ProjectEditorAction.SetMultiLanguageMode"
}

interface ISetFilesPayload {
    action: ProjectEditorAction.SetFiles,
    files: Immutable.List<File>
}

interface ISetStep {
    action: ProjectEditorAction.SetStep,
    step: number
}

interface ISetIsOpen {
    action: ProjectEditorAction.SetIsOpen,
    open: boolean
}

interface ISetMultiLanguageMode {
    action: ProjectEditorAction.SetMultiLanguageMode,
    multiLanguageMode: boolean
}

export type ProjectEditorPayload = ISetFilesPayload | ISetStep | ISetIsOpen | ISetMultiLanguageMode;

export function setProjectFiles(files: Immutable.List<File>): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetFiles,
        files: files
    }
}

export function setStep(step: number): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetStep,
        step: step
    }
}

export function setIsOpen(open: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetIsOpen,
        open: open
    }
}

export function setMultiLanguageMode(multiLanguage: boolean): ProjectEditorPayload {
    return {
        action: ProjectEditorAction.SetMultiLanguageMode,
        multiLanguageMode: multiLanguage
    }
}