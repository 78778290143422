import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Typography
} from "@mui/material";
import {ProjectTranslationMemory} from "../../../../model/TranslationMemory";
import {BuildState} from "../../../../model/Build";

type ProjectTranslationMemoryStateProperties = {
    row: ProjectTranslationMemory
}

export default function ProjectTranslationMemoryState(props: ProjectTranslationMemoryStateProperties) {
    const [open, setOpen] = useState(false);

    if (props.row.errors && props.row.errors.length > 0) {
        return <React.Fragment>
            <Link component={"button"} onClick={() => setOpen(true)}>Error</Link>
            <Dialog open={open} onClose={() => setOpen(false)} scroll={"paper"}>
                <DialogTitle>
                    {"Records added: " + props.row.writtenSegments + " of " + props.row.totalSegments}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText>Errors:</DialogContentText>
                    {props.row.errors.map(error => <DialogContentText>{error}</DialogContentText>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>;
    }

    switch (props.row.state) {
        case BuildState.Available:
            return <Typography fontSize={14}>Complete</Typography>;
        default:
            return <Typography fontSize={14}>{props.row.state}</Typography>;
    }
}