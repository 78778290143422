import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchFetchPageError} from "../globals/ApiGlobals";
import {List} from 'immutable';
import {Report} from "../model/Report";
import {ProjectReportData} from "../model/ProjectReportData";
import {AssigneeReportData} from "../model/AssigneeReportData";
import {
    IAssigneeReportResponse,
    IProjectsReportResponse,
    toModelAssigneeReport,
    toModelProjectReport
} from "./model/ReportDataApiModel";
import {Page} from "../model/Page";
import {EmbeddedResponse} from "../globals/ApiModels";

export function fetchProjectReportData(report: Report) {
    const dataEndpoint = endpoint + "/project-report/:id/report";
    let url = buildUrl(dataEndpoint, {id: report.id});
    const fetchNoDimension = fetchProjectReportDataPromise(url);

    //TODO: Enable after resolve performance issue
    // url = buildUrl(dataEndpoint, {id: report.id}, {dimensions: "CatFile"});
    // const fetchByCatFile = fetchProjectReportDataPromise(url);
    return Promise
        .all([fetchNoDimension])
        .then(([noDimension]) => {
            return new Page<ProjectReportData>().setList(List(noDimension));
        })
        .catch(dispatchFetchPageError<ProjectReportData>);
}

export function fetchAssigneeReportData(report: Report) {
    const dataEndpoint = endpoint + "/translation-assignee/:id/report";
    let url = buildUrl(dataEndpoint, {id: report.id});
    const fetchNoDimension = fetchAssigneeReportDataPromise(url);

    //TODO: Enable after resolve performance issue
    // url = buildUrl(dataEndpoint, {id: report.id}, {dimensions: "CatFile"});
    // const fetchByCatFile = fetchAssigneeReportDataPromise(url)

    return Promise
        .all([fetchNoDimension])
        .then(([noDimension]) => {
            return new Page<AssigneeReportData>().setList(List(noDimension));
        })
        .catch(dispatchFetchPageError<AssigneeReportData>);
}

function fetchAssigneeReportDataPromise(url: string) {
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponse<IAssigneeReportResponse[]>>) => {
            return response
                .data
                ._embedded
                .map(value => toModelAssigneeReport(value));
        });
}

function fetchProjectReportDataPromise(url: string) {
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponse<IProjectsReportResponse[]>>) => {
            return response
                .data
                ._embedded
                .map(value => toModelProjectReport(value));
        });
}

const endpoint = "/api/reports";