import React, {useEffect, useState} from "react";
import {Autocomplete, Button, Divider, IconButton, Stack, TextField} from "@mui/material";
import {CustomDrawer, DrawerPart,} from "../../globals/CommonComponents";
import {Close} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import ReportList from "./ReportList";
import reportListStore from "../../flux/report/list/ReportListStore";
import {ReportType} from "../../model/Report";
import {LoadingButton} from "@mui/lab";
import {
    generateReportAction,
    refreshReportListAction,
    setReportListVarietyAction
} from "../../flux/report/list/ReportListActions";
import {fetchReportDataAction} from "../../flux/report/data/ReportDataActions";
import {useInterval} from "../../globals/CustomHooks";
import ReportFilterView from "./ReportFilterView";
import {catFileSelectActions} from "../../flux/cat-files/select/CatFileSelectActions";
import {Page} from "../../model/Page";
import {CatFile} from "../../model/CatFile";

type ReportSettingsProps = {
    isOpen: boolean,
    onClose: () => void,
    openProjectFiles: () => void
}

export default function ReportSettings(props: ReportSettingsProps) {
    const state = reportListStore.getState();
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [isGenerating, setIsGenerating] = useState(state.isGeneratingNew);
    const [filter, setFilter] = useState(state.filter);
    const [page, setPage] = useState(new Page<CatFile>());

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        const catFilesListener = catFileSelectActions.store.addListener(() => {
            const state = catFileSelectActions.state;
            setPage(state.page);
        });

        const reportListener = reportListStore.addListener(() => {
            const state = reportListStore.getState();
            setFilter(state.filter);
            setIsGenerating((state.isGeneratingNew));
        });

        return () => {
            reportListener.remove();
            catFilesListener.remove();

        }
    }, []);

    useInterval(() => refreshReportListAction(), 2000);
    const isProjectReport = () => {
        return state.filter.variety === ReportType.Project;
    };
    return (
        <CustomDrawer
            width={400}
            anchor={"right"}
            open={isOpen}
            onClose={props.onClose}
        >
            <DrawerPart>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant={"h6"}>Settings</Typography>
                    <IconButton onClick={props.onClose}>
                        <Close/>
                    </IconButton>
                </Stack>
            </DrawerPart>
            <Divider/>
            <DrawerPart>
                <Stack spacing={1}>
                    <Autocomplete
                        disablePortal
                        autoSelect
                        clearIcon={null}
                        id="report-variety"
                        options={varieties}
                        value={varieties.find(value => value.variety === filter.variety)}
                        onChange={(_e, value) => {
                            if (value)
                                setReportListVarietyAction(value.variety);
                        }}
                        renderInput={(params) => <TextField {...params} label="Report Type"/>}
                    />
                    {generateButton(isGenerating, !state.filter.isValid)}
                    {isProjectReport() && (
                        <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
                            <Button onClick={props.openProjectFiles}>Files Filter ({catFileSelectActions.selection.countSelected(page.totalElements)} Selected)</Button>
                        </Stack>
                    )
                    }
                </Stack>
            </DrawerPart>
            <DrawerPart>
                <ReportFilterView filter={state.filter}></ReportFilterView>
            </DrawerPart>
            <Divider/>
            <DrawerPart>
                <ReportList onClick={(report) => {
                    fetchReportDataAction(report);
                    props.onClose();
                }}/>
            </DrawerPart>
        </CustomDrawer>
    )
}

function generateButton(inProgress: boolean, disabled: boolean) {
    return (
        <LoadingButton
            loading={inProgress}
            disabled={disabled || inProgress}
            variant={"contained"}
            onClick={() => generateReportAction()}>
            Generate
        </LoadingButton>
    );
}

const varieties = [
    {label: "Project statistics", variety: ReportType.Project},
    {label: "Translator statistics", variety: ReportType.TranslationAssignee},
]


