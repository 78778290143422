import {List, Set} from "immutable";
import TranslationMemory, {TranslationMemoryGroup} from "../model/TranslationMemory";
import axios, {AxiosResponse} from "axios";
import {
    buildUrl,
    dispatchError,
    dispatchFetchPageError,
    MultipartFormDataConfig,
    toParameters
} from "../globals/ApiGlobals";
import {
    ITranslationMemoryBuildResponse,
    ITranslationMemoryResponse,
    ITranslationMemoryUploadResponse,
    toTranslationMemoryBuildModel,
    toTranslationMemoryModel
} from "./model/TranslationMemoryApiModel";
import {Page} from "../model/Page";
import {Pageable} from "../model/Pageable";
import TranslationMemoryFilter from "../flux/translation-memory/TranslationMemoryFilter";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {Build} from "../model/Build";

export function fetchTranslationMemories(filter?: TranslationMemoryFilter, page?: Pageable) {
    const url = buildUrl(endpoint + "/search", {}, toParameters<TranslationMemoryFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<ITranslationMemoryResponse[]>>) => {
            const list = response.data._embedded.map(toTranslationMemoryModel);
            return Page.fromBackendPage<TranslationMemory>(response.data.page).setList(List(list))
        })
        .catch(dispatchFetchPageError<TranslationMemory>);
}

export function isTmNameExisted(name: string) {
    const filter = new TranslationMemoryFilter({name: name, nameOperator: 'equals'});
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<TranslationMemoryFilter>(filter));
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<ITranslationMemoryResponse[]>>) => {
            return response
                .data
                ._embedded
                .length > 0;
        })
        .catch(error => {
            dispatchError(error)
            return false;
        });
}

export function uploadTranslationMemory(tmName: string, tmFile: File) {
    return axios
        .post(endpoint + "/" + encodeURIComponent(tmName),
            {file: tmFile},
            MultipartFormDataConfig)
        .then((response: AxiosResponse<ITranslationMemoryUploadResponse>) =>
            toTranslationMemoryModel(response.data.translationMemory))
        .catch((error) => {
            dispatchError(error);
            return new TranslationMemory();
        })
}

export async function createTranslationMemory(tm: TranslationMemory) {
    return axios
        .post(endpoint + "/create", {
            name: tm.name,
            sourceLanguage: tm.sourceLanguage?.id,
            targetLanguages: tm.targetLanguages.map(language => language.id).toArray()
        })
        .then((response: AxiosResponse<ITranslationMemoryResponse>) => toTranslationMemoryModel(response.data))
        .catch(error => {
            dispatchError(error);
            return new TranslationMemory();
        })
}

export function deleteTranslationMemories(memories: Set<TranslationMemory>) {
    return Promise
        .all(memories.map(value => deleteTranslationMemory(value)))
        .then(() => {
        });
}

function deleteTranslationMemory(memory: TranslationMemory) {
    return axios
        .delete(endpoint + "/" + encodeURIComponent(memory.name))
        .catch(dispatchError)
}

export function buildTranslationMemories(memories: TranslationMemoryGroup) {
    return axios
        .post(endpoint + "/build", {
            names: memories.translationMemories.map(tm => tm.name).toArray()
        })
        .then((response: AxiosResponse<ITranslationMemoryBuildResponse>) =>
            toTranslationMemoryBuildModel(response.data))
        .catch(error => {
            dispatchError(error);
            return new Build();
        })
}

export function buildTranslationMemory(memory: TranslationMemory) {
    return buildTranslationMemories(new TranslationMemoryGroup(Set.of(memory)));
}

const endpoint = "/api/pretranslate/translation-memory"
