import {User} from "../../model/User";

export enum AuthAction {
    SetUser = "AuthAction.SetUser"
}

interface ISetUser {
    action: AuthAction.SetUser,
    user: User
}

export type AuthPayload = ISetUser;

export function setUser(user: User): AuthPayload {
    return {
        action: AuthAction.SetUser,
        user: user
    }
}