import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Button, Chip, Container, Fab, Grid, Stack, Tab} from "@mui/material";
import Drawer, {DrawerProps} from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

export const DataGridContainer = styled(Box)({
    width: '100%',
    flex: 1,
    overflow: 'auto'
});

export const SimpleButton = styled(Button)({
    textTransform: 'none'
});

export const CustomChip = styled(Chip)({
    height: 18,
    fontSize: 12
});

export const OverflowContainer = styled(Container)({
    overflowY: 'auto',
    padding: 0,
    '@media (min-width:600px)': {
        padding: 0
    }
});

export const NoPaddingContainer = styled(Container)({
    padding: 0,
    '@media (min-width:600px)': {
        padding: 0
    }
});

interface CustomDrawerProps extends DrawerProps {
    width: number;
}

export const CustomDrawer = styled(Drawer)<CustomDrawerProps>(
    (props: CustomDrawerProps) => ({
        width: props.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: props.width,
            transform: `translateX(${props.width}px)`,
            ...(props.open && {
                transform: 'translateX(0px)'
            })
        }
    })
);

export const CommonLink = styled(Link)({
    textDecoration: 'none',
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
});

export const RightBottomFab = styled(Fab)({
    position: 'absolute',
    bottom: 30,
    right: 30,
});

export const BreakWordGrid = styled(Grid)({
    wordWrap: 'break-word'
});

type SearchResultGridProps = {
    isSelected: boolean
}

export const SearchResultGrid = styled(Grid, {
    shouldForwardProp: propName => propName !== 'isSelected'
})<SearchResultGridProps>((props) => ({
    backgroundColor: props.isSelected ? '#f3f0fb' : '#ffffff',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f6f6f6'
    }
}));

export const SlateParagraph = styled('p')({
    marginTop: 0
});

export const FoundFragment = styled('span')({
    color: 'red'
});

export const Placeholder = styled('span')({
    cursor: 'pointer',
    margin: '0px 1px',
    padding: '1px 4px',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 207, 108, 1)',
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgba(173, 211, 146, 1)'
    }
});

export const DrawerPart = styled(Stack)({
    padding: '8px 24px'
});

export const SimpleTab = styled(Tab)({
    textTransform: 'none'
});

export const Bold = styled(Typography)({
    fontWeight: 'bold'
});

export const SmallText = styled(Typography)({
    fontSize: 12
});