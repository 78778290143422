import {Button, Dialog, DialogTitle, Stack} from "@mui/material";
import React, {useState} from "react";
import {adminUserListActions} from "../../flux/users/select/AdminUserListActions";
import {Tenant} from "../../model/Tenant";
import {tenantListActions} from "../../flux/tenant/TenantListActions";
import {User} from "../../model/User";
import TextFieldSelect from "../common/TextFieldSelect";

interface IProps {

    open: boolean,
    onClose: () => void
}

export function UserEditor(props: IProps) {
    const [tenant, setTenant] = useState(new Tenant());

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>User</DialogTitle>
            <Stack direction={"column"} alignItems={"center"} spacing={2} padding={2}>
                <TextFieldSelect onSelect={selected => setTenant(selected)}
                                 label={"Tenant"}
                                 getPresentation={model => model.name}
                                 actions={tenantListActions}>
                </TextFieldSelect>

                <Button fullWidth={true}
                        variant={"contained"}
                        onClick={() => onSave(tenant, props)}
                >
                    Save
                </Button>
            </Stack>
        </Dialog>
    );
}

function onSave(tenant: Tenant, props: IProps) {
    const updated = adminUserListActions
        .selected
        .map(value => value as User)
        .map(user => user.set("tenant", tenant))
        .toList();
    
    adminUserListActions.saveAll(updated);
    props.onClose();
}