import {IconButton, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {permissionsListActions} from "../../flux/user-permissions/list/PermissionsSelectActions";
import {Permission} from "../../model/Permission";
import {getGridStringOperators, GridColDef} from "@mui/x-data-grid";
import {PermissionEntityPartType} from "../../model/PermissionEntityPart";
import AddIcon from "@mui/icons-material/Add";
import {PermissionEditor} from "./PermissionEditor";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import ListView from "../common/ListView";
import DeleteIcon from "@mui/icons-material/Delete";
import {initPermissionsEditor} from "../../flux/user-permissions/editor/PermissionsEditorActions";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {useProject} from "../project/ProjectView";
import PermissionsFilter from "../../flux/user-permissions/PermissionsFilter";
import {GridRowStringOperatorsDefault} from "../../globals/Constants";
import {useSearchParams} from "react-router-dom";
import {getPermissionsPath} from "../../routes/project/tabs/PermissionsRoute";

export default function PermissionsList() {
    const {project} = useProject();
    const [searchParams] = useSearchParams();
    const [openEditor, setOpenEditor] = useState(false);
    const [filter, setFilter]
        = useState<PermissionsFilter | null>(null);

    useEffect(() => {
        const filter = PermissionsFilter.fromSearchParams(project.id, searchParams);
        setFilter(filter);
        permissionsListActions.fetch(filter);
    }, [project]);

    return (
        <Stack sx={{height: '100%'}}>
            <PermissionEditor open={openEditor} onClose={() => setOpenEditor(false)}/>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => executeOpenEditor(setOpenEditor)}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton onClick={() => permissionsListActions.deleteSelected()}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
            </Stack>
            {filter && <ListView actions={permissionsListActions} columns={columns}
                                 navigateProps={{navigator: getPermissionsPath}} initialFilter={filter}/>}
        </Stack>
    );
}

function executeOpenEditor(setOpen: React.Dispatch<React.SetStateAction<boolean>>) {
    const projectId = projectPageStore.getState().project.id;
    initPermissionsEditor(projectId);
    setOpen(true);
}

const columns: GridColDef[] = [
    {
        field: 'username',
        filterable: false,
        sortable: false,
        headerName: 'User',
        type: 'string',
        width: 200,
    },
    {
        field: 'catFile',
        sortable: false,
        headerName: 'File',
        type: 'string',
        width: 500,
        valueGetter: (params: GridValueGetterParams<Permission>) =>
            params.row.getEntityPartTitle(PermissionEntityPartType.CatFile)
    },
    {
        field: 'language',
        sortable: false,
        headerName: 'Language',
        type: 'string',
        width: 100,
        valueGetter: (params: GridValueGetterParams<Permission>) =>
            params.row.getEntityPartTitle(PermissionEntityPartType.Language),
        filterOperators: getGridStringOperators().filter(operator =>
            GridRowStringOperatorsDefault.includes(operator.value))
    },
    {
        field: 'workflow',
        sortable: false,
        headerName: 'Workflow',
        type: 'string',
        width: 100,
        valueGetter: (params: GridValueGetterParams<Permission>) =>
            params.row.getEntityPartTitle(PermissionEntityPartType.WorkflowStep),
        filterOperators: getGridStringOperators().filter(operator =>
            GridRowStringOperatorsDefault.includes(operator.value))
    },
    {
        field: 'readAllowed',
        sortable: false,
        headerName: 'Read',
        type: 'boolean',
        width: 100
    }
]
