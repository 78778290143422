import {CircularProgress, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import rollbackStore from "../../flux/event/rollback/RollbackStore";
import Typography from "@mui/material/Typography";

export function RollbackInProgress() {
    const initialState = rollbackStore.getState();
    const [inProgress, setInProgress] = useState(initialState.inProgress);

    useEffect(() => {
        const rollbackListener = rollbackStore.addListener(() => {
            const state = rollbackStore.getState();
            setInProgress(state.inProgress);
        });

        return () => rollbackListener.remove();
    }, []);

    if (!inProgress)
        return null;

    return (
        <Stack direction={"row"}>
            <CircularProgress size={20}/>
            <Typography> Rolling-back...</Typography>
        </Stack>
    )
}