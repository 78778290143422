import {Record} from "immutable";

interface ISegmentComment {
    id: number,
    segmentId: number,
    text: string,
    languageCode: string,
    authorName: string,
    authorId: string,
    created: Date,
}

const SegmentCommentRecord = Record<ISegmentComment>({
    id: 0,
    segmentId: 0,
    text: "",
    languageCode: "",
    authorName: "",
    authorId: "",
    created: new Date(),
});

export class SegmentComment extends SegmentCommentRecord {
}
