import {ReduceStore} from "flux/utils";
import {ProjectPageAction, ProjectPagePayload} from "./ProjectPagePayload";
import Project from "../../../model/Project";
import {dispatcher, Payload} from "../../Dispatcher";
import {List, Record} from "immutable";
import {CatFile, FileState} from "../../../model/CatFile";
import {WorkflowStepModel} from "../../../model/WorkflowStepModel";
import {Page} from "../../../model/Page";
import {Build} from "../../../model/Build";

interface IProjectPageState {
    project: Project;
    files: Page<CatFile>;
    build: Build | null;
    workflowStages: WorkflowStepModel | null;
}

const ProjectPageFactory = Record<IProjectPageState>({
    project: new Project(),
    files: new Page<CatFile>(),
    build: null,
    workflowStages: null
});

class ProjectPageState extends ProjectPageFactory {
}

export class ProjectPageStore extends ReduceStore<ProjectPageState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ProjectPageState {
        return new ProjectPageState();
    }

    reduce(state: ProjectPageState, payload: ProjectPagePayload): ProjectPageState {
        switch (payload.action) {
            case ProjectPageAction.SetProject:
                return state.set("project", payload.project);
            case ProjectPageAction.SetFiles:
                return state.set("files", payload.page)
            case ProjectPageAction.SetFilesState:
                return state.set("files", this.setFileState(state.files, payload.state, payload.fileIds));
            default:
                return state;
        }
    }

    setFileState(files: Page<CatFile>, catFileState: FileState, fileIds: List<number>) {
        const updated = files
            .list
            .map(file => {
                if (fileIds.size === 0 || fileIds.includes(file.id)) {
                    return file.merge({state: catFileState});
                }
                return file;
            })

        return files.set("list", updated);
    }
}

const projectPageStore = new ProjectPageStore();
export default projectPageStore;