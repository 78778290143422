import {CommonBuildActions} from "../../common/build/CommonBuildActions";
import TranslationMemory, {TranslationMemoryGroup} from "../../../model/TranslationMemory";
import {buildTranslationMemories, buildTranslationMemory} from "../../../api/TranslationMemoryApi";
import {downloadTranslationMemory, fetchTranslationMemoryBuild} from "../../../api/TranslationMemoryBuildApi";

export const translationMemoryGroupBuildActions = new CommonBuildActions<TranslationMemoryGroup>(
    buildTranslationMemories,
    fetchTranslationMemoryBuild,
    downloadTranslationMemory);
export const translationMemoryBuildActions = new CommonBuildActions<TranslationMemory>(
    buildTranslationMemory,
    fetchTranslationMemoryBuild,
    downloadTranslationMemory
)