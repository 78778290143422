import Project from "../../../model/Project";
import {createProject, deleteProject, fetchProjects} from "../../../api/ProjectApi";
import {dispatcher} from "../../Dispatcher";
import {setProjectList} from "./ProjectListPayload";
import {WorkflowStepModel} from "../../../model/WorkflowStepModel";
import {List} from "immutable";
import {uploadFilesAction} from "../page/ProjectPageActions";
import projectListStore from "./ProjectListStore";
import {CommonListActions} from "../../common/list/CommonListActions";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {AvailableResourceType} from "../../../model/AvailableResources";
import ProjectListItem from "../../../model/ProjectListItem";
import ProjectListFilter from "./ProjectListFilter";

class ProjectListActions extends CommonListActions<ProjectListItem, ProjectListFilter> {

    constructor() {
        super(
            fetchProjects,
            async () => {
                dispatchUnsupportedOperation();
            },
            async () => {
                dispatchUnsupportedOperation();
                return List<ProjectListItem>();
            },
            AvailableResourceType.Projects);
    }
}

export const projectListActions = new ProjectListActions();

export async function fetchProjectListAction() {
    const page = projectListStore.getState().page;
    const projects = await fetchProjects(projectListActions.state.filter, page.pageable);
    dispatcher.dispatch(setProjectList(projects));
}

export async function createProjectAction(project: Project,
                                          workflowSteps: WorkflowStepModel | null,
                                          files: List<File>,
                                          multiLanguage: boolean) {
    const created = await createProject(project, workflowSteps);
    await uploadFilesAction(files, created.id, multiLanguage);
    await fetchProjectListAction();
}

export async function deleteProjectAction(id: number) {
    await deleteProject(id);
    await fetchProjectListAction();
}