import {GridActionsCellItem, GridRenderCellParams} from "@mui/x-data-grid";
import {Stack, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {CatFile} from "../../../../../model/CatFile";
import PrivateComponent from "../../../../PrivateComponent";
import {AvailableResourceType} from "../../../../../model/AvailableResources";
import {catFileListActions} from "../../../../../flux/cat-files/CatFileListActions";
import DownloadFileAction from "./DownloadFileAction";
import UploadFileAction from "./UploadFileAction";

type ProjectFileActionsProperties = {
    renderCellParams: GridRenderCellParams<CatFile, CatFile>,
    projectId: number
}

export default function ProjectFileActions(props: ProjectFileActionsProperties) {
    return (
        <Stack direction={"row"}>
            <PrivateComponent resource={AvailableResourceType.CatFiles} writeAllow={true}>
                <UploadFileAction catFile={props.renderCellParams.row} projectId={props.projectId}/>
            </PrivateComponent>
            <PrivateComponent resource={AvailableResourceType.CatFiles} readAllow={true}>
                <DownloadFileAction catFile={props.renderCellParams.row}/>
            </PrivateComponent>
            <PrivateComponent resource={AvailableResourceType.CatFiles} deleteAllow={true}>
                <GridActionsCellItem label={"Delete"} icon={<Tooltip title={"Delete"} arrow><DeleteIcon/></Tooltip>}
                                     onClick={() => catFileListActions.delete(props.renderCellParams.row)}/>
            </PrivateComponent>
        </Stack>
    );
}