import {Button, Card, CardActions, CardContent, Container, Grid, IconButton, Pagination, Stack} from "@mui/material";
import PageBreadcrumbs from "../PageBreadcrumbs";
import AddIcon from "@mui/icons-material/Add";
import React, {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {NewPlaceholdersSetPath} from "../../routes/placeholders/NewPlaceholdersSetRoute";
import placeholdersSetsListStore from "../../flux/placeholders-set/list/PlaceholdersSetListStore";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import {PlaceholdersSetPath} from "../../routes/placeholders/PlaceholdersSetRoute";
import {
    deletePlaceholdersSetAction,
    setPlaceholdersSetsPageNumberAction
} from "../../flux/placeholders-set/editor/PlaceholdersSetEditorActions";

const PlaceholderCard = styled(Card)({
    marginTop: '8px',
    '&:hover': {
        boxShadow: '0 4px 6px #0e1a2d42',
        cursor: 'pointer'
    }
});

const CustomPagination = styled(Pagination)({
    marginTop: '8px'
});

export default function PlaceholdersSetsList() {
    const initialPlaceholdersSetsState = placeholdersSetsListStore.getState();

    const [placeholdersSetsList, setPlaceholdersSetsList]
        = useState(initialPlaceholdersSetsState.placeholdersSets);
    const navigate = useNavigate();

    useEffect(() => {
        const placeholdersSetsListListener = placeholdersSetsListStore
            .addListener(() => {
                const state = placeholdersSetsListStore.getState();
                setPlaceholdersSetsList(state.placeholdersSets);
            });

        return () => placeholdersSetsListListener.remove();
    });

    return (
        <Container maxWidth={false}>
            <Stack spacing={2}>
                <Grid container justifyContent={"space-between"} alignItems={"start"}>
                    <PageBreadcrumbs/>
                    <Button variant={"contained"} startIcon={<AddIcon/>}
                            onClick={() => handleAddPresetClicked(navigate)}>
                        Add preset
                    </Button>
                </Grid>
            </Stack>
            <Stack>
                {placeholdersSetsList.list.map((placeholderSet) => (
                    <PlaceholderCard key={"placeholders-set-" + placeholderSet.id}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <CardContent>
                                <Typography variant={"subtitle2"}>{placeholderSet.name}</Typography>
                            </CardContent>
                            <CardActions>
                                <IconButton onClick={() => handleEditClicked(placeholderSet.id, navigate)}>
                                    <EditIcon/>
                                </IconButton>
                                <IconButton onClick={() => handleDeleteClicked(placeholderSet.id)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </CardActions>
                        </Stack>
                    </PlaceholderCard>
                ))}
            </Stack>
            <CustomPagination count={placeholdersSetsList.totalPages} page={placeholdersSetsList.number}
                              onChange={(_e, value) =>
                                  handlePageChanged(value, setPlaceholdersSetsPageNumberAction)}/>
        </Container>
    );
}

function handleAddPresetClicked(navigate: NavigateFunction) {
    navigate(NewPlaceholdersSetPath);
}

function handleEditClicked(placeholderSetId: number, navigate: NavigateFunction) {
    navigate(PlaceholdersSetPath.replace(':placeholdersSetId', String(placeholderSetId)));
}

function handleDeleteClicked(placeholderSetId: number) {
    deletePlaceholdersSetAction(placeholderSetId);
}

function handlePageChanged(value: number, setPlaceholdersSetsPageNumberAction: (value: number) => void) {
    setPlaceholdersSetsPageNumberAction(value);
}