import {AvailableResourceType} from "../../../model/AvailableResources";
import CatFiles from "../../../components/project/tabs/files/CatFiles";
import PrivateComponent from "../../../components/PrivateComponent";
import React, {useEffect} from "react";
import CatFilesFilter from "../../../flux/cat-files/CatFilesFilter";
import {ProjectPath} from "../ProjectRoute";
import {useLocation} from "react-router-dom";
import {dispatcher} from "../../../flux/Dispatcher";
import {setProjectUrl} from "../../../flux/segment/list/SegmentListPayload";

export default function CatFilesRoute(){
    const location = useLocation();

    useEffect(() => {
        dispatcher.dispatch(setProjectUrl(location.pathname + location.search));
    })

    return <PrivateComponent resource={AvailableResourceType.CatFiles} readAllow={true} fullHeight={true}>
        <CatFiles/>
    </PrivateComponent>
}

export function getCatFilesPath(filter: CatFilesFilter) {
    return ProjectPath.replaceAll(":id", String(filter.projectId)) + "/" + CatFilesPath + "?"
        + filter.toSearchParams();
}

export const CatFilesPath = "catFiles";
export const CatFilesId = "CatFiles";