import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CodeIcon from '@mui/icons-material/Code';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {Link, NavigateFunction, Outlet, useLocation, useMatches, useNavigate} from "react-router-dom";
import {ProjectsId, ProjectsPath} from "./project/ProjectsRoute";
import {Grid, ListItemIcon, ListItemText} from "@mui/material";
import {NewProjectId, NewProjectPath} from "./project/NewProjectRoute";
import {Params} from "@remix-run/router";
import {PretranslateId} from "./project/PretranslateRoute";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AlertView from "../components/AlertView";
import {getGlossariesPath, GlossariesId} from "./GlossariesRoute";
import {useKeycloak} from "@react-keycloak/web";
import {PlaceholdersId, PlaceholdersPath} from "./placeholders/PlaceholdersSetsRoute";
import {NewPlaceholdersSetId} from "./placeholders/NewPlaceholdersSetRoute";
import {PlaceholdersSetId} from "./placeholders/PlaceholdersSetRoute";
import {ProjectSearchId} from "./project/ProjectSearchRoute";
import SearchInProject, {SearchInProjectDrawerWidth} from "../components/project/tabs/files/SearchInProject";
import projectSearchStore from "../flux/project/search/ProjectSearchStore";
import authStore from "../flux/auth/AuthStore";
import Project from "../model/Project";
import PlaceholdersSet from "../model/PlaceholdersSet";
import {AlertType} from "../model/Alert";
import PrivateComponent from "../components/PrivateComponent";
import {AvailableResourceType} from "../model/AvailableResources";
import {getTranslationMemoriesPath, TranslationMemoriesId} from './TranslationMemoriesRoute';
import TranslationMemoryFilter from "../flux/translation-memory/TranslationMemoryFilter";
import GlossaryFilter from "../flux/glossary/GlossaryFilter";
import {EditorId} from './EditorRoute';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {AdminPanePath} from "./admin/AdminPaneRoute";
import {ArrowBack, Logout} from "@mui/icons-material";
import {ProjectId, ProjectPath} from "./project/ProjectRoute";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {VendorsListPath} from "./vendor/VendorsListRoute";

const leftDrawerWidth = 240;

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'leftDrawerOpen' && prop !== 'rightDrawerOpen'
})<{
    leftDrawerOpen?: boolean;
    rightDrawerOpen?: boolean;
}>(({theme, leftDrawerOpen, rightDrawerOpen}) => ({
    flexGrow: 1,
    height: '100%',
    width: `calc(100% - ${(leftDrawerOpen ? leftDrawerWidth : 0) +
    (rightDrawerOpen ? SearchInProjectDrawerWidth : 0)}px)`,
    padding: theme.spacing(3),
    marginLeft: `-${leftDrawerWidth}px`,
    marginRight: `-${SearchInProjectDrawerWidth}px`,
    ...(leftDrawerOpen && {
        marginLeft: 0
    }),
    ...(rightDrawerOpen && {
        marginRight: 0
    })
}));

interface AppBarProps extends MuiAppBarProps {
    leftDrawerOpen?: boolean;
    rightDrawerOpen?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'leftDrawerOpen' && prop !== 'rightDrawerOpen',
})<AppBarProps>(({leftDrawerOpen, rightDrawerOpen}) => ({
    ...((leftDrawerOpen || rightDrawerOpen) && {
        width: `calc(100% - ${(leftDrawerOpen ? leftDrawerWidth : 0) +
        (rightDrawerOpen ? SearchInProjectDrawerWidth : 0)}px)`,
        marginLeft: `${leftDrawerOpen ? leftDrawerWidth : 0}px`,
        marginRight: `${rightDrawerOpen ? SearchInProjectDrawerWidth : 0}px`
    })
}));

const DrawerHeader = styled('div')(({theme}): any => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const MainContainer = styled(Box)(({theme}): any => ({
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`
}));

export default function Root() {
    const initialProjectSearchState = projectSearchStore.getState();
    const initialAuthState = authStore.getState();

    const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
    const [rightDrawerOpen, setRightDrawerOpen]
        = useState(initialProjectSearchState.isOpen);
    const [user, setUser] = useState(initialAuthState.user);
    const matches = useMatches() as MatchesType;
    const navigate = useNavigate();
    const {keycloak} = useKeycloak();

    const location = useLocation();
    const isReportViewOpen = location.pathname.endsWith('/report');

    useEffect(() => {
        const projectSearchListener = projectSearchStore.addListener(() => {
            const state = projectSearchStore.getState();
            setRightDrawerOpen(state.isOpen);
        });

        const authListener = authStore.addListener(() => {
            const state = authStore.getState();
            setUser(state.user);
        });

        return () => {
            projectSearchListener.remove();
            authListener.remove();
        }
    }, []);

    const lastMatch = matches[matches.length - 1];
    if (lastMatch.id === EditorId) {
        return (
            <Box height={'100%'}>
                <Outlet/>
            </Box>
        );
    }

    return (
        <Box sx={{display: 'flex', height: '100%'}}>
            <CssBaseline/>
            <AppBar position="fixed" leftDrawerOpen={leftDrawerOpen} rightDrawerOpen={rightDrawerOpen}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerOpen(setLeftDrawerOpen)}
                        edge="start"
                        sx={{mr: 2, ...(leftDrawerOpen && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        {toolbarLabel(matches)}
                        {backToProjectButton(isReportViewOpen,lastMatch)}
                    </Typography>
                    <Typography>{user?.name}</Typography>
                    <IconButton onClick={() => keycloak.logout()}
                                aria-label="logout"
                                color="inherit"
                    >
                        <Logout/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: leftDrawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: leftDrawerWidth,
                        boxSizing: 'border-box',
                    },
                    '& .MuiListItem-root': {
                        padding: 0
                    },
                    '& .MuiListItemIcon-root': {
                        minWidth: 36
                    },
                    '& .MuiTypography-root': {
                        fontSize: 14
                    }
                }}
                variant="persistent"
                anchor="left"
                open={leftDrawerOpen}
            >
                <Grid container alignItems={"center"}>
                    <Grid item xs={10}>
                        <IconButton sx={{width: '100%', borderRadius: 0, margin: 1, transform: 'scale(0.8)'}}
                                    onClick={() => handleLogoClicked(navigate)}>
                            <img src={"/Logo.svg"} alt={"logo"} height={"100%"}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={() => handleDrawerClose(setLeftDrawerOpen)}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <List>
                    <PrivateComponent resource={AvailableResourceType.Projects} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link} to={ProjectsPath}>
                                <ListItemIcon>
                                    <img src={"/folders.svg"} alt={"projects"}/>
                                </ListItemIcon>
                                <ListItemText primary={"Projects"}/>
                            </ListItemButton>
                            <PrivateComponent resource={AvailableResourceType.Projects} writeAllow={true}>
                                <IconButton component={Link} to={NewProjectPath}>
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                            </PrivateComponent>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.TranslationMemories} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link}
                                            to={getTranslationMemoriesPath(new TranslationMemoryFilter())}>
                                <ListItemIcon>
                                    <img src={"/album.svg"} alt={"translation memories"}/>
                                </ListItemIcon>
                                <ListItemText primary={"Translation memories"}/>
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Glossaries} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link} to={getGlossariesPath(new GlossaryFilter())}>
                                <ListItemIcon>
                                    <img src={"/checklist.svg"} alt={"glossaries"}/>
                                </ListItemIcon>
                                <ListItemText primary={"Glossaries"}/>
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Placeholders} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link} to={PlaceholdersPath}>
                                <ListItemIcon>
                                    <CodeIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"Placeholders"}/>
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Vendors} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link}
                                            to={VendorsListPath}>
                                <ListItemIcon>
                                    <AccountCircleIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"Vendors"}/>
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                    <PrivateComponent resource={AvailableResourceType.Admin} readAllow={true}>
                        <ListItem>
                            <ListItemButton component={Link}
                                            to={AdminPanePath}>
                                <ListItemIcon>
                                    <SettingsOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"Admin"}/>
                            </ListItemButton>
                        </ListItem>
                    </PrivateComponent>
                </List>
            </Drawer>
            <Main leftDrawerOpen={leftDrawerOpen} rightDrawerOpen={rightDrawerOpen}>
                <DrawerHeader/>
                <MainContainer>
                    <Outlet/>
                </MainContainer>
                <AlertView type={AlertType.Global}/>
            </Main>
            <SearchInProject/>
        </Box>
    );
}

function toolbarLabel(matches: MatchesType): string {
    const lastMatch = matches[matches.length - 1];
    switch (lastMatch.id) {
        case TranslationMemoriesId:
            return "Translation memories";
        case ProjectsId:
            return "Projects";
        case GlossariesId:
            return "Glossaries";
        case PlaceholdersId:
            return "Placeholders";
        case NewProjectId:
            return "Add project";
        case ProjectSearchId:
            return "Project search";
        case NewPlaceholdersSetId:
            return "Add preset";
        case PlaceholdersSetId:
            return "Placeholders set " + (lastMatch.data as PlaceholdersSet).name;
        case ProjectId:
            return "Project " + (lastMatch.data as Project).name;
        case PretranslateId:
            return "Project " + (lastMatch.data as Project).name + " pretranslation";
    }
    return "1CAT";
}

function handleDrawerOpen(setLeftDrawerOpen: (value: boolean) => void) {
    setLeftDrawerOpen(true);
}

function handleDrawerClose(setLeftDrawerOpen: (value: boolean) => void) {
    setLeftDrawerOpen(false);
}

function handleLogoClicked(navigate: NavigateFunction) {
    navigate("/");
}

export type MatchesType = {
    id: string;
    pathname: string;
    params: Params;
    data: Project | PlaceholdersSet | null;
    handle: { crumb: (project: Project | PlaceholdersSet | null) => JSX.Element };
}[];

function backToProjectButton(reportViewOpen:boolean,lastMatch:any) {
   return <Box sx={{mr: 2, ...(!reportViewOpen && {display: 'none'})}}>
       {lastMatch.params.id !== null && lastMatch.params.id !== undefined && (
           <Box onClick={() => handleBackClick(lastMatch)} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
               <IconButton aria-label="back" color="inherit">
                   <ArrowBack />
                   <Typography variant="button" sx={{ ml: 0.5, fontSize: '1rem' }}>
                       Back to Project
                   </Typography>
               </IconButton>
           </Box>
       )}
   </Box>
}

function handleBackClick(lastMatch:any):void {
    const pathToProject = ProjectPath.replaceAll(":id", String(lastMatch.params.id));
    window.location.href = pathToProject;
};