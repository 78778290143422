import Project from "../../../model/Project";
import {List} from "immutable";
import {CatFile, FileState} from "../../../model/CatFile";
import {Page} from "../../../model/Page";

export enum ProjectPageAction {
    SetProject = "ProjectPageAction.SetProject",
    SetFiles = "ProjectPageAction.SetFiles",
    SetFilesState = "ProjectPageAction.SetFilesState"
}

interface ISetProjectPayload {
    action: ProjectPageAction.SetProject,
    project: Project
}

interface ISetFilesPayload {
    action: ProjectPageAction.SetFiles,
    page: Page<CatFile>
}

interface ISetFilesStatePayload {
    action: ProjectPageAction.SetFilesState,
    fileIds: List<number>,
    state: FileState
}

export type ProjectPagePayload = ISetProjectPayload
    | ISetFilesPayload
    | ISetFilesStatePayload;

export function setProjectPayload(project: Project): ProjectPagePayload {
    return {
        action: ProjectPageAction.SetProject,
        project: project
    }
}

export function setFilesState(fileIds: List<number>, state: FileState): ProjectPagePayload {
    return {
        action: ProjectPageAction.SetFilesState,
        fileIds: fileIds,
        state: state
    }
}