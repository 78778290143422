import {Build} from "../model/Build";
import {ITranslationMemoryBuildResponse, toTranslationMemoryBuildModel} from "./model/TranslationMemoryApiModel";
import {downloadFile} from "../globals/ApiGlobals";
import axios, {AxiosResponse} from "axios";

export function fetchTranslationMemoryBuild(build: Build) {
    return axios
        .get(endpoint + "/" + build.id)
        .then((response: AxiosResponse<ITranslationMemoryBuildResponse>) =>
            toTranslationMemoryBuildModel(response.data));
}

export function downloadTranslationMemory(build: Build) {
    downloadFile(endpoint, build);
}

const endpoint = "/api/pretranslate/translation-memory-builds"