import {IGlossaryBuildResponse, toGlossaryBuildModel} from "./model/GlossaryApiModel";
import {downloadFile} from "../globals/ApiGlobals";
import {Build} from "../model/Build";
import axios, {AxiosResponse} from "axios";

export function fetchGlossaryBuild(build: Build) {
    return axios
        .get(endpoint + "/" + build.id)
        .then((response: AxiosResponse<IGlossaryBuildResponse>) => toGlossaryBuildModel(response.data));
}

export function downloadGlossary(build: Build) {
    downloadFile(endpoint, build)
}

const endpoint = "/api/glossaries/glossaryBuilds";