import {Record} from "immutable";

interface ISegmentLock {
    userId: string,
    username: string,
    created: Date
}

const SegmentLockRecord = Record<ISegmentLock>({
    userId: '',
    username: '',
    created: new Date()
});

export default class SegmentLock extends SegmentLockRecord {
};