import {Page} from "../../../model/Page";
import ProjectListItem from "../../../model/ProjectListItem";

export enum ProjectListAction {
    SetList = "ProjectListAction.SetList"
}

interface ISetProjectListPayload {
    action: ProjectListAction.SetList,
    page: Page<ProjectListItem>
}

export type ProjectListPayload = ISetProjectListPayload;

export function setProjectList(page: Page<ProjectListItem>): ProjectListPayload {
    return {
        action: ProjectListAction.SetList,
        page: page
    };
}
