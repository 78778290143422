import {RenderElementProps} from "slate-react";
import {Placeholder, SlateParagraph} from "../../../../../globals/CommonComponents";
import {Tooltip} from "@mui/material";
import {
    addEditorCleanupToHistory,
    editorPosition,
    editorText,
    escapePlaceholderContent,
    InlineChromiumBugfix
} from "../../../../../utils/slate/SlateUtils";
import React from "react";
import {Editor, Transforms} from "slate";
import {processTranslationAction} from "../../../../../flux/segment/editor/SegmentEditorActions";
import {Segment} from "../../../../../model/Segment";
import {HistoryEditor} from "slate-history";
import {SlateEntityType, SlatePlaceholder} from "../../../../../utils/slate/SlateModels";

type RenderedSourceElementProps = {
    editor: Editor,
    segment: Segment,
    targetLanguage: string,
    placeholderPrefix: string
}

export function RenderedSourceElement(props: RenderElementProps & RenderedSourceElementProps) {
    const {attributes, children, element} = props;

    switch (element.type) {
        case SlateEntityType.Paragraph:
            return <SlateParagraph {...attributes}>{children}</SlateParagraph>
        case SlateEntityType.Placeholder:
            return <Tooltip title={escapePlaceholderContent(element.content)}>
                <Placeholder {...attributes} contentEditable={false}
                             onClick={() => handlePlaceholderClicked(
                                 element.id,
                                 element.content,
                                 element.order,
                                 element.children[0].text,
                                 props.editor,
                                 props.segment,
                                 props.targetLanguage)}
                             id={props.placeholderPrefix + element.order} tabIndex={0}>
                    <InlineChromiumBugfix/>
                    {children}
                    <InlineChromiumBugfix/>
                </Placeholder>
            </Tooltip>;
        case SlateEntityType.EscapedText:
            return <span {...attributes}>{children}</span>
        default:
            return <p {...attributes}>{children}</p>;
    }
}

function handlePlaceholderClicked(id: number,
                                  content: string,
                                  order: number,
                                  text: string,
                                  editor: Editor,
                                  segment: Segment,
                                  targetLanguage: string) {
    const placeholder: SlatePlaceholder = {
        type: SlateEntityType.Placeholder,
        id: id,
        content: content,
        rawText:text,
        order: order,
        render: false,
        children: [{
            text: text
        }]
    };

    addEditorCleanupToHistory(editor);

    HistoryEditor.withoutSaving(editor, () => {
        Transforms.insertNodes(editor, placeholder);
    });

    const targetText = editorText(editor);
    const cursorPosition = editorPosition(editor) + 1;
    processTranslationAction(segment, targetLanguage, targetText, cursorPosition, editor.history);
}