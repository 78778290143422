import React, {useState} from "react";
import {IconButton, Stack} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "../common/ListView";
import {adminUserListActions} from "../../flux/users/select/AdminUserListActions";
import {AdminUsersPath} from "../../routes/admin/AdminUsersRoute";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {User} from "../../model/User";
import {UserEditor} from "./UserEditor";
import EditIcon from "@mui/icons-material/Edit";
import UsersListFilter from "../../flux/users/UsersListFilter";

export default function AdminUsersList() {
    const [openEditor, setOpenEditor] = useState(false);

    return (
        <Stack sx={{height: '100%'}}>
            <UserEditor open={openEditor} onClose={() => setOpenEditor(false)}/>
            <Stack sx={{height: '100%'}}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => setOpenEditor(true)}>
                        <EditIcon/>
                    </IconButton>
                </Stack>
                <ListView actions={adminUserListActions}
                          columns={columns()}
                          navigateProps={{navigator: () => AdminUsersPath}}
                          initialFilter={new UsersListFilter()}/>
            </Stack>
        </Stack>
    );
}

function columns(): GridColDef[] {
    return [
        {
            field: 'firstName',
            headerName: 'First name',
            width: 200,
            filterable:false,
            sortable:false,
            flex: 2,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 200,
            filterable:false,
            sortable:false,
            flex: 2,
        },
        {
            field: 'tenant',
            headerName: 'Tenant',
            width: 200,
            flex: 2,
            filterable:false,
            sortable:false,
            valueGetter: (params: GridValueGetterParams<User>) => params.row.tenant?.name
        }
    ];
}