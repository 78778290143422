import {ReduceStore} from "flux/utils";
import {ConcordanceSearchPayload, ConcordanceSearchPayloadAction} from "./ConcordanceSearchPayload";
import Immutable from "immutable";
import {ConcordanceSearchResult} from "../../model/ConcordanceSearch";
import {dispatcher, Payload} from "../Dispatcher";
import {Page} from "../../model/Page";

interface IConcordanceSearchState {
    concordanceSearchResults: Page<ConcordanceSearchResult>
}

const ConcordanceSearchStateFactory
    = Immutable.Record<IConcordanceSearchState>({
    concordanceSearchResults: new Page<ConcordanceSearchResult>()
});

export class ConcordanceSearchState extends ConcordanceSearchStateFactory {
}

export class ConcordanceSearchStore extends ReduceStore<ConcordanceSearchState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ConcordanceSearchState {
        return new ConcordanceSearchState();
    }

    reduce(state: ConcordanceSearchState, payload: ConcordanceSearchPayload): ConcordanceSearchState {
        switch (payload.action) {
            case ConcordanceSearchPayloadAction.SetConcordanceSearchResults:
                return state.set("concordanceSearchResults", payload.page);
        }
        return state;
    }
}

const concordanceSearchStore = new ConcordanceSearchStore();
export default concordanceSearchStore;