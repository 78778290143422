import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
    Breadcrumbs,
    InputAdornment,
    LinearProgress,
    Link,
    Menu,
    MenuItem,
    SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Project from "../../../model/Project";
import {Language} from "../../../model/Language";
import {Search} from "@mui/icons-material";
import {Page} from "../../../model/Page";
import projectPageStore from "../../../flux/project/page/ProjectPageStore";
import languageListStore from "../../../flux/language/list/LanguageListStore";
import {Statistic} from "../../../model/Statistic";
import {CatFile} from "../../../model/CatFile";
import segmentListStore from "../../../flux/segment/list/SegmentListStore";
import SegmentListFilter from "../../../flux/segment/list/SegmentListFilter";
import {LanguageList} from "../../../model/LanguageList";
import {getSegmentPath} from "../../../routes/EditorRoute";
import {catFileListActions} from "../../../flux/cat-files/CatFileListActions";
import CustomPagination from "../../common/CustomPagination";
import {getCatFilesPath} from "../../../routes/project/tabs/CatFilesRoute";
import CatFilesFilter from "../../../flux/cat-files/CatFilesFilter";

const GoBackButton = styled(IconButton)({
    border: '1px solid black',
    borderRadius: 0,
    padding: 0
});

const MenuLink = styled(Link)({
    cursor: 'pointer'
});

const StatisticsContainer = styled(Box)({
    paddingLeft: 5,
    width: 200
});

const SearchField = styled(TextField)({
    padding: 1
});

type EditorNavigationViewProperties = {
    filter: SegmentListFilter,
    targetLanguage: Language | null
}

export default function EditorNavigationView(props: EditorNavigationViewProperties) {

    const projectState = projectPageStore.getState();
    const segmentListState = segmentListStore.getState();
    const supportedLanguages = languageListStore.getState().languages;

    const [currentProject, setCurrentProject]
        = useState<Project>(projectState.project);

    const [sourceLanguage, setSourceLanguage]
        = useState<Language | null>(projectState?.project.source);
    const [selectLanguageMenuOpen, setSelectLanguageMenuOpen]
        = useState(false);
    const [selectLanguageMenuAnchor, setSelectLanguageMenuAnchor]
        = useState<HTMLElement | null>(null);
    const [searchLanguageFilter, setSearchLanguageFilter] = useState("");
    const [catFiles, setCatFiles] = useState(catFileListActions.state.page);
    const [catFile, setCatFile]
        = useState(segmentListState.filter.nonNullCatFile);
    const [selectFileMenuOpen, setSelectFileMenuOpen] = useState(false);
    const [selectFileMenuAnchor, setSelectFileMenuAnchor]
        = useState<HTMLElement | null>(null);
    const [searchFileFilter, setSearchFileFilter] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            const project = state.project
            setCurrentProject(project);
            if (project)
                setSourceLanguage(project.source);
        });

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setCatFile(state.filter.nonNullCatFile);
        });

        const catFilesListener = catFileListActions.store.addListener(() => {
            const state = catFileListActions.state;
            setCatFiles(state.page);
        })

        return () => {
            projectPageListener.remove();
            segmentListListener.remove();
            catFilesListener.remove();
        }
    }, []);

    const statistic = props.targetLanguage
        ? catFile.groupedStatistic.translatedByFirstWorkflow(props.targetLanguage)
        : new Statistic();

    return (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <GoBackButton size={"small"} onClick={() => handleBackClicked(navigate, currentProject)}
                          disabled={!currentProject}>
                <ChevronLeftIcon/>
            </GoBackButton>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                <MenuLink underline={"none"} color={"#000000"} fontSize={14} fontWeight={"bold"}>
                    {currentProject?.name}
                </MenuLink>
                <MenuLink onClick={e =>
                    handleSelectLanguageMenuOpen(e, setSelectLanguageMenuAnchor, setSelectLanguageMenuOpen)}
                          underline={"none"} color={"#000000"} fontSize={14} fontWeight={"bold"}>
                    {sourceLanguage?.name + " - " + props.targetLanguage?.name}
                </MenuLink>
                <MenuLink onClick={e =>
                    handleSelectFileMenuOpen(e, setSelectFileMenuAnchor, setSelectFileMenuOpen)}
                          underline={"none"} color={"#000000"} fontSize={14} fontWeight={"bold"}>
                    {catFile?.name}
                </MenuLink>
            </Breadcrumbs>
            <Menu open={selectLanguageMenuOpen} anchorEl={selectLanguageMenuAnchor}
                  onClose={() => handleSelectLanguageMenuClosed(
                      setSelectLanguageMenuAnchor,
                      setSelectLanguageMenuOpen,
                      setSearchLanguageFilter)}>
                {drawLanguageMenuItems(
                    currentProject,
                    sourceLanguage,
                    supportedLanguages,
                    searchLanguageFilter,
                    props.filter,
                    setSelectLanguageMenuAnchor,
                    setSelectLanguageMenuOpen,
                    setSearchLanguageFilter,
                    navigate)}
            </Menu>
            <Menu open={selectFileMenuOpen} anchorEl={selectFileMenuAnchor}
                  onClose={() => handleSelectFileMenuClosed(
                      setSelectFileMenuAnchor,
                      setSelectFileMenuOpen,
                      setSearchFileFilter)}>
                {drawFileMenuItems(
                    props.targetLanguage,
                    catFiles,
                    searchFileFilter,
                    props.filter,
                    setSelectFileMenuAnchor,
                    setSelectFileMenuOpen,
                    setSearchFileFilter,
                    navigate)}
            </Menu>
            <StatisticsContainer>
                <LinearProgress variant={"determinate"} value={statistic.percent}/>
            </StatisticsContainer>
            <Typography fontSize={14} fontWeight={"bold"}>{statistic.workflow.name}</Typography>
            <Typography fontSize={14} color={"text.secondary"}>
                {statistic.presentation}
            </Typography>
        </Stack>
    )
}

function handleBackClicked(navigate: NavigateFunction, project: Project) {
    const projectUrl = segmentListStore.getState().projectUrl;
    navigate(projectUrl ? projectUrl : getCatFilesPath(new CatFilesFilter({projectId: project.id})));
}

function handleSelectLanguageMenuOpen(event: React.MouseEvent<HTMLElement>,
                                      setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                                      setSelectLanguageMenuOpen: (o: boolean) => void) {
    setSelectLanguageMenuAnchor(event.currentTarget);
    setSelectLanguageMenuOpen(true);
}

function handleSelectFileMenuOpen(event: React.MouseEvent<HTMLElement>,
                                  setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                                  setSelectFileMenuOpen: (o: boolean) => void) {
    setSelectFileMenuAnchor(event.currentTarget);
    setSelectFileMenuOpen(true);
}

function handleSelectLanguageMenuClosed(setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                                        setSelectLanguageMenuOpen: (o: boolean) => void,
                                        setSearchLanguageFilter: (s: string) => void) {
    setSelectLanguageMenuAnchor(null);
    setSelectLanguageMenuOpen(false);
    setSearchLanguageFilter("");
}

function handleSelectFileMenuClosed(setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                                    setSelectFileMenuOpen: (o: boolean) => void,
                                    setSearchFileFilter: (s: string) => void) {
    setSelectFileMenuAnchor(null);
    setSelectFileMenuOpen(false);
    setSearchFileFilter("");
}

function handleSearchLanguageTextChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                         setSearchLanguageFilter: (s: string) => void) {
    setSearchLanguageFilter(event.target.value.toLowerCase());
}

function handleSearchFileTextChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                     setSearchFileFilter: (s: string) => void) {
    setSearchFileFilter(event.target.value.toLowerCase());
}

function handleSelectLanguage(languageCode: string,
                              filter: SegmentListFilter,
                              setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                              setSelectLanguageMenuOpen: (o: boolean) => void,
                              setSearchLanguageFilter: (s: string) => void,
                              navigate: NavigateFunction) {
    handleSelectLanguageMenuClosed(setSelectLanguageMenuAnchor, setSelectLanguageMenuOpen, setSearchLanguageFilter);
    navigate(getSegmentPath(filter.set("language", languageCode), 0), {replace: true});
}

function handleSelectFile(catFile: CatFile,
                          filter: SegmentListFilter,
                          setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                          setSelectFileMenuOpen: (o: boolean) => void,
                          setSearchFileFilter: (s: string) => void,
                          navigate: NavigateFunction) {
    handleSelectFileMenuClosed(setSelectFileMenuAnchor, setSelectFileMenuOpen, setSearchFileFilter);
    navigate(getSegmentPath(filter.set("catFile", catFile), 0), {replace: true});
}

function drawLanguageMenuItems(currentProject: Project | null,
                               sourceLanguage: Language | null,
                               supportedLanguages: LanguageList,
                               searchLanguageFilter: string,
                               filter: SegmentListFilter,
                               setSelectLanguageMenuAnchor: (e: HTMLElement | null) => void,
                               setSelectLanguageMenuOpen: (o: boolean) => void,
                               setSearchLanguageFilter: (s: string) => void,
                               navigate: NavigateFunction) {
    const menuItems = [<SearchField key={"language-menu-search"} size={"small"}
                                    placeholder={"Search..."}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={"start"}>
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={e =>
                                        handleSearchLanguageTextChanged(e, setSearchLanguageFilter)}
                                    onKeyDown={e => e.stopPropagation()}/>];
    currentProject?.targets.map(targetLanguage => {
        const foundLanguage = supportedLanguages.findByCode(targetLanguage.id);
        if (!foundLanguage || !foundLanguage.name.toLowerCase().includes(searchLanguageFilter))
            return;
        menuItems.push(<MenuItem key={"language-menu-" + foundLanguage.id}
                                 onClick={() => handleSelectLanguage(
                                     foundLanguage.id,
                                     filter,
                                     setSelectLanguageMenuAnchor,
                                     setSelectLanguageMenuOpen,
                                     setSearchLanguageFilter,
                                     navigate)}>
            {sourceLanguage?.name + " - " + foundLanguage.name}
        </MenuItem>);
    });
    return menuItems;
}

function drawFileMenuItems(targetLanguage: Language | null,
                           catFiles: Page<CatFile>,
                           searchFileFilter: string,
                           filter: SegmentListFilter,
                           setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                           setSelectFileMenuOpen: (o: boolean) => void,
                           setSearchFileFilter: (s: string) => void,
                           navigate: NavigateFunction) {
    if (!targetLanguage)
        return;
    const menuItems = [<SearchField key={"file-menu-search"} size={"small"}
                                    placeholder={"Search..."}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={"start"}>
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={e =>
                                        handleSearchFileTextChanged(e, setSearchFileFilter)}
                                    onKeyDown={e => e.stopPropagation()}/>];
    catFiles.list.map(file => {
        if (!file.name.toLowerCase().includes(searchFileFilter))
            return;
        menuItems.push(<MenuItem key={"file-menu-" + file.id}
                                 onClick={() => handleSelectFile(
                                     file,
                                     filter,
                                     setSelectFileMenuAnchor,
                                     setSelectFileMenuOpen,
                                     setSearchFileFilter,
                                     navigate)}>
            {file.name}
        </MenuItem>);
    });
    menuItems.push(<CustomPagination pageable={catFiles.pageable} onChange={handleCatFilesPageChange}
                                     onRowsPerPageChange={handleCatFilesRowsPerPageChanged}
                                     key={"file-menu-pagination"}/>);
    return menuItems;
}

function handleCatFilesPageChange(page: number) {
    const catFilesState = catFileListActions.state;
    catFileListActions.fetchPageable(catFilesState.filter, catFilesState.page.setPageNumber(page).pageable);
}

function handleCatFilesRowsPerPageChanged(e: SelectChangeEvent<number>) {
    const catFilesState = catFileListActions.state;
    catFileListActions.fetchPageable(catFilesState.filter, catFilesState.page.setSize(Number(e.target.value)).pageable);
}