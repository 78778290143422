import {PlaceholdersSetListPayload, PlaceholdersSetsListAction} from "./PlaceholdersSetListPayload";
import {ReduceStore} from "flux/utils";
import Immutable from "immutable";
import PlaceholdersSet from "../../../model/PlaceholdersSet";
import {dispatcher, Payload} from "../../Dispatcher";
import {Page} from "../../../model/Page";

interface IPlaceholdersSetsListState {
    placeholdersSets: Page<PlaceholdersSet>
}

const PlaceholdersSetsListStateFactory
    = Immutable.Record<IPlaceholdersSetsListState>({
    placeholdersSets: new Page<PlaceholdersSet>()
});

class PlaceholdersSetsListState extends PlaceholdersSetsListStateFactory {
}

export class PlaceholdersSetListStore extends ReduceStore<PlaceholdersSetsListState, Payload> {
    constructor(){
        super(dispatcher);
    }

    getInitialState(): PlaceholdersSetsListState {
        return new PlaceholdersSetsListState();
    }

    reduce(state: PlaceholdersSetsListState, payload: PlaceholdersSetListPayload): PlaceholdersSetsListState {
        switch(payload.action) {
            case PlaceholdersSetsListAction.SetList:
                return state.set("placeholdersSets", payload.page);
            default:
                return state;
        }
    }
}

const placeholdersSetsListStore = new PlaceholdersSetListStore();
export default placeholdersSetsListStore;