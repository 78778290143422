import React, {useEffect, useState} from "react";
import {DataGridContainer} from "../../globals/CommonComponents";
import {Page} from "../../model/Page";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Model} from "../../model/IModel";
import {IFilter} from "../../model/Filter";
import {CommonListActions} from "../../flux/common/list/CommonListActions";
import GroupedListItem from "../../model/GropedListItem";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomPagination from "./CustomPagination";

export interface IFlatGroupedListViewProps<M extends Model, G extends GroupedListItem<M>, F extends IFilter<F>> {
    actions: CommonListActions<G, F>,
    columns: GridColDef[],
    headerDrawer?: (group: GroupedListItem<M>, key: String) => JSX.Element;
}

export default function FlatGroupedListView<M extends Model, G extends GroupedListItem<M>, F extends IFilter<F>>(props: IFlatGroupedListViewProps<M, G, F>) {

    const state = props.actions.state;
    const [available, setAvailable] = useState<Page<G>>(state.page);
    const [isLoading, setIsLoading] = useState(state.isLoading);

    useEffect(() => {
        const storeListener = props.actions.addListener(() => {
            const state = props.actions.state;
            setIsLoading(state.isLoading);
            setAvailable(state.page);
        });
        return () => storeListener.remove();
    });

    if (isLoading)
        return <CircularProgress/>;

    const result = new ListBuilder().build(available, props.columns, props.headerDrawer);

    result.push(<CustomPagination onChange={page => props.actions.setPage(page, state.page.size)}
                                  pageable={available.pageable}
                                  onRowsPerPageChange={e => props.actions.setPage(state.page.number, Number(e.target.value))}/>)
    return result;
}


class ListBuilder<M extends Model, G extends GroupedListItem<M>> {
    build(page: Page<G>, columns: GridColDef[], headerDrawer?: (group: G, key: String) => JSX.Element) {
        for (const row of page.list)
            this.processRow(row, columns, headerDrawer);

        if (this._rows.length > 0)
            this._result.push(this.drawDataGrid(this._rows, columns));

        return this._result;
    }

    processRow(row: G, columns: GridColDef[], headerDrawer?: (group: G, key: String) => JSX.Element) {
        this._keyId = this._keyId + 1;
        if (row.isHeader)
            this.processHeader(row, columns, headerDrawer);
        else
            this._rows.push(row.element);
    }

    processHeader(row: G, columns: GridColDef[], headerDrawer?: (group: G, key: String) => JSX.Element) {
        if (this._rows.length > 0) {
            this._result.push(this.drawDataGrid(this._rows, columns));
            this._rows = [];
        }

        if (headerDrawer === undefined)
            this._result.push(<Typography variant={"h6"} key={"header-" + this._keyId}>{row.header}</Typography>)
        else
            this._result.push(headerDrawer(row, "header-" + this._keyId));
    }

    drawDataGrid<M extends Model>(rows: M[], columns: GridColDef[]) {
        return (
            <DataGridContainer key={"data-grid-" + this._keyId}>
                <DataGrid getRowHeight={() => 'auto'}
                          rows={rows}
                          getRowId={(row: M) => row.id}
                          columns={columns}
                          checkboxSelection={false}
                          hideFooter={true}
                />
            </DataGridContainer>
        )
    }

    _result: JSX.Element[] = [];
    _rows: M[] = [];
    _keyId = 1;
}
