import {List} from "immutable";
import {AvailableResourceType} from "../../../model/AvailableResources";
import PrivateComponent from "../../../components/PrivateComponent";
import PlaceHolders from "../../../components/project/tabs/linguistic-assets/PlaceHolders";
import React from "react";

export default function ProjectPlaceholdersRoute() {
    const resources = List.of(
        AvailableResourceType.TranslationMemories,
        AvailableResourceType.Placeholders);
    return (
        <PrivateComponent resources={resources} readAllow={true}>
            <PlaceHolders/>
        </PrivateComponent>
    );
}

export const ProjectPlaceholdersPath = "placeholders";
export const ProjectPlaceholdersId = "ProjectPlaceholders";