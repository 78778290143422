import React, {RefObject, useEffect, useState} from "react";
import {Segment} from "../../../../model/Segment";
import Project from "../../../../model/Project";
import {styled} from "@mui/material/styles";
import {Checkbox, FormControlLabel, FormGroup, Icon, Stack, TableCell, TableRow, Tooltip} from "@mui/material";
import {
    addEditorCleanupToHistory,
    editorRawText,
    editorText,
    getUpdatedHistory,
    positionToSelection,
    withInlines,
    withPreviousState
} from "../../../../utils/slate/SlateUtils";
import Typography from "@mui/material/Typography";
import {Editable, ReactEditor, Slate, withReact} from "slate-react";
import {BaseEditor, createEditor, Editor, Range, Transforms} from "slate";
import {isKeyHotkey} from "is-hotkey";
import WarningIcon from '@mui/icons-material/Warning';
import Translation from "../../../../model/Translation";
import {
    approveTranslationAction,
    Direction,
    saveTranslationAction,
    setSegmentAction,
    setTargetEditorAction,
    shiftSegmentAction
} from "../../../../flux/segment/editor/SegmentEditorActions";
import {List, Map} from "immutable";
import {SearchResultPrefix} from "../existing-translations/ExistingTranslationsView";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {RenderedTargetElement} from "./rendering/RenderedTargetElement";
import {RenderedText} from "./rendering/RenderedText";
import {RenderedSourceElement} from "./rendering/RenderedSourceElement";
import {withHistory} from "slate-history";
import {ClipboardEvent} from "../../../../globals/Types";
import slateSerializer from "../../../../utils/slate/SlateSerializer";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {WorkflowStepModel} from "../../../../model/WorkflowStepModel";
import {
    deselectSegmentAction,
    selectSegmentAction,
    updateSegmentTranslationAction
} from "../../../../flux/segment/list/SegmentListActions";
import LockIcon from '@mui/icons-material/Lock';
import {dateTimeFormat} from "../../../../globals/Utils";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";

type SegmentViewProps = {
    onClick: (event: React.MouseEvent, editor: Editor) => void,
    segment: Segment,
    translation: Translation,
    index: number,
    reference: RefObject<HTMLTableRowElement>,
    project: Project,
    targetLanguage: string
    isEditing: boolean,
    isSelected: boolean,
    translationsRefs: Map<number, React.RefObject<HTMLTableRowElement>>
}

type SegmentsTableRowProps = {
    isEditing: boolean,
    isLocked: boolean
}

const SegmentsTableRow = styled(TableRow, {
    shouldForwardProp: propName => propName !== 'isEditing' && propName !== 'isLocked'
})<SegmentsTableRowProps>((props) => ({
    backgroundColor: rowColor(props),
    '&:hover': {
        backgroundColor: '#f6f6f6'
    }
}));

type TranslationCheckboxProps = {
    isLastStep: boolean
};

function rowColor(props: SegmentsTableRowProps) {
    if (props.isLocked)
        return 'rgba(198,198,224,0.6)';

    if (props.isEditing)
        return '#f3f0fb';

    return '#ffffff';
}

const TranslationCheckbox = styled(Checkbox, {
    shouldForwardProp: propName => propName !== 'isLastStep'
})<TranslationCheckboxProps>((props) => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
        color: props.isLastStep ? '#1BB65D' : '#757575'
    }
}));

const SegmentCell = styled(TableCell)({
    textAlign: 'center',
    verticalAlign: 'top'
});

const SegmentSourceCell = styled(TableCell)({
    wordWrap: 'break-word'
});

const CustomEditable = styled(Editable)({
    textAlign: 'start'
});

export default function SegmentView(props: SegmentViewProps) {
    const translation = props.translation;
    const stepName = translation.workflowStep ? translation.workflowStep.name : '';
    const isLastStep = props.translation.nextWorkflowStep.isEmpty();
    const firstStep = props.project.getFirstStep();
    const placeholderIds = slateSerializer.getSortedPlaceholderIds(props.segment.source);
    const deserializedTarget = slateSerializer.deserialize(translation.target, placeholderIds);
    const [sourceEditor] = useState(() => withInlines(withReact(createEditor())));
    const [targetEditor] = useState(() =>
        withInlines(withPreviousState(
            withReact(withHistory(createEditor())),
            getUpdatedHistory(translation.history, deserializedTarget.children))));

    const navigate = useNavigate();

    const placeholderPrefix = props.segment.id + "-source-";
    const approveTranslationId = "translation-checkbox-" + props.segment.id;

    useEffect(() => {
        if (props.isEditing) {
            ReactEditor.focus(targetEditor);
            setTargetEditorAction(targetEditor);
        }

        const selection = positionToSelection(translation.cursorPosition, targetEditor);
        if (selection != null)
            Transforms.select(targetEditor, selection);

    }, [props.isEditing, translation.target]);

    const drawApproveTranslation =
        <TranslationCheckbox isLastStep={isLastStep} disabled={!props.isEditing || isLastStep}
                             checked={stepName !== 'First'}
                             onChange={() => completeEditing(targetEditor, props, true)}
                             id={approveTranslationId}/>

    return (
        <SegmentsTableRow onClick={(e) => props.onClick(e, targetEditor)}
                          onKeyDown={(e) =>
                              handleKeyDown(e, sourceEditor, targetEditor, props, navigate)}
                          isEditing={props.isEditing}
                          isLocked={props.translation.isLocked}
                          ref={props.reference}>
            {drawSelector(props.isSelected, props.segment.id)}
            <SegmentCell style={{width: '5%'}}>
                <Stack>
                    <Typography textAlign={"left"}>{props.segment.order + 1}</Typography>
                    {drawComments(props.segment)}
                    {drawLock(props.translation)}
                </Stack>
            </SegmentCell>
            <SegmentSourceCell style={{width: '40%'}}>
                <Slate editor={sourceEditor}
                       initialValue={slateSerializer.deserialize(props.segment.source, placeholderIds).children}>
                    <CustomEditable readOnly={true}
                                    renderElement={(renderProps) =>
                                        <RenderedSourceElement children={renderProps.children}
                                                               element={renderProps.element}
                                                               attributes={renderProps.attributes} editor={targetEditor}
                                                               segment={props.segment}
                                                               targetLanguage={props.targetLanguage}
                                                               placeholderPrefix={placeholderPrefix}/>}
                                    renderLeaf={(props) =>
                                        <RenderedText children={props.children} attributes={props.attributes}
                                                      leaf={props.leaf} text={props.text}/>}
                                    onCopy={(event) => editorOnCopy(event, sourceEditor)}
                    />
                </Slate>
                <Typography fontSize={12} color={"#97A0AF"}>{props.segment.sourceId}</Typography>
            </SegmentSourceCell>
            <SegmentCell style={{width: '40%'}}>
                <Slate editor={targetEditor}
                       initialValue={deserializedTarget.children}
                       onChange={() =>
                           handleTargetChanged(props.segment, translation.languageCode, isLastStep, targetEditor)}>
                    <CustomEditable style={{padding: '0px 4px'}}
                                    renderElement={(props) =>
                                        <RenderedTargetElement children={props.children} element={props.element}
                                                               attributes={props.attributes}/>}
                                    renderLeaf={(props) =>
                                        <RenderedText children={props.children} attributes={props.attributes}
                                                      leaf={props.leaf}
                                                      text={props.text}/>}
                                    onDoubleClick={() => repairDoubleClickSelect()}
                                    readOnly={translation.isLocked}
                                    onKeyDown={(e) =>
                                        handleEditorKeyDown(e, targetEditor)}
                                    onBlur={(e) =>
                                        handleBlurred(e, approveTranslationId, placeholderPrefix, targetEditor, props)}
                                    onCopy={(event) => editorOnCopy(event, targetEditor)}
                    />
                </Slate>
            </SegmentCell>
            <SegmentCell style={{width: '5%'}}>
                {drawWarnings(translation, firstStep)}
            </SegmentCell>
            <SegmentCell style={{width: '10%'}}>
                <FormGroup>
                    <FormControlLabel control={drawApproveTranslation}
                                      disabled={translation.isLocked}
                                      label={stepName === 'First' ? '' : isLastStep ? 'Done' : stepName}
                    />
                </FormGroup>
            </SegmentCell>
        </SegmentsTableRow>
    );
}

function completeEditing(editor: ReactEditor,
                         props: SegmentViewProps,
                         isApprove: boolean) {
    const text = editorText(editor);
    if (text === "")
        return;

    if (isApprove)
        approveTranslationAction(props.segment, props.targetLanguage, text);
    else
        saveTranslationAction(props.segment, props.targetLanguage, text, null, null);
}

function editorOnCopy(event: ClipboardEvent, editor: BaseEditor) {
    event.preventDefault();
    const text = slateSerializer.serialize(editor.getFragment());
    event.clipboardData.setData("text/plain", text);
}

function handleBlurred(e: React.FocusEvent,
                       checkboxId: string,
                       placeholderPrefix: string,
                       editor: Editor,
                       props: SegmentViewProps) {
    const relatedTarget = e.relatedTarget;
    const targetId = relatedTarget?.id;

    if (targetId !== checkboxId && !targetId?.startsWith(placeholderPrefix)) {
        if (targetId?.startsWith(SearchResultPrefix)) {
            const text = editorRawText(editor);
            const state = segmentStore.getState();
            const updatedTranslation = props.segment.translation.set("target", text);
            const updatedSegment = props.segment.set("translation", updatedTranslation);
            setSegmentAction(updatedSegment, state.catFile, state.languageCode);
            updateSegmentTranslationAction(updatedSegment.id, updatedTranslation);
            return;
        }
        completeEditing(editor, props, false);
    }
}

function handleEditorKeyDown(e: React.KeyboardEvent<HTMLElement>, editor: Editor) {
    const {selection} = editor;
    const {nativeEvent} = e;

    if (isKeyHotkey('enter', nativeEvent)) {
        e.preventDefault();
        return;
    }

    if (selection && Range.isCollapsed(selection)) {
        if (isKeyHotkey('left', nativeEvent)) {
            e.preventDefault();
            Transforms.move(editor, {
                unit: 'offset',
                reverse: true
            });
            return;
        }
        if (isKeyHotkey('right', nativeEvent)) {
            e.preventDefault();
            Transforms.move(editor, {
                unit: 'offset'
            });
            return;
        }
        if (isKeyHotkey('backspace', nativeEvent)) {
            e.preventDefault();
            Transforms.delete(editor, {
                at: selection.anchor,
                distance: 1,
                unit: 'character',
                reverse: true
            });
            return;
        }
    }
}

function handleKeyDown(e: React.KeyboardEvent,
                       sourceEditor: Editor,
                       targetEditor: Editor,
                       props: SegmentViewProps,
                       navigate: NavigateFunction) {
    const available = hotKeys.filter(value => value.isHotkey(e));
    if (available.isEmpty())
        return;

    const sourceText = editorText(sourceEditor);
    const targetText = editorText(targetEditor);
    e.preventDefault();

    available.first()!.action({
        keyEvent: e,
        segmentViewProps: props,
        sourceText: sourceText,
        targetText: targetText,
        editor: targetEditor,
        navigate: navigate
    });
}

function handleTargetChanged(segment: Segment, languageCode: string, isLastStep: boolean, editor: Editor) {
    if (!isLastStep)
        return;
    const editingOperations = editor.operations.filter(op => op.type !== "set_selection");
    if (editingOperations.length > 0)
        approveTranslationAction(segment, languageCode, null);
}

/**
 *  Workaround for error: 'Failed to execute 'setEnd' on 'Range': There is no child at offset {}'
 *  Open issue: https://github.com/ianstormtaylor/slate/issues/5435
 *              https://github.com/udecode/plate/issues/2883
 *  Error description:  Slate crashes and displays the error message when you select text followed by a readOnly element (placeholder) and then press the delete key.
 *                      This occurs on word double-click selection, which partially selects the placeholder node
 *  Workaround description: To address the fix, the selection range is adjusted by setting the endOffset to the end of the selected text node.
 */
function repairDoubleClickSelect() {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const parentNode = range.commonAncestorContainer;
        const selectedNode = range.startContainer;

        if (selectedNode === null || parentNode === null)
            return;
        if (range.endContainer.nodeType != range.startContainer.nodeType) {
            const cloneRange = range.cloneRange();
            const endOffset = cloneRange.startOffset + cloneRange.toString().length;
            range.setEnd(selectedNode, endOffset);
        }
    }
}

function drawLock(translation: Translation) {
    const lock = translation.lock;

    if (lock == null)
        return null;

    const date = dateTimeFormat(lock.created);
    return (
        <Tooltip title={`Locked by ${lock.username} at ${date}`}>
            <LockIcon color={"primary"}/>
        </Tooltip>)
}

function drawComments(segment: Segment) {
    if (segment.commentsCount <= 0)
        return null;

    return (
        <Stack direction={"row"} alignItems={"center"}>
            <Icon>
                <img src={"/003-24x24.svg"} alt={"comments"} width={21} height={20}/>
            </Icon>
            <Typography fontSize={14} fontWeight={"bold"} color={"#158aff"}>
                {segment.commentsCount}
            </Typography>
        </Stack>
    )
}

function drawWarnings(translation: Translation, firstStep: WorkflowStepModel) {
    const hasErrors = translation.markupErrors.size > 0;
    const tryToApprove = translation.tryToApprove;
    const notFirstStep = firstStep && translation.workflowStep.id !== firstStep.id;
    if (hasErrors && (tryToApprove || notFirstStep)) {
        return <Tooltip title={translation.markupErrors.join('\n')}>
            <WarningIcon color={'error'}/>
        </Tooltip>;
    }
}

function drawSelector(isSelected: boolean, segmentId: number) {
    if (!segmentListStore.getState().withControls)
        return null;

    return (
        <SegmentCell style={{width: '42px'}}>
            <Checkbox id={`segment-selected-checkbox-${segmentId}`}
                      onChange={isSelected ? () => deselectSegmentAction(segmentId) : () => selectSegmentAction(segmentId)}
                      checked={isSelected}/>
        </SegmentCell>
    )
}

interface IHotkeyContext {
    keyEvent: React.KeyboardEvent
    segmentViewProps: SegmentViewProps,
    sourceText: string,
    targetText: string,
    editor: Editor,
    navigate: NavigateFunction
}

interface ISegmentHotkeys {
    isHotkey: (e: React.KeyboardEvent) => boolean
    action: (context: IHotkeyContext) => void
}

const hotKeys = List<ISegmentHotkeys>(
    [
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && e.key === 'Enter',
            action: (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const text = context.targetText;
                if (text === "")
                    return;

                approveTranslationAction(props.segment, props.targetLanguage, text)
                    .then(result => {
                        if (result)
                            shiftSegmentAction(props.translationsRefs, Direction.Down, context.navigate);
                    });
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && (e.key === 'Insert' || e.code === "KeyI"),
            action: (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const editor = context.editor;
                const segment = props.segment;
                addEditorCleanupToHistory(editor);
                saveTranslationAction(
                    segment,
                    props.targetLanguage,
                    context.sourceText,
                    null,
                    editor.history);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && /^[1-9]$/.test(e.key),
            action: (context: IHotkeyContext) => {
                const id = parseInt(context.keyEvent.key) - 1;
                const tmTranslation = segmentStore.getState().searchResults.list.get(id);
                if (!tmTranslation)
                    return;

                const props = context.segmentViewProps;
                const editor = context.editor;
                addEditorCleanupToHistory(editor);
                saveTranslationAction(
                    props.segment,
                    props.targetLanguage,
                    tmTranslation.target,
                    null,
                    editor.history);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'Enter',
            action: (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const text = context.targetText;
                if (text !== "")
                    saveTranslationAction(
                        props.segment,
                        props.targetLanguage,
                        text,
                        null,
                        null);
                shiftSegmentAction(props.translationsRefs, Direction.Down, context.navigate);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'ArrowUp',
            action: (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                shiftSegmentAction(props.translationsRefs, Direction.Up, context.navigate);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'ArrowDown',
            action: (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                shiftSegmentAction(props.translationsRefs, Direction.Down, context.navigate);
            }
        }
    ]);
