import Glossary from "../../../model/Glossary";

export enum GlossaryEditorAction {
    SetGlossary = "GlossaryEditorAction.SetGlossary",
    SetIsGlossaryExisted = "GlossaryEditorAction.SetIsGlossaryExisted"
}

interface ISetGlossaryPayload {
    action: GlossaryEditorAction.SetGlossary,
    glossary: Glossary
}

interface ISetIsGlossaryExistedPayload {
    action: GlossaryEditorAction.SetIsGlossaryExisted,
    isExisted: boolean
}

export type GlossaryEditorPayload = ISetGlossaryPayload
    | ISetIsGlossaryExistedPayload;

export function setEditedGlossary(glossary: Glossary): GlossaryEditorPayload {
    return {
        action: GlossaryEditorAction.SetGlossary,
        glossary: glossary
    }
}

export function setIsGlossaryExistedPayload(isExisted: boolean): GlossaryEditorPayload {
    return {
        action: GlossaryEditorAction.SetIsGlossaryExisted,
        isExisted: isExisted
    }
}
