import {Record} from "immutable";
import Project from "./Project";
import {AlertColor} from "@mui/material";

export enum AlertType {
    Editor,
    Global
}

interface IAlertCommon {
    message: string,
    severity: AlertColor,
    type: AlertType
}

interface IProjectAlert extends IAlertCommon {
    project: Project | null
}

const AlertRecord = Record<IAlertCommon>({
    message: "",
    severity: "error",
    type: AlertType.Global
});

const ProjectAlertRecord = Record<IProjectAlert>({
    message: "",
    severity: "error",
    type: AlertType.Global,
    project: null
})

export default class ResponseAlert extends AlertRecord {
};

export class ProjectAlert extends ProjectAlertRecord {
}