import {dispatcher} from "../Dispatcher";
import {setConcordanceSearchResults} from "./ConcordanceSearchPayload";
import {List} from "immutable";
import {executeConcordanceSearch} from "../../api/PretranslateApi";


export async function executeConcordanceSearchAction(valueToFind: string,
                                                     concordanceSearchType: string,
                                                     sourceLanguage: string,
                                                     targetLanguage: string,
                                                     translationMemoryNames: List<string>) {
    const page = await executeConcordanceSearch(valueToFind,
        concordanceSearchType,
        sourceLanguage,
        targetLanguage,
        translationMemoryNames);
    dispatcher.dispatch(setConcordanceSearchResults(page));
}