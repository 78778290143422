import {Map, Record} from "immutable";
import {IFilter} from "../../model/Filter";
import {GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel} from "@mui/x-data-grid";
import {addSearchParam, updateWithGridFilterModel, updateWithGridSortingModel} from "../../utils/ListViewUtils";

export interface ITranslationMemoryOperatorsFilter {
    nameOperator: string | null,
    sourceLanguageOperator: string | null
}

export interface ITranslationMemoryFilter extends ITranslationMemoryOperatorsFilter {
    projectId: number | null,
    name: string | null,
    sourceLanguage: string | null,
    orderBy: string | null,
    orderDirection: string | null
}

const TranslationMemoryFilterRecord = Record<ITranslationMemoryFilter>({
    projectId: null,
    name: null,
    nameOperator: null,
    sourceLanguage: null,
    sourceLanguageOperator: null,
    orderBy: null,
    orderDirection: null
});

export default class TranslationMemoryFilter extends TranslationMemoryFilterRecord
    implements IFilter<TranslationMemoryFilter> {
    updateWithGridFilterModel(model: GridFilterModel): TranslationMemoryFilter {
        return updateWithGridFilterModel<ITranslationMemoryFilter, TranslationMemoryFilter>(
            this,
            model,
            AvailableTranslationMemoryFilters);
    }

    updateWithGridSortingModel(model: GridSortModel): TranslationMemoryFilter {
        return updateWithGridSortingModel<ITranslationMemoryFilter, TranslationMemoryFilter>(
            this,
            model,
            AvailableTranslationMemorySortedFields);
    }

    toGridFilterModel(): GridFilterModel {
        const items: GridFilterItem[] = [];
        if (this.name && this.nameOperator)
            items.push({field: "name", operator: this.nameOperator, value: this.name});
        if (this.sourceLanguage && this.sourceLanguageOperator)
            items.push({field: "sourceLanguage", operator: this.sourceLanguageOperator, value: this.sourceLanguage});
        return {items: items};
    }

    toGridSortModel(): GridSortModel {
        if (this.orderBy && this.orderDirection) {
            const foundEntry = AvailableTranslationMemorySortedFields
                .findEntry(value => value === this.orderBy);
            if (foundEntry === undefined)
                return [];
            return [{field: foundEntry[0], sort: this.orderDirection as GridSortDirection}];
        }
        return [];
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "name", this.name);
        addSearchParam(result, "nameOperator", this.nameOperator);
        addSearchParam(result, "sourceLanguage", this.sourceLanguage);
        addSearchParam(result, "sourceLanguageOperator", this.sourceLanguageOperator);
        addSearchParam(result, "orderBy", this.orderBy);
        addSearchParam(result, "orderDirection", this.orderDirection);

        return result.join("&");
    }

    static fromSearchParams(projectId: number | null, searchParams: URLSearchParams) {
        let name = searchParams.get("name");
        if (name)
            name = decodeURIComponent(name);

        let sourceLanguage = searchParams.get("sourceLanguage");
        if (sourceLanguage)
            sourceLanguage = decodeURIComponent(sourceLanguage);

        return new TranslationMemoryFilter({
            projectId: projectId,
            name: name,
            nameOperator: searchParams.get("nameOperator"),
            sourceLanguage: sourceLanguage,
            sourceLanguageOperator: searchParams.get("sourceLanguageOperator"),
            orderBy: searchParams.get("orderBy"),
            orderDirection: searchParams.get("orderDirection")
        });
    }
}

const AvailableTranslationMemoryFilters = Map<string, string>()
    .set('name', 'nameOperator')
    .set('sourceLanguage', 'sourceLanguageOperator');
const AvailableTranslationMemorySortedFields = Map<string, string>()
    .set('name', 'name');