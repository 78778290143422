import React, {useEffect, useState} from "react";
import {Button, Chip, Container, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import PlaceholdersSet from "../../model/PlaceholdersSet";
import placeholdersSetsListStore from "../../flux/placeholders-set/list/PlaceholdersSetListStore";
import {useNavigate} from "react-router-dom";
import {PlaceholdersPath} from "../../routes/placeholders/PlaceholdersSetsRoute";
import {validatePlaceholder} from "../../api/PlaceholdersSetsApi";
import placeholdersSetEditorStore from "../../flux/placeholders-set/editor/PlaceholdersSetEditorStore";
import {updatePlaceholdersSetAction} from "../../flux/placeholders-set/editor/PlaceholdersSetEditorActions";
import {List} from "immutable";

const PlaceholdersBox = styled(Box)({
    background: '#eee'
});

const PlaceholderChip = styled(Chip)({
    background: '#fff'
})

export default function PlaceholdersSetEditor() {
    const initialPlaceholdersSetsListState = placeholdersSetsListStore.getState();
    const initialPlaceholdersEditorState = placeholdersSetEditorStore.getState();

    const [regExp, setRegExp] = useState<string>("");
    const [regExpInvalid, setRegExpInvalid] = useState<boolean>(false);
    const [regExps, setRegExps]
        = useState<List<string>>(List<string>(initialPlaceholdersEditorState.placeholdersSet
        ? initialPlaceholdersEditorState.placeholdersSet.placeholders.map(placeholder => placeholder.value)
        : List<string>()));
    const [placeholdersSet, setPlaceholdersSet]
        = useState<PlaceholdersSet | null>(initialPlaceholdersEditorState.placeholdersSet
        ? initialPlaceholdersEditorState.placeholdersSet
        : null);
    const [placeholdersSets, setPlaceholdersSets]
        = useState(initialPlaceholdersSetsListState.placeholdersSets);
    const [placeholdersSetName, setPlaceholdersSetName]
        = useState<string>(initialPlaceholdersEditorState.placeholdersSet
        ? initialPlaceholdersEditorState.placeholdersSet.name
        : "");

    const navigate = useNavigate();

    useEffect(() => {
        const placeholdersSetEditorListener
            = placeholdersSetEditorStore.addListener(() => {
            const state = placeholdersSetEditorStore.getState();
            const placeholdersSet = state.placeholdersSet;
            setPlaceholdersSet(placeholdersSet);
            if (placeholdersSet)
                setPlaceholdersSetName(placeholdersSet.name);
        });

        const placeholdersSetsListListener
            = placeholdersSetsListStore.addListener(() => {
            const state = placeholdersSetsListStore.getState();
            setPlaceholdersSets(state.placeholdersSets);
        });

        return () => {
            placeholdersSetEditorListener.remove();
            placeholdersSetsListListener.remove();
        }
    })

    const handleRegExpChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegExp(event.target.value);
    }

    const handlePlaceholdersSetNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlaceholdersSetName(event.target.value);
    }

    const handleAddClicked = () => {
        validatePlaceholder(regExp).then(isValid => {
            if (isValid) {
                setRegExps(regExps.push(regExp));
                setRegExp("");
                setRegExpInvalid(false);
            } else
                setRegExpInvalid(true);
        });
    }

    const handleDeleteClicked = (index: number) => {
        setRegExps(regExps.delete(index));
    }

    const handleCancelClicked = () => {
        navigate(PlaceholdersPath);
    }

    const handleSaveClicked = () => {
        updatePlaceholdersSetAction(
            placeholdersSet ? placeholdersSet.name : placeholdersSetName,
            placeholdersSetName,
            regExps);
        navigate(PlaceholdersPath);
    }

    const isDataValid = () => {
        return !regExps.isEmpty() && placeholdersSetName !== "" && isPlaceholdersSetNameValid();
    }

    const isPlaceholdersSetNameValid = () => {
        const existingSetFound = placeholdersSets
            .list.find(placeholdersSet => placeholdersSet.name === placeholdersSetName);
        if (!placeholdersSet) // new
            return !existingSetFound;
        else // edited
            return placeholdersSet.name === placeholdersSetName || !existingSetFound;
    }

    const isRegExpValid = () => {
        return regExp !== "";
    }

    return (
        <Container maxWidth={false}>
            <Stack mt={1}>
                <TextField label={"Placeholder preset name"} variant={"standard"} value={placeholdersSetName}
                           onChange={handlePlaceholdersSetNameChanged} error={!isPlaceholdersSetNameValid()}
                           helperText={isPlaceholdersSetNameValid()
                               ? ""
                               : "Placeholder preset with this name already exists"}/>
                <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
                    <TextField label={"Enter a regular expression"} variant={"standard"} fullWidth={true}
                               onChange={handleRegExpChanged} value={regExp} error={regExpInvalid}
                               helperText={regExpInvalid ? "Regular expression is invalid" : ""}/>
                    <Button variant={"contained"} size={"small"} onClick={handleAddClicked} disabled={!isRegExpValid()}>
                        Add
                    </Button>
                </Stack>
                <PlaceholdersBox mt={2} p={2}>
                    <Stack spacing={1}>
                        {regExps.isEmpty()
                            ? <Typography>Empty list</Typography>
                            : regExps.map((entry, index) => (
                                    <Stack direction={"row"} spacing={1} key={"placeholder-" + index}>
                                        <Typography>{index + 1}</Typography>
                                        <PlaceholderChip label={entry} variant={"outlined"} size={"small"}
                                                         onDelete={() => handleDeleteClicked(index)}/>
                                    </Stack>
                                )
                            )}
                    </Stack>
                </PlaceholdersBox>
                <Stack direction={"row"} justifyContent={"flex-end"} mt={2} spacing={1}>
                    <Button size={"small"} onClick={handleCancelClicked}>Cancel</Button>
                    <Button variant={"contained"} size={"small"} disabled={!isDataValid()} onClick={handleSaveClicked}>
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
}