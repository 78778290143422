import {deleteGlossaries, fetchGlossaries} from "../../../api/GlossaryApi";
import {CommonListActions} from "../../common/list/CommonListActions";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {List} from "immutable";
import Glossary from "../../../model/Glossary";
import GlossaryFilter from "../GlossaryFilter";
import {AvailableResourceType} from "../../../model/AvailableResources";

export const glossaryListActions
    = new CommonListActions<Glossary, GlossaryFilter>(
    fetchGlossaries,
    deleteGlossaries,
    async () => {
        dispatchUnsupportedOperation();
        return List<Glossary>();
    },
    AvailableResourceType.Glossaries)
