import {Report} from "../../../model/Report";
import {ReportDataType} from "../../../model/ReportDataType";
import {Page} from "../../../model/Page";

export enum ReportDataAction {
    SetReport = "ReportDataAction.SetReport",
    SetIsLoading = "ReportDataAction.ReportLoading",
    Cleanup = "ReportDataAction.Cleanup",
}

interface ISetReportData {
    action: ReportDataAction.SetReport,
    report: Report,
    data: Page<ReportDataType>
}

interface IReportDataLoading {
    action: ReportDataAction.SetIsLoading,
    isLoading: boolean
}

interface ICleanupReportData {
    action: ReportDataAction.Cleanup,
}

export type ReportDataPayload = ISetReportData
    | IReportDataLoading
    | ICleanupReportData;

export function setReportData(report: Report, data: Page<ReportDataType>): ReportDataPayload {
    return {
        action: ReportDataAction.SetReport,
        report: report,
        data: data
    }
}

export function setReportDataIsLoading(isLoading: boolean): ReportDataPayload {
    return {
        action: ReportDataAction.SetIsLoading,
        isLoading: isLoading
    }
}

export function cleanupReportData(): ReportDataPayload {
    return {
        action: ReportDataAction.Cleanup,
    }
}