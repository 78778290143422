import ProjectList from "../../components/project/ProjectList";
import ProjectListFilter from "../../flux/project/list/ProjectListFilter";

export default function ProjectsRoute() {
    return (
        <ProjectList/>
    );
}

export const ProjectsPath = "/projects";
export const ProjectsId = "Projects";
export function getProjectsListPath(filter: ProjectListFilter) {
    return ProjectsPath + "?" + filter.toSearchParams();
}