import {Breadcrumbs, Grid, Link} from "@mui/material";
import React from "react";
import {useMatches} from "react-router-dom";
import {MatchesType} from "../routes/Root";

export default function PageBreadcrumbs() {
    const matches = useMatches() as MatchesType;

    const crumbs: JSX.Element[] = matches
        .filter(match => Boolean(match.handle?.crumb))
        .map(match => match.handle.crumb(match.data));

    return (
        <Grid>
            <Breadcrumbs key={"page-header-breadcrumbs"}>
                <Link color={"inherit"} href={"/"}>Home</Link>
                {crumbs}
            </Breadcrumbs>
        </Grid>
    );
}