import {VendorEditor} from "../../components/vendor/VendorEditor";

export default function VendorRoute() {
    return (<VendorEditor/>);
}

export function getVendorPath(id: number) {
    return VendorPath.replaceAll(":id", String(id));
}

export const VendorPath = "/vendors/:id";