import {List, Record} from "immutable";

export type ReportCell = {
    value: string | number
    field: string
}

type IReportRow = {
    values: List<ReportCell>,
}

const ReportRowRecord = Record<IReportRow>({
    values: List()
});

export class ReportRow extends ReportRowRecord {
}
