import WorkflowStep from "../../model/WorkflowStep";

export interface IWorkflowStepResponse {
    id: number,
    name: string,
    order: number
}

export function toWorkflowStepModel(response: IWorkflowStepResponse) {
    return new WorkflowStep({
        id: response.id,
        name: response.name,
        order: response.order
    })
}