import {Model} from "../../../model/IModel";

export enum CommonBuildAction {
    Add = "CommonBuildAction.Add",
    Remove = "CommonBuildAction.Remove"
}

interface IAddPayload<M extends Model> {
    action: CommonBuildAction.Add,
    model: M,
    storeId: string
}

interface IRemovePayload<M extends Model> {
    action: CommonBuildAction.Remove,
    model: M,
    storeId: string
}

export type CommonBuildPayload = IAddPayload<any> | IRemovePayload<any>;

export function addBuildPayload<M extends Model>(storeId: string, model: M): CommonBuildPayload {
    return {
        action: CommonBuildAction.Add,
        model: model,
        storeId: storeId
    }
}

export function removeBuildPayload<M extends Model>(storeId: string, model: M): CommonBuildPayload {
    return {
        action: CommonBuildAction.Remove,
        model: model,
        storeId: storeId
    }
}