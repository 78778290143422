import {Button, Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import {ReactSetter} from "../../globals/Types";
import {Vendor} from "../../model/Vendor";
import PageBreadcrumbs from "../PageBreadcrumbs";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {saveVendorAction} from "../../flux/vendor/editor/VendorEditorActions";
import {getVendorPath} from "../../routes/vendor/VendorRoute";

export function NewVendorEditor() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [emptyName, setEmptyName] = useState(false)

    return (
        <Stack direction={"column"} alignItems={"start"} spacing={2} padding={2}>
            <PageBreadcrumbs/>

            <TextField fullWidth={true}
                       id="vendor-name"
                       label="Name"
                       variant="standard"
                       value={name}
                       error={emptyName}
                       helperText={emptyName ? "empty name" : ""}
                       onChange={(e) => {
                           setEmptyName(false);
                           setName(e.target.value);
                       }}/>
            <Button variant={"contained"} onClick={() => onSave(name, setEmptyName, navigate)}>
                Create
            </Button>

        </Stack>
    );
}

function onSave(name: string, setEmptyName: ReactSetter<boolean>, navigate: NavigateFunction) {
    if (name.trim() === '') {
        setEmptyName(true)
        return;
    }

    const vendor = new Vendor({name: name});
    saveVendorAction(vendor).then(value => navigate(getVendorPath(value!.id)));
}