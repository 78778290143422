import {Record} from "immutable";
import {IModel} from "./IModel";

interface IGroupedListItem<T extends IModel> {
    id: string | number,
    headerLevel: number | null,
    header: string | null,
    element: T | null,
}

const GroupedListItemRecord = Record<IGroupedListItem<any>>({
    id: "",
    headerLevel: null,
    header: null,
    element: null
})

export default class GroupedListItem<T extends IModel> extends GroupedListItemRecord implements IGroupedListItem<T> {
    get isHeader() {
        return this.element === null;
    }
}