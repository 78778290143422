import {
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Container,
    Divider,
    Link,
    Stack,
    TablePagination
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {List, Map, Set} from "immutable";
import {styled} from "@mui/material/styles";
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import UndoIcon from '@mui/icons-material/Undo';
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {eventGroupListActions} from "../../flux/event/EventGroupListActions";
import {CatEventGroup} from "../../model/CatEventGroup";
import {dateFormat, startOfDay, timeFormat} from "../../globals/Utils";
import authStore from "../../flux/auth/AuthStore";
import {CatEventType, eventTypePresentation} from "../../model/CatEventType";
import {EventListActions} from "../../flux/event/EventListActions";
import {CatEvent} from "../../model/CatEvent";
import ListView from "../common/ListView";
import PrivateComponent from "../PrivateComponent";
import {AvailableResourceType} from "../../model/AvailableResources";
import CatEventFilter from "../../flux/event/CatEventFilter";

const RollbackButton = styled(Button)({
    textTransform: "none"
})

export default function GroupedActivityList() {
    const state = eventGroupListActions.state;
    const [available, setAvailable] = useState(state.page);
    const [isLoading, setIsLoading] = useState(state.isLoading);
    const [expandedGroups, setExpandedGroups]
        = useState(Set<CatEventGroup>);

    useEffect(() => {
        const storeListener = eventGroupListActions.addListener(() => {
            const state = eventGroupListActions.state;
            setIsLoading(state.isLoading);
            setAvailable(state.page);
        });
        return () => storeListener.remove();
    });

    if (isLoading)
        return <CircularProgress/>;

    return (
        <Container maxWidth={false}>
            <Stack>
                {drawEventGroups(available.list, expandedGroups, setExpandedGroups)}
            </Stack>
            <Stack alignItems={"center"} marginY={1}>
                <TablePagination
                    component="div"
                    count={available.pageable.totalElements}
                    page={available.backendNumber}
                    onPageChange={(_event, page) =>
                        eventGroupListActions.setPage(page + 1, available.size)}
                    rowsPerPage={available.pageable.size}
                    onRowsPerPageChange={event =>
                        eventGroupListActions.setPage(available.number, parseInt(event.target.value))
                    }
                />
            </Stack>
        </Container>
    );
}

function drawEventGroups(groups: List<CatEventGroup>,
                         expandedGroups: Set<CatEventGroup>,
                         setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>) {
    return dayToEventGroupsMap(groups)
        .map((groups, day) =>
            drawEventGroupsDay(day, groups, expandedGroups, setExpandedGroups))
        .toList()
}

function drawEventGroupsDay(day: Date,
                            groups: List<CatEventGroup>,
                            expandedGroups: Set<CatEventGroup>,
                            setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>) {
    return (
        <Stack direction={"column"} key={"day-" + day.toString()}>
            <Divider sx={{marginY: 1}}>
                <Chip label={dateFormat(day)} variant={"outlined"}/>
            </Divider>
            {drawGroups(groups, expandedGroups, setExpandedGroups)}
        </Stack>
    )
}

function drawGroups(groups: List<CatEventGroup>,
                    expandedGroups: Set<CatEventGroup>,
                    setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>) {
    return groups.map((group, key) => drawGroup(group, expandedGroups, setExpandedGroups, key));
}

function drawGroup(eventGroup: CatEventGroup,
                   expandedGroups: Set<CatEventGroup>,
                   setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>,
                   key: number) {
    return (
        <Stack direction={"column"} key={"group-" + key}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Link fontWeight={"bold"}
                      fontSize={14}
                      underline={"none"}
                      component={hasDetails(eventGroup.eventType) ? "button" : "p"}
                      color={"inherit"}
                      textAlign={"left"}
                      onClick={() => handleExpandGroupClicked(eventGroup, expandedGroups, setExpandedGroups)}
                >
                    {`${userPresentation(eventGroup)} ${eventTypePresentation(eventGroup.eventType)}. ${filePresentation(eventGroup)}   `}
                </Link>
                <Stack direction={"row"} alignItems={"center"}>
                    {drawExpandIcon(eventGroup, expandedGroups, setExpandedGroups)}
                    {drawGroupRollback(eventGroup)}
                    <Typography fontWeight={"bold"} fontSize={14}>{timeFormat(eventGroup.date)}</Typography>
                </Stack>
            </Stack>
            {drawDetails(eventGroup, expandedGroups)}
        </Stack>
    )
}

function drawDetails(eventGroup: CatEventGroup, expandedGroups: Set<CatEventGroup>) {
    const isExpanded = isGroupExpanded(eventGroup, expandedGroups)
    let details = null;
    if (isExpanded && hasDetails(eventGroup.eventType)) {
        const actions = eventGroupListActions.getDetails(eventGroup);
        details = <ListView actions={actions} columns={columns(eventGroup, actions as EventListActions)}
                            selection={false} navigateProps={null} initialFilter={new CatEventFilter()}/>;
    }

    return (
        <Collapse in={isExpanded}>
            {details}
        </Collapse>
    )
}

function hasDetails(eventType: CatEventType) {
    switch (eventType) {
        case CatEventType.CatFileCreatedEvent:
        case CatEventType.CatFileDeletedEvent:
        case CatEventType.CatFileUpdatedEvent:
            return false;
        case CatEventType.CatFileRolledBackEvent:
        case CatEventType.SegmentCreatedEvent:
        case CatEventType.SegmentDeletedEvent:
        case CatEventType.SegmentUpdatedEvent:
        case CatEventType.SegmentRolledBackEvent:
        case CatEventType.TranslationRolledBackEvent:
        case CatEventType.SegmentTranslationSavedEvent:
        case CatEventType.TranslationAssignee:
        case CatEventType.Undefined:
        default:
            return true
    }
}

function userPresentation(eventGroup: CatEventGroup) {
    const user = authStore.getState().user
    if (!user)
        return "";

    if (user.id === eventGroup.userId)
        return "You:";
    else
        return `${eventGroup.username}:`;
}

function filePresentation(eventGroup: CatEventGroup) {
    return `File: "${eventGroup.catFileName}"`;
}

function dayToEventGroupsMap(groups: List<CatEventGroup>): Map<Date, List<CatEventGroup>> {
    return groups
        .reduce((map, item: CatEventGroup) => {
            const date = startOfDay(item.get('date'));
            if (!date)
                return map;

            return map.update(date, itemList => {
                if (!itemList)
                    return List([item]);
                return itemList.push(item);
            });

        }, Map());
}

function isGroupExpanded(eventGroup: CatEventGroup, expandedGroups: Set<CatEventGroup>) {
    return expandedGroups.contains(eventGroup);
}

function handleExpandGroupClicked(eventGroup: CatEventGroup,
                                  expandedGroups: Set<CatEventGroup>,
                                  setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>) {
    let updated;
    if (expandedGroups.contains(eventGroup))
        updated = expandedGroups.delete(eventGroup);
    else
        updated = expandedGroups.add(eventGroup);

    setExpandedGroups(updated);
}

function drawExpandIcon(eventGroup: CatEventGroup,
                        expandedGroups: Set<CatEventGroup>,
                        setExpandedGroups: React.Dispatch<React.SetStateAction<Set<CatEventGroup>>>) {
    if (!hasDetails(eventGroup.eventType))
        return null;

    return (
        <IconButton onClick={() => handleExpandGroupClicked(eventGroup, expandedGroups, setExpandedGroups)}>
            {isGroupExpanded(eventGroup, expandedGroups) ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
    )
}

function columns(eventGroup: CatEventGroup, eventListActions: EventListActions): GridColDef[] {
    let values: GridColDef[] = [];
    switch (eventGroup.eventType) {
        case CatEventType.TranslationRolledBackEvent:
        case CatEventType.SegmentTranslationSavedEvent:
            values = [
                {
                    field: 'source',
                    filterable: false,
                    sortable: false,
                    headerName: 'source',
                    width: 350
                },
                {
                    field: 'target',
                    filterable: false,
                    sortable: false,
                    headerName: 'target',
                    width: 350,
                    valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.dataAsJson.target
                },
                {
                    field: 'code',
                    filterable: false,
                    sortable: false,
                    headerName: 'code',
                    width: 50,
                    valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.dataAsJson.languageCode
                },
                {
                    field: 'workflowStepName',
                    filterable: false,
                    sortable: false,
                    headerName: 'workflow',
                    width: 120
                }
            ]
            break;
        case CatEventType.TranslationAssignee:
            values = [
                {
                    field: 'source',
                    filterable: false,
                    sortable: false,
                    headerName: 'source',
                    width: 350
                },
                {
                    field: 'target',
                    filterable: false,
                    sortable: false,
                    headerName: 'target',
                    width: 350,
                    valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.dataAsJson.target
                },
                {
                    field: 'code',
                    filterable: false,
                    sortable: false,
                    headerName: 'code',
                    width: 50,
                    valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.dataAsJson.languageCode
                },
                {
                    field: 'workflowStepName',
                    filterable: false,
                    sortable: false,
                    headerName: 'workflow',
                    width: 120,
                },
                {
                    field: 'bestMatchScore',
                    filterable: false,
                    sortable: false,
                    headerName: 'TM match',
                    width: 100,
                    valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.bestMatchScore
                }
            ]
            break;
        case CatEventType.CatFileCreatedEvent:
        case CatEventType.CatFileDeletedEvent:
        case CatEventType.CatFileUpdatedEvent:
            values = [
                {
                    field: 'catFileName',
                    filterable: false,
                    sortable: false,
                    headerName: 'name',
                    width: 500,
                }
            ]
            break;
        case CatEventType.CatFileRolledBackEvent:
            values = [
                {
                    field: 'catFileName',
                    filterable: false,
                    sortable: false,
                    headerName: 'name',
                    width: 500,
                },
                {
                    field: 'state',
                    filterable: false,
                    sortable: false,
                    headerName: 'state',
                    width: 100,
                    valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.dataAsJson.state
                }
            ]
            break;
        case CatEventType.SegmentCreatedEvent:
        case CatEventType.SegmentDeletedEvent:
        case CatEventType.SegmentUpdatedEvent:
        case CatEventType.SegmentRolledBackEvent:
            values = [
                {
                    field: 'data',
                    filterable: false,
                    sortable: false,
                    headerName: 'data',
                    width: 500,
                    valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.data
                }
            ]
            break;

        default:
            return [];
    }

    values.push({
        field: 'date',
        filterable: false,
        sortable: false,
        headerName: 'date',
        width: 60,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => timeFormat(params.row.date)
    })

    values.push({
        field: 'Rollback',
        headerName: '',
        align: 'right',
        type: 'actions',
        flex: 1,
        getActions: (params: GridRowParams<CatEvent>) => [
            drawEventRollback(params.row, eventListActions)
        ]
    })

    return values;
}

function drawEventRollback(catEvent: CatEvent, eventListActions: EventListActions) {
    if (!catEvent.revertible)
        return <Container/>;

    return (
        <PrivateComponent resource={AvailableResourceType.Events} writeAllow={true}>
            <RollbackButton startIcon={<UndoIcon/>} onClick={() => eventListActions.rollback(catEvent)}>
                Rollback
            </RollbackButton>
        </PrivateComponent>
    )
}

function drawGroupRollback(eventGroup: CatEventGroup) {
    if (!eventGroup.revertible)
        return <Container/>;

    return (
        <PrivateComponent resource={AvailableResourceType.Events} writeAllow={true}>
            <RollbackButton onClick={() => eventGroupListActions.rollback(eventGroup)}
                            startIcon={<UndoIcon/>}>
                Rollback
            </RollbackButton>
        </PrivateComponent>
    )
}