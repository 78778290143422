import {User} from "../../../model/User";
import {Page} from "../../../model/Page";

export enum UsersListAction {
    SetAdministrators = "UsersListAction.SetAdministrators",
    SetUsersWithPermissions = "UsersListAction.SetUsersWithPermissions"
}

interface ISetAdministrators {
    action: UsersListAction.SetAdministrators,
    administrators: Page<User>
}

interface ISetUsersWithPermissions {
    action: UsersListAction.SetUsersWithPermissions,
    users: Page<User>
}

export type UsersListPayload = ISetAdministrators | ISetUsersWithPermissions;

export function setAdministrators(administrators: Page<User>): UsersListPayload {
    return {
        action: UsersListAction.SetAdministrators,
        administrators: administrators
    }
}

export function setUsersWithPermissions(users: Page<User>): UsersListPayload {
    return {
        action: UsersListAction.SetUsersWithPermissions,
        users: users
    }
}