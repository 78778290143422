import {List} from "immutable";
import {Language} from "./Language";

export class LanguageList {
    constructor(languages: List<Language>) {
        this._languages = languages;
    }

    private readonly _languages: List<Language>;

    getLanguages(): List<Language> {
        return this._languages;
    }

    findByCode(code: string): Language | undefined {
        return this._languages.find(language => language.id === code);
    }

    static emptyList(){
        return new LanguageList(List());
    }
}