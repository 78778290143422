import {ReduceStore} from "flux/utils";
import {CommentListAction, CommentListPayload,} from "./CommentListPayload";
import {Record} from 'immutable';
import {Page} from "../../model/Page";
import {dispatcher, Payload} from "../Dispatcher";
import {SegmentComment} from "../../model/SegmentComment";


interface ICommentListState {
    isLoading: boolean,
    segmentId: number,
    languageCode: string,
    page: Page<SegmentComment>
}

const CommentStateFactory = Record<ICommentListState>({
    isLoading: true,
    segmentId: 0,
    languageCode: "",
    page: new Page<SegmentComment>().setSize(5)
});

class CommentListState extends CommentStateFactory {
}

export class CommentListStore extends ReduceStore<CommentListState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): CommentListState {
        return new CommentListState();
    }

    reduce(state: CommentListState, payload: CommentListPayload): CommentListState {
        switch (payload.action) {
            case CommentListAction.SetList:
                state = state
                    .set("page", payload.page)
                break
            case CommentListAction.SetFilters:
                state = state
                    .set("segmentId", payload.segmentId)
                    .set("languageCode", payload.languageCode)
                break;
            case CommentListAction.SetIsLoading:
                state = state.set("isLoading", payload.isLoading);
                break;

        }
        return state;
    }
}

const commentListStore = new CommentListStore();
export default commentListStore;