export enum PlaceholdersSetSelectAction {
    SetOpen = "PlaceholdersSetSelectAction.SetOpen",
    SetPlaceholdersSet = "PlaceholdersSetSelectAction.SetPlaceholdersSet"
}

interface ISetOpenPayload {
    action: PlaceholdersSetSelectAction.SetOpen,
    open: boolean
}

interface ISetPlaceholdersSetPayload {
    action: PlaceholdersSetSelectAction.SetPlaceholdersSet,
    placeholdersSet: number | null
}

export type PlaceholdersSetSelectPayload = ISetOpenPayload | ISetPlaceholdersSetPayload;

export function setSelectPlaceholdersSetMenuOpen(open: boolean): PlaceholdersSetSelectPayload {
    return {
        action: PlaceholdersSetSelectAction.SetOpen,
        open: open
    }
}

export function setSelectedPlaceholdersSetId(placeholdersSet: number | null): PlaceholdersSetSelectPayload {
    return {
        action: PlaceholdersSetSelectAction.SetPlaceholdersSet,
        placeholdersSet: placeholdersSet
    }
}