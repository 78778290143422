import {Record} from "immutable";
import {IFilter} from "../../model/Filter";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";

export interface INullOperatorsFilter {
}

export interface INullFilter extends INullOperatorsFilter {
}

const NullFilterRecord = Record<INullFilter>({
})

export default class NullFilter extends NullFilterRecord implements IFilter<NullFilter> {
    updateWithGridFilterModel(_model: GridFilterModel): NullFilter {
        return this;
    }

    updateWithGridSortingModel(_model: GridSortModel): NullFilter {
        return this;
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }
}