import {CatEventGroup} from "../../model/CatEventGroup";
import {CatEventType} from "../../model/CatEventType";
import {CatEvent} from "../../model/CatEvent";
import {CatEventObjectType} from "../../model/CatEventObjectType";
import {endOfDay, enumByValue, startOfDay} from "../../globals/Utils";
import {RollbackTasksState} from "../../model/RollbackTasksState";

export function toModel(response: IEventResponse) {
    return new CatEvent({
        id: response.eventId,
        eventObjectId: response.eventObjectId,
        eventObjectType: enumByValue(response.eventObjectType, Object.values(CatEventObjectType)),
        eventType: enumByValue(response.eventType, Object.values(CatEventType)),
        date: new Date(response.date),
        data: response.data,
        catFileName: response.catFileName,
        source: response.source,
        workflowStepName: response.workflowStepName,
        username: response.username,
        revertible: response.revertible
    });
}

export function toGroupModel(response: IEventGroupResponse) {
    return new CatEventGroup({
        date: new Date(response.date),
        userId: response.userId,
        eventType: enumByValue(response.eventType, Object.values(CatEventType)),
        eventObjectType: enumByValue(response.eventObjectType, Object.values(CatEventObjectType)),
        count: response.count,
        projectId: response.projectId,
        catFileId: response.catFileId,
        catFileName: response.catFileName,
        username: response.username,
        revertible: response.revertible
    });
}

export function toGroupWebRequest(group: CatEventGroup): IEventGroupRequest {
    return {
        from: startOfDay(group.date),
        to: endOfDay(group.date),
        userId: group.userId,
        eventType: group.eventType,
        eventObjectType: group.eventObjectType,
        projectId: group.projectId,
        catFileId: group.catFileId,
    }
}

export function toRollbackState(state: string) {
    return enumByValue(state, Object.values(RollbackTasksState));
}

export interface IEventResponse {
    username: string;
    eventId: string,
    eventObjectId: number,
    eventObjectType: string,
    eventType: string,
    date: Date,
    data: string
    catFileName: string,
    source: string,
    workflowStepName: string,
    revertible: boolean
}

export interface IEventGroupResponse {
    date: Date,
    userId: string,
    username: string,
    eventType: string,
    eventObjectType: string,
    count: number,
    projectId: number,
    catFileId: number,
    catFileName: string,
    revertible: boolean
}

export interface IEventGroupRequest {
    from: Date,
    to: Date,
    userId: string,
    eventType: string,
    projectId: number,
    catFileId: number,
    eventObjectType: string
}

