import {Vendor} from "../../../model/Vendor";

export enum VendorEditorAction {
    SetVendor = "VendorEditorAction.SetVendor",
}

interface ISetVendorPayload {
    action: VendorEditorAction.SetVendor,
    vendor: Vendor
}


export type VendorEditorPayload = ISetVendorPayload;

export function setVendor(vendor: Vendor): VendorEditorPayload {
    return {
        action: VendorEditorAction.SetVendor,
        vendor: vendor
    }
}