import {Button, Dialog, DialogTitle, Stack, Tabs} from "@mui/material";
import React, {useState} from "react";
import {saveUserPermissionsAction} from "../../flux/user-permissions/editor/PermissionsEditorActions";
import ListView from "../common/ListView";
import {SimpleTab} from "../../globals/CommonComponents";
import {workflowSelectActions} from "../../flux/workflow/select/WorkflowSelectActions";
import {projectLanguagesSelectActions} from "../../flux/language/select/ProjectLanguagesSelectActions";
import {catFileSelectActions} from "../../flux/cat-files/select/CatFileSelectActions";
import {defaultColumns} from "../../utils/ListViewUtils";
import NullFilter from "../../flux/common/NullFilter";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import {userListActions} from "../../flux/users/select/UserListActions";
import UsersListFilter from "../../flux/users/UsersListFilter";

interface IProps {
    open: boolean,
    onClose: () => void
}

export function PermissionEditor(props: IProps) {
    const [tab, setTab] = useState(0);

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>Set permissions</DialogTitle>
            <Stack direction={"column"} alignItems={"center"} spacing={1}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                        <SimpleTab label={"Users"}/>
                        <SimpleTab label={"Cat files"}/>
                        <SimpleTab label={"Workflow"}/>
                        <SimpleTab label={"Languages"}/>
                    </Tabs>
                </Stack>
                {drawTab(tab)}
            </Stack>
            <Button variant={"contained"}
                    onClick={() => {
                        saveUserPermissionsAction();
                        props.onClose();
                    }}>
                Save
            </Button>
        </Dialog>
    );
}

function drawTab(tab: number) {
    switch (tab) {
        case 0:
            return <ListView key={"select-permission-tab" + tab} actions={userListActions}
                             columns={defaultColumns(false)} navigateProps={null}
                             initialFilter={new UsersListFilter()}/>
        case 1:
            return <ListView key={"select-permission-tab" + tab} actions={catFileSelectActions}
                             columns={defaultColumns(false)} navigateProps={null}
                             initialFilter={new CatFilesFilter()}/>
        case 2:
            return <ListView key={"select-permission-tab" + tab} actions={workflowSelectActions}
                             columns={defaultColumns(false)} navigateProps={null}
                             initialFilter={new NullFilter()}/>
        case 3:
            return <ListView key={"select-permission-tab" + tab} actions={projectLanguagesSelectActions}
                             columns={defaultColumns(false)} navigateProps={null}
                             initialFilter={new NullFilter()}/>
        default:
            return null;
    }
}