export enum WorkflowStepType {
    MT = "MT",
    Pretranslate = "Pretranslate",
    Manual = "Manual",
    Root = "Root",
    Final = "Final"
}

export const WorkflowStepNames = ["Translation", "Proofreading"];

// TODO make it immutable
export class WorkflowStepModel {
    id?: number;
    name: string;
    type: WorkflowStepType;
    next: WorkflowStepModel[];

    constructor(id: number | undefined, name: string, type: WorkflowStepType, next: WorkflowStepModel[]) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.next = next;
    }

    addStep(name: string, type: WorkflowStepType): void {
        let currentStage: WorkflowStepModel = this;
        while (currentStage.next.length > 0) {
            currentStage = currentStage.next[0];
        }
        currentStage.next.push(new WorkflowStepModel(undefined, name, type, []));
    }

    editStep(index: number, name: string): void {
        let currentStage: WorkflowStepModel = this;
        let currentIndex = 0;
        while (currentIndex !== index) {
            if (currentStage.next.length === 0) {
                return;
            }
            currentStage = currentStage.next[0];
            currentIndex++;
        }
        currentStage.name = name;
    }

    removeStep(index: number): WorkflowStepModel | null {
        let previousStage: WorkflowStepModel | null = null;
        let currentStage: WorkflowStepModel = this;
        let currentIndex = 0;
        while (currentIndex !== index) {
            if (currentStage.next.length === 0) {
                return this;
            }
            previousStage = currentStage;
            currentStage = currentStage.next[0];
            currentIndex++;
        }
        if(previousStage === null) {
            if (currentStage.next.length === 0) {
                return null;
            }
            return currentStage.next[0];
        }
        previousStage.next = currentStage.next;
        return this;
    }
}