import {Segment} from "../../../model/Segment";
import {Page} from "../../../model/Page";
import SegmentListFilter from "./SegmentListFilter";

export enum SegmentListPayloadAction {
    SetSegmentListPage = "SegmentListPayloadAction.SetSegmentListPage",
    SetIsSegmentSaving = "SegmentListPayloadAction.SetIsSegmentSaving",
    SetIsUpdatingSegments = "SegmentListPayloadAction.SetIsUpdatingSegments",
    SetProjectUrl = "SegmentListPayloadAction.SetProjectUrl",
    ToggleSelectAll = "SegmentListPayloadAction.ToggleSelectAll",
    SelectSegment = "SegmentListPayloadAction.SelectSegment",
    DeselectSegment = "SegmentListPayloadAction.DeselectSegment",
    ToggleWithControls = "SegmentListPayloadAction.ToggleWithControls",
    ToggleClearTargetAlert = "SegmentListPayloadAction.ToggleClearTargetAlert",
    SetLastClickedSegment = "SegmentListPayloadAction.SetLastClickedSegment"
}

interface ISetSegmentListPagePayload {
    action: SegmentListPayloadAction.SetSegmentListPage,
    page: Page<Segment>,
    filter: SegmentListFilter
}

interface ISetIsSegmentSaving {
    action: SegmentListPayloadAction.SetIsSegmentSaving,
    isSegmentSaving: boolean
}

interface ISetIsUpdating {
    action: SegmentListPayloadAction.SetIsUpdatingSegments,
    isUpdating: boolean
}

interface ISetProjectUrlPayload {
    action: SegmentListPayloadAction.SetProjectUrl,
    projectUrl: string | null
}

interface IToggleSelectAll {
    action: SegmentListPayloadAction.ToggleSelectAll,
}

interface IToggleWithControls {
    action: SegmentListPayloadAction.ToggleWithControls,
}

interface IToggleClearTargetAlert {
    action: SegmentListPayloadAction.ToggleClearTargetAlert,
}

interface ISelectSegment {
    action: SegmentListPayloadAction.SelectSegment,
    id: number
}

interface ISetLastClickedSegment {
    action: SegmentListPayloadAction.SetLastClickedSegment,
    id: number
}

interface IDeselectSegment {
    action: SegmentListPayloadAction.DeselectSegment,
    id: number
}

export type SegmentListPayload = ISetSegmentListPagePayload
    | ISetIsSegmentSaving
    | ISetIsUpdating
    | ISetProjectUrlPayload
    | IToggleSelectAll
    | ISelectSegment
    | IDeselectSegment
    | IToggleWithControls
    | ISetLastClickedSegment
    | IToggleClearTargetAlert;

export function setSegmentListPage(page: Page<Segment>, filter: SegmentListFilter): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetSegmentListPage,
        page: page,
        filter: filter
    }
}

export function setIsSegmentSaving(isSegmentSaving: boolean): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetIsSegmentSaving,
        isSegmentSaving: isSegmentSaving
    }
}

export function setIsUpdating(isUpdating: boolean): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetIsUpdatingSegments,
        isUpdating: isUpdating
    }
}

export function setProjectUrl(projectUrl: string | null): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetProjectUrl,
        projectUrl: projectUrl
    }
}

export function toggleSelectAll(): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.ToggleSelectAll
    }
}

export function selectSegment(id: number): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SelectSegment,
        id: id
    }
}

export function deselectSegment(id: number): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.DeselectSegment,
        id: id
    }
}

export function toggleWithControls(): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.ToggleWithControls
    }
}

export function toggleClearTargetAlert(): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.ToggleClearTargetAlert
    }
}

export function setLastClickedSegment(id: number): SegmentListPayload {
    return {
        action: SegmentListPayloadAction.SetLastClickedSegment,
        id: id
    }
}