import TranslationMemoryList from "../components/translation-memory/TranslationMemoryList";
import React from "react";
import TranslationMemoryFilter from "../flux/translation-memory/TranslationMemoryFilter";

export default function TranslationMemoriesRoute() {
    return (
        <TranslationMemoryList/>
    );
}

export function getTranslationMemoriesPath(filter: TranslationMemoryFilter) {
    return TranslationMemoriesPath + "?" + filter.toSearchParams();
}

export const TranslationMemoriesPath = "/translation-memories";
export const TranslationMemoriesId = "TranslationMemories";

