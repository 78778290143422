import {ReduceStore} from "flux/utils";
import {Record} from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import {LanguageListAction, LanguageListPayload} from "./LanguageListPayload";
import {LanguageList} from "../../../model/LanguageList";

interface ILanguageListState {
    languages: LanguageList
}

const LanguageListStateFactory = Record<ILanguageListState>({
    languages: LanguageList.emptyList()
});

class LanguageListState extends LanguageListStateFactory {
}

export class LanguageListStore extends ReduceStore<LanguageListState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): LanguageListState {
        return new LanguageListState();
    }

    reduce(state: LanguageListState, payload: LanguageListPayload): LanguageListState {
        switch(payload.action) {
            case LanguageListAction.SetList:
                state = state.set("languages", payload.languages);
                break;
        }
        return state;
    }
}

const languageListStore = new LanguageListStore();
export default languageListStore;