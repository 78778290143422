import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchFetchListError, dispatchFetchPageError, toParameters} from "../globals/ApiGlobals";
import {List} from "immutable";
import {Page} from "../model/Page";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {Pageable} from "../model/Pageable";
import NullFilter from "../flux/common/NullFilter";
import {ITenantResponse, toTenantModel, toTenantRequest} from "./model/TenantApiModel";
import {Tenant} from "../model/Tenant";

export function fetchTenants(filter?: NullFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint,
        {},
        toParameters<NullFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<ITenantResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toTenantModel(value));
            return Page
                .fromBackendPage<Tenant>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<Tenant>);
}

export function saveTenants(tenants: List<Tenant>) {
    return axios
        .post(endpoint, tenants.map(value => toTenantRequest(value)).toJSON())
        .then((response: AxiosResponse<ITenantResponse>) => {
            return List.of(toTenantModel(response.data));
        })
        .catch(dispatchFetchListError<Tenant>);
}

const endpoint = "/api/common/tenants";