import {
    Autocomplete,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@mui/material";
import languageListStore from "../../flux/language/list/LanguageListStore";
import {useEffect, useState} from "react";
import {List} from "immutable";
import {Language} from "../../model/Language";
import translationMemoryEditorStore from "../../flux/translation-memory/editor/TranslationMemoryEditorStore";
import TranslationMemory from "../../model/TranslationMemory";
import {
    createTranslationMemoryAction,
    setEditedTranslationMemoryAction
} from "../../flux/translation-memory/editor/TranslationMemoryEditorActions";
import {handleLanguageOptionRendered} from "../../utils/LanguageUtils";

interface IProps {
    open: boolean;
    onClose: () => void;
}

export function EmptyTranslationMemoryEditor(props: IProps) {
    const supportedLanguages = languageListStore.getState().languages;
    const state = translationMemoryEditorStore.getState();

    const [tm, setTm] = useState(state.tm);
    const [isTmExisted, setIsTmExisted] = useState(state.tmIsExisted);

    useEffect(() => {
        const tmEditorListener = translationMemoryEditorStore.addListener(() => {
            const state = translationMemoryEditorStore.getState();
            setTm(state.tm);
            setIsTmExisted(state.tmIsExisted);
        });

        return () => tmEditorListener.remove();
    });

    return <Dialog open={props.open} fullWidth>
        <DialogTitle>Create Translation Memory</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField color={isTmExisted ? 'warning' : 'primary'}
                               helperText={isTmExisted ? "TM with this name is already exists" : ""}
                               label={"Translation memory name"}
                               variant={"standard"} fullWidth
                               onChange={e => setEditedTranslationMemoryAction(tm.set("name", e.target.value))}/>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete renderInput={params => <TextField {...params} label={"Source"} variant={"standard"}/>}
                                  options={supportedLanguages
                                      .getLanguages()
                                      .filter(language =>
                                          !tm.targetLanguages.find(targetLanguage => targetLanguage.id === language.id))
                                      .toArray()}
                                  renderOption={handleLanguageOptionRendered}
                                  getOptionLabel={(option: Language) => option.name}
                                  value={tm.sourceLanguage}
                                  onChange={(_e, value) =>
                                      value && setEditedTranslationMemoryAction(tm.set("sourceLanguage", value))}/>
                </Grid>
                <Grid item xs={8}>
                    <Autocomplete multiple renderInput={params => <TextField {...params} label={"Target"}
                                                                             variant={"standard"}/>}
                                  options={supportedLanguages
                                      .getLanguages()
                                      .filter(language => language.id !== tm.sourceLanguage?.id)
                                      .toArray()}
                                  renderOption={handleLanguageOptionRendered}
                                  getOptionLabel={(option: Language) => option.name}
                                  renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option, index) => <Chip {...getTagProps({index})}
                                                                            key={"target-" + option.name}
                                                                            label={option.name} size={"small"}/>)}
                                  value={tm.targetLanguages.toArray()}
                                  onChange={(_e, value) =>
                                      setEditedTranslationMemoryAction(tm.set("targetLanguages", List(value)))}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button disabled={isDisabled(tm, isTmExisted)} onClick={() => handleSubmit(tm, props.onClose)}>
                Create
            </Button>
            <Button onClick={props.onClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
}

function isDisabled(tm: TranslationMemory, isTmExisted: boolean) {
    return tm.name == "" || tm.sourceLanguage == null || tm.targetLanguages.isEmpty() || isTmExisted;
}

function handleSubmit(tm: TranslationMemory, onClose: () => void) {
    createTranslationMemoryAction(tm);
    onClose();
}