import {Record} from "immutable";

interface IVendor {
    id: number,
    name: string,
}

const VendorRecord = Record<IVendor>({
    name: '',
    id: 0,
});

export class Vendor extends VendorRecord {
}