import {List, Set} from "immutable";
import axios, {AxiosResponse} from "axios";
import {
    buildUrl,
    dispatchError,
    dispatchFetchPageError,
    MultipartFormDataConfig,
    toParameters
} from "../globals/ApiGlobals";
import {CatFile, TypeOfCatFile} from "../model/CatFile";
import {Page} from "../model/Page";
import {
    ICatFileBuildResponse,
    ICatFileResponse,
    IRequestUploadFile,
    toCatFileBuildModel,
    toCatFileModels
} from "./model/CatFileApiModel";
import {Pageable} from "../model/Pageable";
import CatFilesFilter from "../flux/cat-files/CatFilesFilter";
import {Language} from "../model/Language";
import {filterLanguages} from "../utils/ListViewUtils";
import {Build} from "../model/Build";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";

export function fetchFiles(filter: CatFilesFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<CatFilesFilter>(filter, page));
    const languageFilter = filter.language;

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<ICatFileResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .flatMap(value => {
                    const languages = filter.targetLanguages.map(l => Language.fromSupported(l));
                    return toCatFileModels(
                        value,
                        filterLanguages(
                            languages,
                            languageFilter === null
                                ? []
                                : typeof languageFilter === "string" ? [languageFilter] : languageFilter,
                            filter.languageOperator));
                });
            return Page
                .fromBackendPage<CatFile>(response.data.page)
                .setList(List(list));
        })
        .catch(dispatchFetchPageError<CatFile>);
}

export function fetchFile(id: number, targetLanguage: Language) {
    return axios
        .get(endpoint + "/" + id)
        .then((response: AxiosResponse<ICatFileResponse>) => {
            return toCatFileModels(response.data, List.of(targetLanguage)).at(0);
        })
        .catch((error) => {
            dispatchError(error);
            return new CatFile({});
        });
}

export function uploadFile(file: File, projectId: number, name: string, multiLanguage: boolean) {
    const fileType = fileNameToFileType(name, multiLanguage);
    if (!fileType)
        return

    const request: IRequestUploadFile = {
        projectId: projectId,
        file: file,
        fileName: name,
        fileType: fileType
    };
    axios
        .post(endpoint, request, MultipartFormDataConfig)
        .catch(dispatchError);
}

export function updateTranslations(file: File, catFile: CatFile) {
    axios
        .post(endpoint + "/" + catFile.id + "/updateTranslations", {file: file}, MultipartFormDataConfig)
        .catch(dispatchError);
}

export function deleteFiles(catFiles: Set<CatFile>) {
    return Promise
        .all(catFiles.map(value => deleteFile(value.id)))
        .then(() => {
        });
}

export function deleteFile(id: number) {
    axios
        .delete(endpoint + "/" + id)
        .catch(dispatchError);
}

export function buildFile(catFile: CatFile) {
    return axios
        .post(endpoint + "/" + catFile.id + "/" + catFile.language + "/build")
        .then((response: AxiosResponse<ICatFileBuildResponse>) => toCatFileBuildModel(response.data))
        .catch((error) => {
            dispatchError(error);
            return new Build();
        })
}

export function buildFileTranslations(catFile: CatFile) {
    return axios
        .post(endpoint + "/" + catFile.id + "/" + catFile.language + "/buildTranslations")
        .then((response: AxiosResponse<ICatFileBuildResponse>) => toCatFileBuildModel(response.data))
        .catch((error) => {
            dispatchError(error);
            return new Build();
        })
}

function fileNameToFileType(fileName: string, multiLanguage: boolean) {

    if (fileName.endsWith(".html") || fileName.endsWith(".htm"))
        return TypeOfCatFile.Html;

    if (fileName.endsWith(".md"))
        return TypeOfCatFile.Md;

    if (fileName.endsWith(".docx"))
        return TypeOfCatFile.Docx;

    if (fileName.endsWith(".pptx"))
        return TypeOfCatFile.Pptx;

    if (fileName.endsWith(".xlsx"))
        return TypeOfCatFile.Xlsx;

    if (fileName.endsWith(".txt"))
        return TypeOfCatFile.Txt;

    if (fileName.endsWith(".po"))
        return TypeOfCatFile.Po;

    if (!fileName.endsWith(".xliff"))
        return null;

    if (multiLanguage)
        return TypeOfCatFile.SmartcatXliff;
    else
        return TypeOfCatFile.SmartcatXliffNoId;
}

const endpoint = "/api/projects/catFiles";
