import {AvailableResources} from "../../model/AvailableResources";

export enum AvailableResourcesAction {
    Set = "AvailableResourcesAction.Set"
}

interface ISet {
    action: AvailableResourcesAction.Set,
    resources: AvailableResources
}

export type AvailableResourcesPayload = ISet;

export function setAvailableResourcesPayload(resources: AvailableResources): AvailableResourcesPayload {
    return {
        action: AvailableResourcesAction.Set,
        resources: resources
    }
}