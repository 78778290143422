import React, {useEffect, useState} from "react";
import {CircularProgress, Container, ListItemAvatar, ListItemText, Pagination} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import reportListStore from "../../flux/report/list/ReportListStore";
import Immutable from "immutable";
import {Report, ReportState} from "../../model/Report";
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import ListItemButton from "@mui/material/ListItemButton";
import {useInterval} from "../../globals/CustomHooks";
import {Page} from "../../model/Page";
import {dateTimeFormat} from "../../globals/Utils";
import {refreshReportListAction, setReportListPageNumberAction} from "../../flux/report/list/ReportListActions";
import ReportListItemSecondaryActions from "./ReportListItemSecondaryActions";

type ReportListProps = {
    onClick: (report: Report) => void
}

export default function ReportList(props: ReportListProps) {
    const state = reportListStore.getState();
    const [page, setPage] = useState(state.page);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const reportListener = reportListStore.addListener(() => {
            const state = reportListStore.getState();
            setPage(state.page);
            setIsLoading(state.isLoading);
        });

        return () => reportListener.remove();
    }, []);

    let listItems = <CircularProgress/>;
    if (!isLoading)
        listItems = generateList(state.page.list, props.onClick, page);

    useInterval(() => refreshReportListAction(), 2000);

    return (
        <Container>
            <List>
                {listItems}
            </List>
        </Container>
    )
}

function generateList(list: Immutable.List<Report>,
                      onClick: (report: Report) => void,
                      page: Page<Report>) {
    const listItems = generateListItems(list, onClick);
    return (
        <List>
            {listItems}
            <Pagination count={page.totalPages}
                        onChange={(_event, pageNumber) =>
                            setReportListPageNumberAction(pageNumber)}
                        page={page.number}/>
        </List>
    );
}

function generateListItems(list: Immutable.List<Report>, onClick: (report: Report) => void) {
    return list.map((report) => {
        let text = dateTimeFormat(report.created);
        let avatar;
        let isDisabledItem = true;
        let itemOnClick = () => {
        };
        let actionsDisabled = false;
        switch (report.state) {
            case ReportState.Available:
                avatar = <DoneIcon color={"success"}/>
                itemOnClick = () => onClick(report);
                isDisabledItem = false;
                break;
            case ReportState.Generating:
            case ReportState.Downloading:
                avatar = <CircularProgress/>
                actionsDisabled = true;
                break;
            case ReportState.Error:
            default:
                avatar = <ErrorIcon color={"error"}/>
                break;
        }

        return (
            <ListItem key={report.id} secondaryAction={<ReportListItemSecondaryActions actionsDisabled={actionsDisabled}
                                                                       report={report}/>}>
                <ListItemButton onClick={itemOnClick} disabled={isDisabledItem}>
                    <ListItemAvatar>{avatar}</ListItemAvatar>
                    <ListItemText primary={text} onClick={itemOnClick}/>
                </ListItemButton>
            </ListItem>)
    });
}