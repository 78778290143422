import Immutable from "immutable";

interface IGlossary {
    id: number,
    name: string
}

const Record = Immutable.Record<IGlossary>({
    id: 0,
    name: ""
});

export default class Glossary extends Record {
}