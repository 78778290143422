import {CommonListActions} from "../../common/list/CommonListActions";
import TranslationMemory, {ProjectTranslationMemory} from "../../../model/TranslationMemory";
import TranslationMemoryFilter from "../TranslationMemoryFilter";
import {deleteTranslationMemories, fetchTranslationMemories} from "../../../api/TranslationMemoryApi";
import {dispatchProjectAlert, dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {List} from "immutable";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {Pageable} from "../../../model/Pageable";
import {fetchProjectTranslationMemories} from "../../../api/ProjectTranslationMemoryApi";
import {BuildState} from "../../../model/Build";
import projectPageStore from "../../project/page/ProjectPageStore";
import {fetchProject} from "../../../api/ProjectApi";
import {timeoutPromise} from "../../../globals/Utils";
import {AlertType} from "../../../model/Alert";
import alertStore from "../../alert/AlertStore";

export const translationMemoryListActions
    = new CommonListActions<TranslationMemory, TranslationMemoryFilter>(
    fetchTranslationMemories,
    deleteTranslationMemories,
    async () => {
        dispatchUnsupportedOperation();
        return List<TranslationMemory>();
    },
    AvailableResourceType.TranslationMemories
);

export const projectTranslationMemoryListActions
    = new CommonListActions<ProjectTranslationMemory, TranslationMemoryFilter>(
    fetchProjectTranslationMemoriesAction,
    async () => {
        dispatchUnsupportedOperation();
    },
    async () => {
        dispatchUnsupportedOperation();
        return List<ProjectTranslationMemory>();
    },
    AvailableResourceType.TranslationMemories
);

async function fetchProjectTranslationMemoriesAction(filter: TranslationMemoryFilter, page?: Pageable) {
    const result = await fetchProjectTranslationMemories(filter, page);
    const currentBuild = result.list.find(value => value.state !== null)
    if (currentBuild && filter.projectId) {
        let project = projectPageStore.getState().project;
        if (filter.projectId !== project.id)
            project = await fetchProject(filter.projectId);
        const waitingForAlert = alertStore.getState().waitingForAlert;
        if (currentBuild.state === BuildState.Error || currentBuild.errors && currentBuild.errors.length > 0) {
            waitingForAlert && dispatchProjectAlert(
                "The process of recording translations into the translation memory has errors.",
                project,
                AlertType.Global,
                "error");
        } else if (currentBuild.state === BuildState.Available) {
            waitingForAlert && dispatchProjectAlert(
                "The process of recording translations into the translation memory has been successfully completed.",
                project,
                AlertType.Global,
                "success");
        } else {
            timeoutPromise(1000).then(() => {
                projectTranslationMemoryListActions.refresh(false);
            })
        }
    }
    return result;
}

