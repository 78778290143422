import {User} from "../../../model/User";
import {fetchUsers} from "../../../api/UserApi";
import {CommonListActions} from "../../common/list/CommonListActions";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {List} from "immutable";
import UsersListFilter from "../UsersListFilter";

export const userListActions = new CommonListActions<User, UsersListFilter>(
    fetchUsers,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<User>();
    },
    AvailableResourceType.Users
);