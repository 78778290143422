import {List, Record} from "immutable";
import {IReportData} from "./ReportDataType";

interface IAssigneeReportDimension {
    catFileId: number
    catFileName: string,
    workflowStepId: number,
    workflowStep: string,
    languageCode: string,
    username: string
}

interface IAssigneeReportData extends IReportData {
    dimension: IAssigneeReportDimension,
}

const ReportDataRecord = Record<IAssigneeReportData>({
    dimension: {
        catFileId: 0,
        catFileName: "",
        languageCode: "",
        username: "",
        workflowStepId: 0,
        workflowStep: "",
    },
    columns: List(),
    rows: List(),
});

export class AssigneeReportData extends ReportDataRecord {
}

