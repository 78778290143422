import {Dispatcher} from 'flux';
import {AlertPayload} from "./alert/AlertPayload";
import {TranslationMemoryEditorPayload} from "./translation-memory/editor/TranslationMemoryEditorPayload";
import {ProjectListPayload} from "./project/list/ProjectListPayload";
import {ProjectPagePayload} from "./project/page/ProjectPagePayload";
import {ProjectEditorPayload} from "./project/editor/ProjectEditorPayload";
import {SegmentListPayload} from "./segment/list/SegmentListPayload";
import {SegmentEditorPayload} from "./segment/editor/SegmentEditorPayload";
import {GlossaryEditorPayload} from "./glossary/editor/GlossaryEditorPayload";
import {ConcordanceSearchPayload} from "./concordance-search/ConcordanceSearchPayload";
import {ProjectSearchPayload} from "./project/search/ProjectSearchPayload";
import {PlaceholdersSetListPayload} from "./placeholders-set/list/PlaceholdersSetListPayload";
import {PlaceholdersSetEditorPayload} from "./placeholders-set/editor/PlaceholdersSetEditorPayload";
import {AuthPayload} from "./auth/AuthPayload";
import {PlaceholdersSetSelectPayload} from "./placeholders-set/select/PlaceholdersSetSelectPayload";
import {ReportDataPayload} from "./report/data/ReportDataPayload";
import {ReportListPayload} from "./report/list/ReportListPayload";
import {AvailableResourcesPayload} from "./available-resources/AvailableResourcesPayload";
import {CommonListPayload} from "./common/list/CommonListPayload";
import {CommentListPayload} from "./comment/CommentListPayload";
import {RollbackPayload} from "./event/rollback/RollbackPayload";
import {CommonBuildPayload} from "./common/build/CommonBuildPayload";
import {LanguageListPayload} from "./language/list/LanguageListPayload";
import {UsersListPayload} from "./users/list/UsersListPayload";
import {VendorEditorPayload} from "./vendor/editor/VendorEditorPayload";

export type Payload =
    | TranslationMemoryEditorPayload
    | GlossaryEditorPayload
    | PlaceholdersSetListPayload
    | PlaceholdersSetEditorPayload
    | PlaceholdersSetSelectPayload
    | ProjectPagePayload
    | ProjectListPayload
    | ProjectEditorPayload
    | ProjectSearchPayload
    | AuthPayload
    | SegmentListPayload
    | SegmentEditorPayload
    | AlertPayload
    | ConcordanceSearchPayload
    | ReportDataPayload
    | ReportListPayload
    | CommentListPayload
    | AvailableResourcesPayload
    | CommonListPayload
    | CommonBuildPayload
    | RollbackPayload
    | LanguageListPayload
    | UsersListPayload
    | VendorEditorPayload;

class CustomDispatcher<Payload> extends Dispatcher<Payload> {
    dispatch(payload: Payload) {
        // console.log('Dispatching payload: ', payload);
        super.dispatch(payload);
    }
}

export const dispatcher = new CustomDispatcher<Payload>();