import {ReduceStore} from "flux/utils";
import Immutable from "immutable";
import {ProjectEditorAction, ProjectEditorPayload} from "./ProjectEditorPayload";
import {dispatcher, Payload} from "../../Dispatcher";

interface IProjectEditorState {
    open: boolean,
    files: Immutable.List<File>,
    multiLanguage: boolean,
    step: number
}

const ProjectEditorStateFactory = Immutable.Record<IProjectEditorState>({
    open: false,
    files: Immutable.List<File>(),
    multiLanguage: false,
    step: 0
});

class ProjectEditorState extends ProjectEditorStateFactory {
}

export class ProjectEditorStore extends ReduceStore<ProjectEditorState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ProjectEditorState {
        return new ProjectEditorState();
    }

    reduce(state: ProjectEditorState, payload: ProjectEditorPayload): ProjectEditorState {
        switch (payload.action) {
            case ProjectEditorAction.SetFiles:
                return state.set("files", payload.files);
            case ProjectEditorAction.SetStep:
                return state.set("step", payload.step);
            case ProjectEditorAction.SetIsOpen:
                if (payload.open) {
                    return state.set("open", true);
                }
                return this.getInitialState();
            case ProjectEditorAction.SetMultiLanguageMode:
                return state.set("multiLanguage", payload.multiLanguageMode);
        }
        return state;
    }
}

const projectEditorStore = new ProjectEditorStore();
export default projectEditorStore;