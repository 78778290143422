import {CommonListActions} from "../common/list/CommonListActions";
import NullFilter from "../common/NullFilter";
import {dispatchUnsupportedOperation} from "../../globals/ApiGlobals";
import {Tenant} from "../../model/Tenant";
import {fetchTenants, saveTenants} from "../../api/TenantApi";
import {AvailableResourceType} from "../../model/AvailableResources";

export const tenantListActions = new CommonListActions<Tenant, NullFilter>(
    (filter, page) => fetchTenants(filter,page),
    async () => {
        dispatchUnsupportedOperation();
        return;
    },
    saveTenants,
    AvailableResourceType.Admin
);

