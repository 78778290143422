import {Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";
import {Report} from "../../model/Report";
import {deleteReport, downloadReport} from "../../api/ReportApi";

type ReportListItemSecondaryActionsProps = {
    actionsDisabled: boolean,
    report: Report
}

export default function ReportListItemSecondaryActions(props: ReportListItemSecondaryActionsProps) {
    let onClickDeleteButton = () => deleteReport(props.report);
    let onClickDownloadButton = () => downloadReport(props.report);

    return (
        <Stack direction={"row"}>
            <IconButton edge="end"
                        aria-label="delete"
                        onClick={onClickDeleteButton}>
                <DeleteIcon/>
            </IconButton>
            <IconButton edge="end"
                        aria-label="download"
                        disabled={props.actionsDisabled}
                        onClick={onClickDownloadButton}>
                <DownloadIcon/>
            </IconButton>
        </Stack>
    );
}