import React, {useState} from "react";
import {Container} from "@mui/material";
import {RightBottomFab} from "../../globals/CommonComponents";
import ReportDataView from "./ReportDataView";
import {Settings} from "@mui/icons-material";
import ReportSettings from "./ReportSettings";
import ReportProjectFiles from "./ReportProjectFiles";

export default function ReportView() {
    const [openSettings, setOpenSettings] = useState(true);
    const [openProjectFiles, setOpenFiles] = useState(false);

    return (
        <Container>
            <ReportSettings
                isOpen={openSettings}
                onClose={() => setOpenSettings(false)}
                openProjectFiles={() => setOpenFiles(true)}
            />
            <ReportDataView/>
            <RightBottomFab color="primary"
                            aria-label="settings"
                            onClick={() => setOpenSettings(true)}>
                <Settings/>
            </RightBottomFab>
            <ReportProjectFiles isOpen={openProjectFiles}
                                onClose={() => setOpenFiles(false)}
            />
        </Container>
    );
}