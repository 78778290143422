import {Page} from "../../model/Page";
import {SegmentComment} from "../../model/SegmentComment";

export enum CommentListAction {
    SetList = "CommentListAction.SetList",
    SetFilters = "CommentListAction.SetFilters",
    SetIsLoading = "CommentListAction.SetIsLoading",
}

interface ISetCommentList {
    action: CommentListAction.SetList,
    page: Page<SegmentComment>
}

interface ISetFilters {
    action: CommentListAction.SetFilters,
    segmentId: number,
    languageCode: string
}

interface ISetIsLoading {
    action: CommentListAction.SetIsLoading,
    isLoading: boolean
}

export type CommentListPayload = ISetCommentList
    | ISetFilters
    | ISetIsLoading;

export function setCommentListPayload(page: Page<SegmentComment>): CommentListPayload {
    return {
        action: CommentListAction.SetList,
        page: page
    }
}

export function setCommentListFiltersPayload(segmentId: number, languageCode: string): CommentListPayload {
    return {
        action: CommentListAction.SetFilters,
        segmentId: segmentId,
        languageCode: languageCode
    }
}

export function commentListIsLoadingPayload(isLoading: boolean): CommentListPayload {
    return {
        action: CommentListAction.SetIsLoading,
        isLoading: isLoading
    }
}