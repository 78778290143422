import {GlossaryEditorAction, GlossaryEditorPayload} from "./GlossaryEditorPayload";
import {ReduceStore} from "flux/utils";
import Immutable from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import Glossary from "../../../model/Glossary";

interface IGlossaryEditorState {
    glossary: Glossary,
    glossaryIsExisted: boolean,
}

const GlossaryEditorStateFactory = Immutable.Record<IGlossaryEditorState>({
    glossary: new Glossary(),
    glossaryIsExisted: false,
});

class GlossaryEditorState extends GlossaryEditorStateFactory {
}

export class GlossaryEditorStore extends ReduceStore<GlossaryEditorState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): GlossaryEditorState {
        return new GlossaryEditorState();
    }

    reduce(state: GlossaryEditorState, payload: GlossaryEditorPayload): GlossaryEditorState {
        switch (payload.action) {
            case GlossaryEditorAction.SetGlossary:
                return state.set("glossary", payload.glossary);
            case GlossaryEditorAction.SetIsGlossaryExisted:
                return state.set("glossaryIsExisted", payload.isExisted);
        }
        return state;
    }
}

const glossaryEditorStore = new GlossaryEditorStore();
export default glossaryEditorStore;