import {Pageable} from "../../../model/Pageable";
import {
    listIsLoadingPayload,
    selectionModelDeselect,
    selectionModelSelect,
    setPagePayload,
    setSelectedPayload,
    toggleSelectAll
} from "./CommonListPayload";
import {Model} from "../../../model/IModel";
import {Page} from "../../../model/Page";
import {CommonListStore} from "./CommonListStore";
import {List, Set} from "immutable";
import {IFilter} from "../../../model/Filter";
import {dispatcher} from "../../Dispatcher";
import NullFilter from "../NullFilter";
import {AvailableResourceType} from "../../../model/AvailableResources";
import availableResourcesStore from "../../available-resources/AvailableResourcesStore";
import {Mutex} from "async-mutex";

export class CommonListActions<M extends Model, F extends IFilter<F>> {
    constructor(fetchModels: (filter: F, page?: Pageable) => Promise<Page<M>>,
                deleteModels: (toDelete: Set<M>) => Promise<void>,
                saveModels: (model: List<M>) => Promise<List<M>>,
                resource: AvailableResourceType) {
        this.store = new CommonListStore();
        this._fetchModels = fetchModels;
        this._deleteModels = deleteModels;
        this._saveModels = saveModels;
        this.storeId = this.store.storeId;
        this._resource = resource;
    }

    public readonly store: CommonListStore<M, F>;
    public readonly storeId: string;
    private readonly _fetchModels: (filter: F, page?: Pageable) => Promise<Page<M>>;
    private readonly _deleteModels: (toDelete: Set<M>) => Promise<void>;
    private readonly _saveModels: (model: List<M>) => Promise<List<M>>;
    private readonly _resource: AvailableResourceType;

    private static FetchMutex = new Mutex();

    public async saveAll(models: List<M>) {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, false, true, false);

        if (!available)
            return;

        this.setIsLoading();

        await this._saveModels(models);
        await this.refresh();
    }

    public async delete(model: M) {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, false, false, true);

        if (!available)
            return;

        this.setIsLoading();

        await this._deleteModels(Set.of(model));
        await this.refresh();
    }

    public async deleteSelected() {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, false, false, true);

        if (!available)
            return;

        this.setIsLoading();

        const toDelete: Set<M> = this.selected.toSet();
        await this._deleteModels(toDelete);
        dispatcher.dispatch(setSelectedPayload(this.storeId, Set()));
        await this.refresh();
    }

    public async fetchPageable(filter: F, page?: Pageable) {
        if (this.filterNotChanged(filter) && this.pageNotChanged(page))
            return;

        await this.forceFetch(filter, page);
    }

    public async fetch(filter: F) {
        const state = this.store.getState();
        await this.fetchPageable(filter, state.page.pageable);
    }

    public async setFilter(filter: F) {
        dispatcher.dispatch(setPagePayload(this.storeId, this.state.page, filter, this.state.selectionModel));
    }

    public async setPage(page: number, size: number) {
        const state = this.store.getState();
        await this.fetchPageable(state.filter, state.page.setSize(size).setPageNumber(page).pageable);
    }

    public async select(selection: Set<M>) {
        dispatcher.dispatch(setSelectedPayload(this.storeId, selection));
    }

    public async toggleSelectAllAction() {
        dispatcher.dispatch(toggleSelectAll(this.storeId));
    }

    public async selectionModelSelect(id: number|string) {
        dispatcher.dispatch(selectionModelSelect(id,this.storeId));
    }

    public async selectionModelDeselect(id: number|string) {
        dispatcher.dispatch(selectionModelDeselect(id,this.storeId));
    }

    public async refresh(showLoading: boolean = true) {
        await this.forceFetch(undefined, this.state.page.pageable, showLoading);
    }

    public setIsLoading() {
        dispatcher.dispatch(listIsLoadingPayload(this.storeId, true))
    }

    get state() {
        return this.store.getState();
    }

    get selected() {
        return this.state.selected;
    }

    get selection() {
        return this.state.selectionModel;
    }


    public addListener(callback: () => void) {
        return this.store.addListener(callback);
    }

    public async forceFetch(filter?: F, page?: Pageable, showLoading: boolean = true) {
        const available = availableResourcesStore
            .getState()
            .resources
            .isAvailableResource(this._resource, true, false, false);

        if (!available)
            return;

        const currentFilter = filter ? filter : this.state.filter;
        if (!currentFilter)
            return;

        if (showLoading)
            dispatcher.dispatch(listIsLoadingPayload(this.storeId, true));
        CommonListActions.FetchMutex
            .runExclusive(async () => {
                const updatedFilter = filter ? filter : this.state.filter;
                if (!updatedFilter.equals(currentFilter))
                    return;
                const models = await this._fetchModels(currentFilter, page);
                dispatcher.dispatch(setPagePayload(this.storeId, models, currentFilter, this.state.selectionModel));
            })
            .then(() => dispatcher.dispatch(listIsLoadingPayload(this.storeId, false)));
    }

    private filterNotChanged(filter?: F) {
        if (filter)
            return filter.equals(this.store.getState().filter as F);
        else
            return new NullFilter().equals(this.store.getState().filter);
    }

    private pageNotChanged(page?: Pageable) {
        if (page)
            return page.equals(this.store.getState().page);
        else
            return true;
    }
}

// TODO: remove it. Functions which used it, should be a proper promise
export function promiseWorkaround() {
    return new Promise(resolve => setTimeout(resolve, 1));
}