import {Alert, Snackbar} from "@mui/material";
import React, {useEffect, useState} from "react";
import alertStore from "../flux/alert/AlertStore";
import ResponseAlert, {AlertType, ProjectAlert} from "../model/Alert";
import {styled} from "@mui/material/styles";
import {resetAlertAction} from "../flux/alert/AlertActions";

type AlertViewProperties = {
    type: AlertType
}

const CustomSnackbar = styled(Snackbar)({
    position: 'absolute'
});

export default function AlertView(props: AlertViewProperties) {

    const [alertOpen, setAlertOpen] = useState(false);
    const [alert, setAlert] = useState<ResponseAlert | ProjectAlert | null>(null);

    useEffect(() => {
        const requestListener = alertStore.addListener(() => {
            const state = alertStore.getState();
            if (state.alert && state.alert.type === props.type) {
                setAlert(state.alert);
                setAlertOpen(true);
            }
        });
        return () => requestListener.remove();
    });

    const handleErrorClosed = () => {
        setAlertOpen(false);
        setAlert(null);
        resetAlertAction();
    }

    return (
        <CustomSnackbar open={alertOpen} anchorOrigin={{vertical:'top', horizontal:'center'}}>
            <Alert severity={alert?.severity} onClose={handleErrorClosed}>{alert?.message}</Alert>
        </CustomSnackbar>
    );
}
