import GlossaryList from "../components/glossary/GlossaryList";
import GlossaryFilter from "../flux/glossary/GlossaryFilter";

export default function GlossariesRoute() {
    return (
        <GlossaryList/>
    );
}

export function getGlossariesPath(filter: GlossaryFilter) {
    return GlossariesPath + "?" + filter.toSearchParams();
}

export const GlossariesPath = "/glossaries"
export const GlossariesId = "Glossaries"