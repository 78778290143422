import {User} from "../../model/User";
import {ITenantResponse, toTenantModel} from "./TenantApiModel";

export function toUserModel(response: IUserResponse) {
    return new User({
        id: response.id,
        firstName: response.firstName,
        lastName: response.lastName,
        tenant: toTenantModel(response.tenant)
    });
}

export function toUserRequest(user: User) {
    return {
        userId: user.id,
        tenantId: user.tenant?.id
    } as IUserRequest
}

export interface IUserResponse {
    id: string,
    firstName: string,
    lastName: string,
    tenant: ITenantResponse
}

export interface IUserRequest {
    userId: string
    tenantId: string | null
}