import {Table, TableBody, TableContainer} from "@mui/material";
import React, {RefObject, useEffect, useState} from "react";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import Project from "../../../../model/Project";
import {Segment} from "../../../../model/Segment";
import {Map} from "immutable";
import {styled} from "@mui/material/styles";
import SegmentView from "./SegmentView";
import Translation from "../../../../model/Translation";
import {Page} from "../../../../model/Page";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {getSegmentPath} from "../../../../routes/EditorRoute";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import {SelectionModel} from "../../../../model/SelectionModel";
import {
    selectSegmentAction,
    setLastClickedSegmentAction,
    toggleWithControlsAction
} from "../../../../flux/segment/list/SegmentListActions";

const stringHash = require("string-hash");
const SegmentsTable = styled(Table)({
    tableLayout: "fixed"
});

type SegmentsViewProperties = {
    segments: Page<Segment>,
    filter: SegmentListFilter,
    translationsRefs: Map<number, RefObject<HTMLTableRowElement>>,
    currentSegment: Segment | null
};

export default function SegmentList(props: SegmentsViewProperties) {
    const initialProjectState = projectPageStore.getState();

    const [project, setProject]
        = useState<Project | null>(initialProjectState.project);
    const [selection, setSelection] = useState(new SelectionModel());

    const navigate = useNavigate();

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        const segmentListListener = segmentListStore.addListener(() => {
            setSelection(segmentListStore.getState().selection);
        });

        return () => {
            projectPageListener.remove();
            segmentListListener.remove();
        };
    });

    return (
        <TableContainer>
            <SegmentsTable>
                <TableBody>
                    {props.segments.list.map((segment, index) =>
                        drawSegment(
                            segment,
                            index,
                            props.translationsRefs,
                            props.filter,
                            project,
                            props.currentSegment,
                            selection,
                            navigate))}
                </TableBody>
            </SegmentsTable>
        </TableContainer>
    );
}

// hash used for force recreate component
// workaround for set editor target value
function segmentViewKey(segment: Segment, translation: Translation) {
    return "segment-" + segment.id + "-" + stringHash(translation.target);
}

function handleSegmentClicked(event: React.MouseEvent,
                              segment: Segment,
                              translationsRefs: Map<number, RefObject<HTMLTableRowElement>>,
                              filter: SegmentListFilter,
                              navigate: NavigateFunction) {
    clickWithShiftKey(event, segment.id);

    navigate(getSegmentPath(filter, segment.order), {replace: true});
    const translationRef = translationsRefs.get(segment.id);
    if (translationRef)
        translationRef.current?.scrollIntoView({block: "nearest", inline: "nearest"});
    setLastClickedSegmentAction(segment.id);
}

function clickWithShiftKey(event: React.MouseEvent, segmentId: number) {
    if (!event.shiftKey)
        return;

    const state = segmentListStore.getState();
    if (!state.withControls)
        toggleWithControlsAction();
    const lastSelectedId = state.lastClickedId;
    if (!lastSelectedId) {
        selectSegmentAction(segmentId);
        return;
    }
    const segmentsList = state.page.list;
    let listToSelect;
    if (segmentId > lastSelectedId) {
        listToSelect = segmentsList
            .filter(segment => segment.id >= lastSelectedId && segment.id <= segmentId)
            .toList();
    } else {
        listToSelect = segmentsList
            .filter(segment => segment.id >= segmentId && segment.id <= lastSelectedId)
            .toList();
    }
    listToSelect.forEach(segment => {
        selectSegmentAction(segment.id);
        const last = segmentsList.last();
        if (last && last.id == segment.id)
            setLastClickedSegmentAction(segment.id)
    });
}

function drawSegment(segment: Segment,
                     index: number,
                     translationsRefs: Map<number, RefObject<HTMLTableRowElement>>,
                     filter: SegmentListFilter,
                     project: Project | null,
                     currentSegment: Segment | null,
                     selection: SelectionModel,
                     navigate: NavigateFunction) {
    const target = segment.translation;
    const ref = translationsRefs.get(segment.id);
    const catFile = filter.catFile;
    if (!target.workflowStep || !project || !catFile || !ref)
        return;

    return (
        <SegmentView onClick={(event) => handleSegmentClicked(event, segment, translationsRefs, filter, navigate)}
                     reference={ref}
                     segment={segment}
                     translation={target}
                     index={index}
                     key={segmentViewKey(segment, target)}
                     project={project}
                     targetLanguage={filter.nonNullLanguage}
                     isEditing={currentSegment?.id === segment.id}
                     translationsRefs={translationsRefs}
                     isSelected={selection.isSelected(segment.id)}/>
    );
}