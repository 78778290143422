import {Statistic} from "../../model/Statistic";
import {Language} from "../../model/Language";
import {StatisticMetric} from "../../model/StatisticMetric";
import {IWorkflowStepResponse, toWorkflowStepModel} from "./Workflow";

export function toStatisticModel(response: IStatisticResponse) {
    return new Statistic({
        language: Language.fromSupported(response.languageCode),
        workflow: toWorkflowStepModel(response.workflowStep),
        metric: new StatisticMetric({
            totalWords: response.totalWords,
            translatedWords: response.translatedWords
        })
    });
}

export interface IStatisticResponse {
    languageCode: string,
    workflowStep: IWorkflowStepResponse
    totalWords: number,
    translatedWords: number
}

