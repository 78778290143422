import {Record} from "immutable";

export enum ReportType {
    Project = "ReportType.Project",
    TranslationAssignee = "ReportType.Assignee"
}

export enum ReportState {
    Available = "ReportState.Available",
    Error = "ReportState.Error",
    Generating = "ReportState.Generating",
    Downloading = "ReportState.Downloading"
}

interface IReport {
    id: number,
    state: ReportState,
    variety: ReportType,
    created: Date,
}

const ReportRecord = Record<IReport>({
    id: 0,
    variety: ReportType.Project,
    state: ReportState.Generating,
    created: new Date()
});

export class Report extends ReportRecord {
}
