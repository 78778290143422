import Project from "../../model/Project";
import {List} from "immutable";

import {WorkflowStepModel, WorkflowStepType} from "../../model/WorkflowStepModel";
import {IStatisticResponse, toStatisticModel} from "./StatisticApiModel";
import ProjectListItem from "../../model/ProjectListItem";
import {Language} from "../../model/Language";
import {Build, BuildState} from "../../model/Build";

export function toProjectModel(response: IProjectResponse) {
    return new Project({
        id: response.id,
        name: response.name,
        source: Language.fromSupported(response.source),
        targets: List(response.targets).map(value => Language.fromSupported(value)),
        translationMemories: List(response.translationMemories),
        writableTm: response.writableTm,
        glossaries: List(response.glossaries),
        placeholdersSetId: response.placeholdersSetId,
        refuseSaveSegmentsWithPlaceholderErrors: response.refuseSaveSegmentsWithPlaceholderErrors,
        workflow: List(response.workflow.map(v => toWorkflowModelFromWorkflowStepResponse(v)))
    });
}

export function toProjectListItemModel(response: IProjectListItemResponse) {
    return new ProjectListItem({
        id: response.id,
        name: response.name,
        source: response.source,
        targets: List(response.targets),
        statistic: List(response.statistic.map(s => toStatisticModel(s))),
    });
}

export function toProjectBuildModel(response: IProjectBuildResponse) {
    return new Build({
        id: response.id,
        state: BuildState[response.state as keyof typeof BuildState],
    })
}

function toWorkflowModelFromWorkflowStepResponse(response: IWorkflowStepResponse) {
    return new WorkflowStepModel(
        response.id,
        response.name,
        WorkflowStepType[response.type as keyof typeof WorkflowStepType],
        []);
}

export interface IProjectResponse {
    id: number,
    name: string,
    source: string,
    targets: string[],
    translationMemories: string[],
    writableTm: '',
    glossaries: string[],
    placeholdersSetId: number | null,
    refuseSaveSegmentsWithPlaceholderErrors: boolean,
    workflow: IWorkflowStepResponse[]
}

export interface IProjectListItemResponse {
    id: number,
    name: string,
    source: string,
    targets: string[],
    statistic: IStatisticResponse[],
}

interface IWorkflowStepResponse {
    id: number;
    name: string;
    type: string;
}

export interface IProjectBuildResponse {
    id: number,
    state: string
}
