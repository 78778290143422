import {Tenant} from "../../model/Tenant";
import {emptyAsNull} from "../../globals/ApiGlobals";

export function toTenantModel(response: ITenantResponse | null) {
    if (response == null)
        return new Tenant();

    return new Tenant({
        id: response.id,
        name: response.name,
    });
}

export function toTenantRequest(tenant: Tenant) {
    return {
        id: emptyAsNull(tenant.id),
        name: tenant.name
    } as ITenantRequest
}

export interface ITenantRequest{
    id: string |null,
    name: string,
}

export interface ITenantResponse {
    id: string,
    name: string,
}