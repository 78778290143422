import React, {useEffect, useState} from "react";
import {CircularProgress, IconButton, Stack} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"
import {getGridStringOperators, GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import GlossaryEditor from "./GlossaryEditor";
import PageBreadcrumbs from "../PageBreadcrumbs";
import ListView from "../common/ListView";
import UploadIcon from "@mui/icons-material/Upload";
import {setEditedGlossaryAction} from "../../flux/glossary/editor/GlossaryEditorActions";
import Glossary from "../../model/Glossary";
import {glossaryListActions} from "../../flux/glossary/list/GlossaryListActions";
import DownloadIcon from "@mui/icons-material/Download";
import {hasModel} from "../../globals/Utils";
import {GridRowStringOperatorsDefault} from "../../globals/Constants";
import {List} from "immutable";
import {glossaryBuildActions} from "../../flux/glossary/build/GlossaryBuildActions";
import {getGlossariesPath} from "../../routes/GlossariesRoute";
import GlossaryFilter from "../../flux/glossary/GlossaryFilter";

export default function GlossaryList() {
    const buildState = glossaryBuildActions.state;

    const [openEditor, setOpenEditor] = useState(false);
    const [buildingGlossaries, setBuildingGlossaries]
        = useState(buildState.building);

    useEffect(() => {
        const storeListener = glossaryBuildActions.addListener(() => {
            const state = glossaryBuildActions.state;
            setBuildingGlossaries(state.building);
        });

        return () => storeListener.remove();
    }, []);

    return (
        <Stack sx={{height: '100%'}}>
            <GlossaryEditor open={openEditor} onClose={() => setOpenEditor(false)}/>
            <Stack sx={{height: '100%'}}>
                <PageBreadcrumbs/>
                <Stack direction={"row"}>
                    <IconButton onClick={() => {
                        setEditedGlossaryAction(new Glossary());
                        setOpenEditor(true);
                    }}>
                        <UploadIcon/>
                    </IconButton>
                    <IconButton onClick={() => glossaryListActions.deleteSelected()}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
                <ListView actions={glossaryListActions} columns={columns(setOpenEditor, buildingGlossaries)}
                          navigateProps={{navigator: getGlossariesPath}} initialFilter={new GlossaryFilter()}/>
            </Stack>
        </Stack>
    );
}

function columns(setOpenEditor: React.Dispatch<React.SetStateAction<boolean>>,
                 buildingGlossaries: List<Glossary>): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams<Glossary>) => [
                <GridActionsCellItem label={'Update'} icon={<UploadIcon/>}
                                     onClick={() => {
                                         setOpenEditor(true);
                                         setEditedGlossaryAction(params.row);
                                     }}/>,
                generateDownloadGlossary(params.row, buildingGlossaries),
                <GridActionsCellItem label={'Delete'} icon={<DeleteIcon/>}
                                     onClick={() => glossaryListActions.delete(params.row)}/>
            ]
        }
    ];
}

function generateDownloadGlossary(glossary: Glossary, buildingGlossaries: List<Glossary>) {
    if (hasModel(glossary, buildingGlossaries))
        return <CircularProgress size={20}/>;
    else
        return <GridActionsCellItem label={'Download'} icon={<DownloadIcon/>}
                                    onClick={() => glossaryBuildActions.build(glossary)}/>;
}


