import PlaceholdersSet from "../../../model/PlaceholdersSet";
import Placeholder from "../../../model/Placeholder";
import {Page} from "../../../model/Page";

export enum PlaceholdersSetEditorAction {
    SetPlaceholdersSet = "PlaceholdersSetEditorAction.SetPlaceholdersSet",
    SetPlaceholdersList = "PlaceholdersSetEditorAction.SetPlaceholdersList"
}

interface ISetPlaceholdersSetPayload {
    action: PlaceholdersSetEditorAction.SetPlaceholdersSet,
    placeholdersSet: PlaceholdersSet | null
}

interface ISetPlaceholdersPayload {
    action: PlaceholdersSetEditorAction.SetPlaceholdersList,
    page: Page<Placeholder>
}

export type PlaceholdersSetEditorPayload = ISetPlaceholdersSetPayload | ISetPlaceholdersPayload;

export function setPlaceholdersSet(placeholdersSet: PlaceholdersSet | null): PlaceholdersSetEditorPayload {
    return {
        action: PlaceholdersSetEditorAction.SetPlaceholdersSet,
        placeholdersSet: placeholdersSet
    }
}

export function setPlaceholders(placeholders: Page<Placeholder>): PlaceholdersSetEditorPayload {
    return {
        action: PlaceholdersSetEditorAction.SetPlaceholdersList,
        page: placeholders
    }
}