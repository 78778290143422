import {ReduceStore} from "flux/utils";
import {PlaceholdersSetSelectAction, PlaceholdersSetSelectPayload} from "./PlaceholdersSetSelectPayload";
import Immutable from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";

interface IPlaceholdersSetSelectState {
    open: boolean,
    placeholdersSet: number | null
}

const PlaceholdersSetSelectStateFactory
    = Immutable.Record<IPlaceholdersSetSelectState>({
    open: false,
    placeholdersSet: null
});

class PlaceholdersSetSelectState extends PlaceholdersSetSelectStateFactory {
}

export class PlaceholdersSetSelectStore extends ReduceStore<PlaceholdersSetSelectState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): PlaceholdersSetSelectState {
        return new PlaceholdersSetSelectState();
    }

    reduce(state: PlaceholdersSetSelectState, payload: PlaceholdersSetSelectPayload): PlaceholdersSetSelectState {
        switch (payload.action) {
            case PlaceholdersSetSelectAction.SetOpen:
                return state.set("open", payload.open);
            case PlaceholdersSetSelectAction.SetPlaceholdersSet:
                return state.set("placeholdersSet", payload.placeholdersSet);
        }
        return state;
    }
}

const placeholdersSetSelectStore = new PlaceholdersSetSelectStore();
export default placeholdersSetSelectStore;