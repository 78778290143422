import {List, Record} from "immutable";

export enum AvailableResourceType {
    CatFiles = "AvailableResource.CatFiles",
    Comments = "AvailableResource.Comments",
    Projects = "AvailableResource.Projects",
    AvailableResources = "AvailableResource.AvailableResources",
    Segments = "AvailableResource.Segments",
    SegmentsLock = "AvailableResource.SegmentsLock",
    TranslationMemories = "AvailableResource.TranslationMemories",
    Glossaries = "AvailableResource.Glossaries",
    Placeholders = "AvailableResource.Placeholders",
    Permissions = "AvailableResource.Permissions",
    Pretranslate = "AvailableResource.Pretranslate",
    Report = "AvailableResource.Report",
    Users = "AvailableResource.Users",
    Events = "AvailableResourceType.Events",
    Vendors = "AvailableResourceType.Vendors",
    Admin = "AvailableResourceType.Admin",
    ClearTarget= "AvailableResourceType.ClearTarget",
}

interface IAvailableResources {
    fullAccess: boolean,
    readable: List<AvailableResourceType>,
    writable: List<AvailableResourceType>,
    removable: List<AvailableResourceType>
}

const AvailableResourcesRecord = Record<IAvailableResources>({
    fullAccess: false,
    readable: List.of(),
    writable: List.of(),
    removable: List.of()
});

export class AvailableResources extends AvailableResourcesRecord {

    public add(resources: AvailableResources) {
        return this
            .set("fullAccess", this.fullAccess && resources.fullAccess)
            .set("readable", this.readable.concat(resources.readable))
            .set("writable", this.writable.concat(resources.writable))
            .set("removable", this.removable.concat(resources.removable))
    }


    public isAvailableResources(resources: List<AvailableResourceType>,
                                readAllowed: boolean,
                                writeAllowed: boolean,
                                removeAllowed: boolean) {
        if (this.fullAccess)
            return true;

        let available = true;
        for (let resource of resources)
            available = available && this.isAvailableResource(resource, readAllowed, writeAllowed, removeAllowed);

        return available;
    }

    public isAvailableResource(resource: AvailableResourceType, readAllowed: boolean, writeAllowed: boolean, removeAllowed: boolean) {
        if (this.fullAccess)
            return true;

        let available = true;
        if (readAllowed)
            available = this.readable.contains(resource) && available;
        if (writeAllowed)
            available = this.writable.contains(resource) && available;
        if (removeAllowed)
            available = this.removable.contains(resource) && available;

        return available;
    }
}
