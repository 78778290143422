import {List, Record} from "immutable";
import {PermissionEntityPart, PermissionEntityPartType} from "./PermissionEntityPart";

interface IUserPermission {
    id: number,
    userId: string,
    username: string,
    permissionEntity: List<PermissionEntityPart>,
    readAllowed: boolean
}

const UserPermissionRecord = Record<IUserPermission>({
    id: 0,
    userId: "",
    username: "",
    permissionEntity: List.of(),
    readAllowed: false
});

export class Permission extends UserPermissionRecord {
    public getEntityPartTitle(type: PermissionEntityPartType) {
        const part = this.permissionEntity.find(value =>
            value.type === type);
        if (part)
            return part.title;
        else
            return "";
    }
}
