import {ReduceStore} from "flux/utils";
import {AuthAction, AuthPayload} from "./AuthPayload";
import Immutable from "immutable";
import {User} from "../../model/User";
import {dispatcher, Payload} from "../Dispatcher";

interface IAuthState {
    user: User | null
}

const AuthStateFactory = Immutable.Record<IAuthState>({
    user: null
});

class AuthState extends AuthStateFactory {
}

export class AuthStore extends ReduceStore<AuthState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): AuthState {
        return new AuthState();
    }

    reduce(state: AuthState, payload: AuthPayload): AuthState {
        switch(payload.action) {
            case AuthAction.SetUser:
               return state.set("user", payload.user);
        }
        return state;
    }
}

const authStore = new AuthStore();
export default authStore;