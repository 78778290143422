import React, {useState} from "react";
import {Container, Stack, Tabs} from "@mui/material";
import {OverflowContainer, SimpleTab} from "../../../globals/CommonComponents";
import ConcordanceSearchView from "./concordance-search/ConcordanceSearchView";
import CatInfoView from "./cat-info/CatInfoView";

enum TabName {
    ConcordanceSearch = "ConcordanceSearch",
    CatInfo = "CatInfo"
}

export default function BottomRightFrame() {
    const [currentTab, setCurrentTab]
        = useState<TabName>(TabName.ConcordanceSearch);

    const handleTabChanged = (value: TabName) => {
        setCurrentTab(value);
    }

    function drawTab() {
        switch(currentTab) {
            case TabName.CatInfo:
                return <CatInfoView/>;
            case TabName.ConcordanceSearch:
                return <ConcordanceSearchView/>;
        }
    }

    return (
        <OverflowContainer maxWidth={false}>
            <Stack sx={{height: '100%'}}>
                <Container>
                    <Tabs value={currentTab} onChange={(_event, value) =>
                        handleTabChanged(value)}>
                        <SimpleTab label={"CAT info"} value={TabName.CatInfo}/>
                        <SimpleTab label={"Concordance search"} value={TabName.ConcordanceSearch}/>
                    </Tabs>
                </Container>
                {drawTab()}
            </Stack>
        </OverflowContainer>
    )
}