import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import TopLeftFrame from "../frames/TopLeftFrame";
import {ResizableHorizontalGrid, ResizableVerticalGrid} from "react-resizable-collapsible-grid";
import Box from "@mui/material/Box";
import TopRightFrame from "../frames/TopRightFrame";
import EditorNavigationView from "./EditorNavigationView";
import EditorFiltersView from "./EditorFiltersView";
import BottomRightFrame from "../frames/BottomRightFrame";
import BottomLeftFrame from "../frames/BottomLeftFrame";
import {handleEditorMessageAction} from "../../../flux/segment/editor/SegmentEditorActions";
import languageListStore from "../../../flux/language/list/LanguageListStore";
import SegmentListFilter from "../../../flux/segment/list/SegmentListFilter";
import segmentListStore from "../../../flux/segment/list/SegmentListStore";
import {LanguageList} from "../../../model/LanguageList";

export default function EditorView() {
    const initialSegmentListFilterState = segmentListStore.getState();
    const initialLanguageListState = languageListStore.getState();

    const [supportedLanguages, setSupportedLanguages]
        = useState(initialLanguageListState.languages);
    const [filter, setFilter]
        = useState(initialSegmentListFilterState.filter);

    useEffect(() => {
        window.addEventListener('message', handleEditorMessageAction);

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setFilter(state.filter);
        });

        const languageListListener = languageListStore.addListener(() => {
            const state = languageListStore.getState();
            setSupportedLanguages(state.languages);
        });

        return () => {
            window.removeEventListener('message', handleEditorMessageAction);
            segmentListListener.remove();
            languageListListener.remove();
        };
    }, []);

    return (
        <Box sx={{height: '100%'}}>
            <Stack height={'100%'} spacing={1}>
                <Stack borderBottom={'1px solid #d6d6d6'} padding={1}>
                    <EditorNavigationView targetLanguage={getTargetLanguage(supportedLanguages, filter)}
                                          filter={filter}/>
                    <EditorFiltersView/>
                </Stack>
                <ResizableHorizontalGrid initialWidths={{left: "70%", right: "30%"}} style={{margin: 0}}>
                    <ResizableVerticalGrid initialHeight={"60%"}>
                        <TopLeftFrame filter={filter}/>
                        <BottomLeftFrame/>
                    </ResizableVerticalGrid>
                    <ResizableVerticalGrid initialHeight={"60%"}>
                        <TopRightFrame targetLanguage={getTargetLanguage(supportedLanguages, filter)}/>
                        <BottomRightFrame/>
                    </ResizableVerticalGrid>
                </ResizableHorizontalGrid>
            </Stack>
        </Box>
    );
}

function getTargetLanguage(supportedLanguages: LanguageList, filter: SegmentListFilter)
{
    const foundLanguage = supportedLanguages.findByCode(filter.nonNullLanguage);
    if (!foundLanguage)
        return null;
    return foundLanguage;
}
