import {ReduceStore} from "flux/utils";
import {ReportListAction, ReportListPayload} from "./ReportListPayload";
import {Record} from 'immutable';
import {dispatcher, Payload} from "../../Dispatcher";
import {Page} from "../../../model/Page";
import {ProjectReportFilter, ReportFilters} from "../../../model/ReportFilters";
import {Report} from "../../../model/Report"

interface IReportListState {
    isLoading: boolean,
    isGeneratingNew: boolean,
    filter: ReportFilters,
    page: Page<Report>
}

const ReportDataStateFactory = Record<IReportListState>({
    isLoading: true,
    isGeneratingNew: false,
    filter: new ProjectReportFilter(),
    page: new Page<Report>().setSize(5)
});

class ReportListState extends ReportDataStateFactory {
}

export class ReportListStore extends ReduceStore<ReportListState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ReportListState {
        return new ReportListState();
    }

    reduce(state: ReportListState, payload: ReportListPayload): ReportListState {
        switch (payload.action) {
            case ReportListAction.SetList:
                return state
                    .set("isGeneratingNew", payload.isGeneratingNew)
                    .set("page", payload.page)
            case ReportListAction.SetIsLoading:
                state = state.set("isLoading", payload.isLoading);
                break
            case ReportListAction.SetPage:
                state = state.set("page", payload.page)
                return state;
            case ReportListAction.SetFilter:
                state = state.set("filter", payload.filter);
                return state;
            case ReportListAction.GenerateReport:
                state = state.set("isGeneratingNew", true)
                return state;
        }
        return state;
    }
}

const reportListStore = new ReportListStore();
export default reportListStore;