import {Statistic} from "./Statistic";
import {List, Record} from "immutable";
import {GroupedStatistic} from "./GroupedStatistic";
import {Language} from "./Language";

export enum FileState {
    Available = 'Available',
    SegmentsReading = 'SegmentsReading',
    Deleted = 'Deleted',
    Error = 'Error',
    MT = 'MT',
    Pretranslating = 'Pretranslating',
    Translating = 'Translating'
}

export enum TypeOfCatFile {
    SmartcatXliff = "SmartcatXliff",
    SmartcatXliffNoId = "SmartcatXliffNoId",
    Xliff = "Xliff",
    Html = "Html",
    Md = "Md",
    Docx = "Docx",
    Pptx = "Pptx",
    Xlsx = "Xlsx",
    Po = "Po",
    Txt = "Txt"
}

export enum TagResolver {
    Onecint = "Onecint",
    Smartcat = "Smartcat"
}

interface ICatFile {
    id: number,
    name: string,
    projectId: number,
    type: TypeOfCatFile,
    state: FileState,
    language: Language,
    statistic: List<Statistic>,
    path: string
}

const CatFileRecord = Record<ICatFile>({
    id: 0,
    name: '',
    projectId: 0,
    type: TypeOfCatFile.SmartcatXliff,
    state: FileState.Available,
    language: Language.Empty,
    statistic: List<Statistic>(),
    path: ''
});

export class CatFile extends CatFileRecord {
    get tagResolver() {
        switch (this.type) {
            case TypeOfCatFile.SmartcatXliff:
            case TypeOfCatFile.SmartcatXliffNoId:
                return TagResolver.Smartcat;
            case TypeOfCatFile.Html:
            case TypeOfCatFile.Xliff:
            case TypeOfCatFile.Docx:
            case TypeOfCatFile.Pptx:
            default:
                return TagResolver.Onecint;
        }
    }

    get groupedStatistic() {
        return new GroupedStatistic({
            statistic: this.statistic
        });
    }

    get multiLanguage() {
        return this.type === TypeOfCatFile.SmartcatXliff;
    }
}

export const isPending = (catFile: CatFile): boolean => {
    return catFile.state === FileState.MT
        || catFile.state === FileState.Pretranslating
        || catFile.state === FileState.SegmentsReading
        || catFile.state === FileState.Translating;
}