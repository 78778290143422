import React, {useEffect, useState} from "react";
import {Container, Tab, Tabs} from "@mui/material";
import {useLocation} from "react-router-dom";
import ProjectFilesUploading from "./ProjectFilesUploading";
import projectEditorStore from "../../../flux/project/editor/ProjectEditorStore";
import ProjectSettingsEditor from "./ProjectSettingsEditor";
import {setIsOpenAction} from "../../../flux/project/editor/ProjectEditorActions";

export default function ProjectEditor() {

    const [currentStep, setCurrentStep] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const projectEditorListener = projectEditorStore.addListener(() => {
            const state = projectEditorStore.getState();
            setCurrentStep(state.step);
        });

        return () => projectEditorListener.remove();
    });

    useEffect(() => {
        setIsOpenAction(false);
    }, [location]);

    const drawStep = () => {
        switch (currentStep) {
            case 0:
                return <ProjectFilesUploading/>;
            case 1:
                return <ProjectSettingsEditor/>;
        }
    }

    return (
        <Container maxWidth={false}>
            <Tabs value={currentStep}>
                <Tab label={"1. FILES"}/>
                <Tab label={"2. SETTINGS"}/>
            </Tabs>
            {drawStep()}
        </Container>
    );
}