import SegmentListFilter from "../flux/segment/list/SegmentListFilter";
import EditorView from "../components/editor/header/EditorView";

export default function EditorRoute(){
    return (<EditorView/>);
}

export function getSegmentPath(filter: SegmentListFilter, position: number | null, withFilter: boolean = true) {
    return EditorPath
            .replaceAll(":projectId", String(filter.projectId))
            .replaceAll(":fileId", String(filter.nonNullCatFile.id))
            .replaceAll(":language", filter.nonNullLanguage)
            .replaceAll(":position", position !== null ? String(position + 1) : "null")
        + (withFilter ? "?" + filter.toSearchParams() : "");
}

// TODO: Get rid of (:position = null) in url
export const EditorPath = "/editor/project/:projectId/file/:fileId/language/:language/" +
    "position/:position";
export const EditorId = "SegmentListRoute";