import {BaseEditor, BaseText, Descendant, Path} from "slate";
import {ReactEditor} from "slate-react";
import {HistoryEditor} from "slate-history";

export enum SlateEntityType {
    PlainText = "SlateEntityType.PlainText",
    EscapedText = "SlateEntityType.EscapedText",
    Placeholder = "SlateEntityType.Placeholder",
    Paragraph = "SlateEntityType.Paragraph",

}

export type SlateEscapedText = {
    type: SlateEntityType.EscapedText,
    rawText: string,
    children: BaseText[]
}

export type SlatePlaceholder = {
    type: SlateEntityType.Placeholder,
    id: number,
    content: string,
    rawText:string,
    order: number,
    render: boolean,
    children: BaseText[]
}

export type SlateParagraph = {
    type: SlateEntityType.Paragraph,
    children: (Element | BaseText)[]
}

export type Element = SlatePlaceholder | SlateEscapedText | SlateParagraph;

declare module 'slate' {
    // used for override slate model
    // noinspection JSUnusedGlobalSymbols
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & HistoryEditor,
        Element: Element,
        Text: BaseText
    }
}

export interface INodeEntry {
    node: Descendant | BaseText;
    path: Path;
}
