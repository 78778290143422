import {BaseEditor, Descendant, Editor, Node, Operation, Path, Range, Text,} from "slate";
import {ReactEditor} from "slate-react";
import React from "react";
import {styled} from "@mui/material/styles";
import {History, HistoryEditor} from "slate-history";
import {INodeEntry, SlateEntityType} from "./SlateModels";
import slateSerializer from "./SlateSerializer";
import {Selection} from "slate/dist/interfaces/editor";

const InlineBugFixSpan = styled("span")({
    fontSize: 0
});

export const InlineChromiumBugfix = () => (
    <InlineBugFixSpan contentEditable={false}>
        {String.fromCodePoint(160) /* Non-breaking space */}
    </InlineBugFixSpan>
);

export function editorText(editor: ReactEditor) {
    const serializedText = slateSerializer.serialize(editor.children);
    const trimmedSerialisedText = serializedText.trim();
    if (trimmedSerialisedText === "")
        return serializedText;
    else
        return trimmedSerialisedText;
}

export function editorRawText(editor: ReactEditor) {
    return slateSerializer.serializeRaw(editor.children);
}

export function editorPosition(editor: BaseEditor & ReactEditor & HistoryEditor) {
    const {selection} = editor;
    if (!selection)
        return 0;

    const nodes = collectNodes(editor);

    const [start] = Range.edges(selection);
    let position = start.offset;
    for (const entry of nodes) {
        const descendant = entry.node;
        if (!Text.isText(descendant))
            continue;

        if (Path.compare(entry.path, start.path) < 0)
            position += descendant.text.length;
    }

    return position;
}

export function positionToSelection(position: number | null, editor: Editor & ReactEditor) {
    if (position === null)
        return null;

    const nodes = collectNodes(editor);
    let selection = null
    let pointer = 0;
    for (const entry of nodes) {
        const descendant = entry.node;
        if (!Text.isText(descendant))
            continue;

        const serialized = slateSerializer.serialize([descendant]);
        const length = serialized.length;
        const offset = position - pointer - 1;
        selection = {
            anchor: {path: entry.path, offset: offset},
            focus: {path: entry.path, offset: offset}
        } as Selection
        if (position <= pointer + length)
            return selection;

        pointer += length;
    }

    return selection;
}

// workaround editor.nodes() - gets only first level of nodes
function collectNodes(editor: Editor & ReactEditor, node: Node = editor, path: Path = []): INodeEntry[] {
    let nodes: INodeEntry[] = [];

    if (!Editor.isEditor(node))
        nodes.push({node, path});

    if (Text.isText(node))
        return nodes;

    for (const [child, childPath] of Node.children(editor, path)) {
        const childNodes = collectNodes(editor, child, childPath);
        nodes = nodes.concat(childNodes);
    }

    return nodes;
}

export function addEditorCleanupToHistory(editor: Editor) {
    const operations: Operation[] = [];
    editor.children.forEach((node) => {
        operations.push({
            type: 'remove_node',
            path: [0],
            node
        });
    });
    editor.history.undos.push({
        operations: operations,
        selectionBefore: null
    });
}

export function getUpdatedHistory(history: History | null, children: Descendant[]): History | null {
    if (!history)
        return null;
    const updatedUndos = structuredClone(history.undos);
    const updatedRedos = structuredClone(history.redos);
    const lastUndo = updatedUndos[updatedUndos.length - 1];
    children.forEach((child, i) => {
        lastUndo.operations.push({
            type: "insert_node",
            path: [i],
            node: child
        });
    });
    return {
        undos: updatedUndos,
        redos: updatedRedos
    }
}

export function withInlines(editor: Editor): Editor {
    const {isElementReadOnly, isInline, isSelectable} = editor;

    editor.isInline = (element) => element.type === SlateEntityType.Placeholder
        || element.type === SlateEntityType.EscapedText
        || isInline(element);
    editor.isElementReadOnly = (element) => element.type === SlateEntityType.Placeholder
        || element.type === SlateEntityType.EscapedText
        || isElementReadOnly(element);
    editor.isSelectable = (element) => element.type !== SlateEntityType.Placeholder && isSelectable(element);
    return editor;
}

export function withPreviousState(editor: Editor, history: History | null): Editor {
    if (history)
        editor.history = history;

    return editor;
}

// TODO: segments verification
export function hasServiceTags(text: string) {
    const regex = new RegExp("(<bpt.*?>|<ept.*?>|<ph.*?>)");
    return regex.test(text);
}

export function escapePlaceholderContent(content: string) {
    return content.replaceAll('\n', '\\n');
}