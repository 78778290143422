import {ConcordanceSearchResult} from "../../model/ConcordanceSearch";
import {Page} from "../../model/Page";

export enum ConcordanceSearchPayloadAction {
    SetConcordanceSearchResults = "ConcordanceSearchPayloadAction.SetConcordanceSearchResults"
}

interface ISetConcordanceSearchResultsPayload {
    action: ConcordanceSearchPayloadAction.SetConcordanceSearchResults,
    page: Page<ConcordanceSearchResult>
}

export type ConcordanceSearchPayload = ISetConcordanceSearchResultsPayload;

export function setConcordanceSearchResults(page: Page<ConcordanceSearchResult>)
    : ConcordanceSearchPayload {
    return {
        action: ConcordanceSearchPayloadAction.SetConcordanceSearchResults,
        page: page
    }
}