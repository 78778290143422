import PrivateComponent from "../../../components/PrivateComponent";
import {AvailableResourceType} from "../../../model/AvailableResources";
import ProjectTranslationMemories from "../../../components/project/tabs/linguistic-assets/ProjectTranslationMemories";
import React from "react";
import {ProjectPath} from "../ProjectRoute";
import TranslationMemoryFilter from "../../../flux/translation-memory/TranslationMemoryFilter";

export default function ProjectTranslationMemoriesRoute() {
    return (
        <PrivateComponent resource={AvailableResourceType.TranslationMemories} readAllow={true} fullHeight={true}>
            <ProjectTranslationMemories/>
        </PrivateComponent>
    );
}

export function getProjectTranslationMemoriesPath(filter: TranslationMemoryFilter) {
    return ProjectPath.replaceAll(":id", String(filter.projectId)) + "/" + ProjectTranslationMemoriesPath
        + "?" + filter.toSearchParams();
}

export const ProjectTranslationMemoriesPath = "translation-memories"
export const ProjectTranslationMemoriesId = "ProjectTranslationMemories";