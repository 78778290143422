import PrivateComponent from "../../../components/PrivateComponent";
import {AvailableResourceType} from "../../../model/AvailableResources";
import ProjectGlossaries from "../../../components/project/tabs/linguistic-assets/ProjectGlossaries";
import React from "react";
import GlossaryFilter from "../../../flux/glossary/GlossaryFilter";
import {ProjectPath} from "../ProjectRoute";

export default function ProjectGlossariesRoute() {
    return (
        <PrivateComponent resource={AvailableResourceType.TranslationMemories} readAllow={true} fullHeight={true}>
            <ProjectGlossaries/>
        </PrivateComponent>
    );
}

export function getProjectGlossariesPath(filter: GlossaryFilter) {
    return ProjectPath.replaceAll(":id", String(filter.projectId)) + "/" + ProjectGlossariesPath + "?"
        + filter.toSearchParams();
}

export const ProjectGlossariesPath = "glossaries";
export const ProjectGlossariesId = "ProjectGlossaries";