import {List} from "immutable";
import {CommonListActions} from "../../common/list/CommonListActions";
import {CatFile} from "../../../model/CatFile";
import {deleteFiles, fetchFiles} from "../../../api/FileApi";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {AvailableResourceType} from "../../../model/AvailableResources";
import CatFilesFilter from "../CatFilesFilter";

class CatFileSelectActions extends CommonListActions<CatFile, CatFilesFilter> {

    constructor() {
        super(
            fetchFiles,
            deleteFiles,
            async () => {
                dispatchUnsupportedOperation();
                return List<CatFile>();
            },
            AvailableResourceType.CatFiles);
    }
}

export const catFileSelectActions = new CatFileSelectActions();