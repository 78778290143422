import Immutable from "immutable";

interface IRollbackFilter {
    projectId: number | null
}

const RollbackFilterRecord = Immutable.Record<IRollbackFilter>({
    projectId: null

})

export default class RollbackFilter extends RollbackFilterRecord {
}