import {dispatcher} from "../../Dispatcher";
import {rollbackInProgressPayload, setRollbackFilterPayload} from "./RollbackPayload";
import {rollbackState} from "../../../api/EventApi";
import {eventGroupListActions} from "../EventGroupListActions";
import {RollbackTasksState} from "../../../model/RollbackTasksState";
import rollbackStore from "./RollbackStore";
import RollbackFilter from "./RollbackFilter";
import CatEventGroupFilter from "../CatEventGroupFilter";

export async function checkRollbackInProgressAction(filter?: CatEventGroupFilter): Promise<void> {
    if (!filter)
        return;

    const projectId = filter.projectId;
    if (!projectId)
        return;

    if (!rollbackStore.getState().inProgress && rollbackStore.getState().filter.projectId === projectId)
        return;

    const state = await rollbackState(projectId);
    const inProgress = state !== RollbackTasksState.Complete;

    dispatcher.dispatch(setRollbackFilterPayload(new RollbackFilter({projectId: projectId})));

    const inProgressChanged = inProgress !== rollbackStore.getState().inProgress;
    dispatcher.dispatch(rollbackInProgressPayload(inProgress));

    if (!inProgress && inProgressChanged)
        await eventGroupListActions.forceFetch(eventGroupListActions.store.getState().filter);
}

export async function setRollbackInProgressAction(inProgress: boolean) {
    dispatcher.dispatch(rollbackInProgressPayload(inProgress));
}


