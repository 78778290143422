import {Record} from "immutable";
import {IFilter} from "../../model/Filter";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";

export interface ICatEventOperatorsFilter {
}

export interface ICatEventFilter extends ICatEventOperatorsFilter {
    from: Date | null
    to: Date | null
    projectId: number | null
    userId: string | null
    catFileId: number | null
    eventType: string | null,
    eventObjectType: string | null
    segmentId: number | null
    languageCode: string | null,
    orderBy: string | null
    orderDirection: string | null
}

const CatEventFilterRecord = Record<ICatEventFilter>({
    catFileId: null,
    eventObjectType: null,
    eventType: null,
    userId: null,
    from: null,
    to: null,
    projectId: null,
    segmentId: null,
    languageCode: null,
    orderBy: "eventId",
    orderDirection: "desc",
});

export default class CatEventFilter extends CatEventFilterRecord implements IFilter<CatEventFilter> {
    updateWithGridFilterModel(_model: GridFilterModel): CatEventFilter {
        return this;
    }

    updateWithGridSortingModel(_model: GridSortModel): CatEventFilter {
        return this;
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }
}