import {Report} from "../../../model/Report";
import {Page} from "../../../model/Page";
import {ReportFilters} from "../../../model/ReportFilters";

export enum ReportListAction {
    SetList = "ReportListAction.SetList",
    SetIsLoading = "ReportListAction.SetIsLoading",
    SetVariety = "ReportListAction.SetVariety",
    SetPage = "ReportListAction.SetPage",
    SetFilter = "ReportListAction.SetFilter",
    GenerateReport = "ReportListAction.GenerateReport"
}

interface ISetReportList {
    action: ReportListAction.SetList,
    isGeneratingNew: boolean,
    page: Page<Report>
}

interface ISetReportListIsLoading {
    action: ReportListAction.SetIsLoading,
    isLoading: boolean
}

interface ISetReportListPage {
    action: ReportListAction.SetPage,
    page: Page<Report>
}

interface ISetReportFilter {
    action: ReportListAction.SetFilter,
    filter: ReportFilters
}

interface IGenerateReport {
    action: ReportListAction.GenerateReport
}

export type ReportListPayload = ISetReportList
    | ISetReportListPage
    | ISetReportFilter
    | IGenerateReport
    | ISetReportListIsLoading;

export function setReportList(page: Page<Report>, isGeneratingNew: boolean): ReportListPayload {
    return {
        action: ReportListAction.SetList,
        isGeneratingNew: isGeneratingNew,
        page: page
    }
}

export function setReportListPage(page: Page<Report>): ReportListPayload {
    return {
        action: ReportListAction.SetPage,
        page: page
    }
}

export function setReportListFilter(filter: ReportFilters): ReportListPayload {
    return {
        action: ReportListAction.SetFilter,
        filter: filter
    }
}

export function generateReport(): ReportListPayload {
    return {
        action: ReportListAction.GenerateReport
    }
}

export function setReportListIsLoading(isLoading: boolean): ReportListPayload {
    return {
        action: ReportListAction.SetIsLoading,
        isLoading: isLoading
    }
}