import {Record} from "immutable";

interface IWorkflowStep {
    id: number,
    name: string
    order: number,
}

const WorkflowStepRecord = Record<IWorkflowStep>({
    id: 0,
    name: "",
    order: 0
});

export class WorkflowStep extends WorkflowStepRecord {
    public isEmpty() {
        return this.id === 0;
    }
}

export default WorkflowStep

