import ResponseAlert, {ProjectAlert} from "../../model/Alert";

export enum AlertAction {
    SetAlert = "AlertAction.SetAlert",
    SetWaitingForAlert = "AlertAction.SetWaitingForAlert"
}

interface ISetAlertPayload {
    action: AlertAction.SetAlert,
    alert: ResponseAlert | ProjectAlert | null
}

interface ISetWaitingForAlert {
    action: AlertAction.SetWaitingForAlert,
    waitingForAlert: boolean
}

export type AlertPayload = ISetAlertPayload | ISetWaitingForAlert;

export function setAlert(alert: ResponseAlert | ProjectAlert | null): AlertPayload {
    return {
        action: AlertAction.SetAlert,
        alert: alert
    }
}

export function setWaitingForAlert(waitingForAlert: boolean): AlertPayload {
    return {
        action: AlertAction.SetWaitingForAlert,
        waitingForAlert: waitingForAlert
    }
}
