import {Record} from "immutable";

interface ITMSearchResult extends ISearchResult {
    translationMemory: string,
    mode: string,
    scoring: number,
    diff: string
}

const SearchResultRecord = Record<ITMSearchResult>({
    translationMemory: '',
    source: '',
    target: '',
    mode: '',
    scoring: 0,
    diff: ''
});

export default class TranslationMemorySearchResult extends SearchResultRecord {
}
