import Immutable, {List} from "immutable";
import {Pageable} from "./Pageable";

interface IPage<T> {
    pageable: Pageable
    list: List<T>
}

export interface IBackendPage {
    totalPages: number,
    totalElements: number,
    number: number,
    size: number,
}

const PageRecord = Immutable.Record<IPage<any>>({
    pageable: new Pageable(),
    list: List<any>()
})

export class Page<T> extends PageRecord {
    get list() {
        return this.get("list") as List<T>;
    }

    get totalPages() {
        return this.pageable.get("totalPages");
    }

    get number() {
        return this.pageable.get("number");
    }

    get backendNumber() {
        return this.pageable.get("number") - 1;
    }

    get totalElements() {
        return this.pageable.get("totalElements");
    }

    get size() {
        return this.pageable.get("size");
    }

    // set List using for workaround:  Immutable.Record doesn't support generic
    public setList(list: List<T>) {
        return this.set("list", list);
    }

    public setPageNumber(pageNumber: number) {
        return this.set("pageable", this.pageable.set("number", pageNumber));
    }

    public setSize(size: number) {
        return this.set("pageable", this.pageable.set("size", size));
    }

    public static fromBackendPage<T>(page: IBackendPage): Page<T> {
        return new Page({
            pageable: Pageable.fromBackendPage(page)
        });
    }
}