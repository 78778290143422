import {Checkbox, Icon, InputAdornment, Tooltip} from "@mui/material";

type MatchCaseAdornmentProperties = {
    matchCase: boolean,
    setMatchCase: (matchCase: boolean) => void
}

export default function MatchCaseAdornment(props: MatchCaseAdornmentProperties) {
    return <InputAdornment position={"end"}>
        <Tooltip title={"Case sensitive"}>
            <Checkbox
                icon={<Icon>
                    <img src={"/letter-case.svg"} alt={"letter-case"}/>
                </Icon>}
                checkedIcon={<Icon>
                    <img src={"/letter-case-checked.svg"}
                         alt={"letter-case-checked"}/>
                </Icon>}
                checked={props.matchCase}
                onChange={() => props.setMatchCase(!props.matchCase)}
                onMouseDown={e => e.preventDefault()}/>
        </Tooltip>
    </InputAdornment>
}