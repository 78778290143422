import axios, {AxiosResponse} from "axios";
import {
    buildUrl,
    dispatchError,
    dispatchFetchListError,
    dispatchFetchPageError,
    toParameters
} from "../globals/ApiGlobals";
import {Page} from "../model/Page";
import {List, Set} from "immutable";
import {Permission} from "../model/Permission";
import {IUserPermissionResponse, toModel, toWebRequest} from "./model/PermissionApiModel";
import {Pageable} from "../model/Pageable";
import PermissionsFilter from "../flux/user-permissions/PermissionsFilter";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";

export function savePermissions(permissions: List<Permission>) {
    return axios
        .post(endpoint, toWebRequest(permissions))
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IUserPermissionResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toModel(value));
            return List(list);
        })
        .catch(dispatchFetchListError<Permission>);
}

export function fetchPermissions(filter?: PermissionsFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<PermissionsFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IUserPermissionResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toModel(value));
            return Page
                .fromBackendPage<Permission>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<Permission>);
}

// TODO optimize delete|save on backend for all api classes
export function deletePermissions(permissions: Set<Permission>) {
    return Promise
        .all(permissions.map(value => deletePermission(value.id)))
        .then(() => {
        });
}

export function deletePermission(id: number) {
    const url = buildUrl(endpoint + "/:id", {id: id});
    return axios
        .delete(url)
        .catch(dispatchError);
}

const endpoint = "/api/projects/permissions";