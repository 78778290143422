import {AvailableResources, AvailableResourceType} from "../../model/AvailableResources";
import {List} from "immutable";

export function toModel(response: IAvailableResourcesResponse) {
    return new AvailableResources({
        fullAccess: response.fullAccess,
        readable: stringsToResourceType(response.readable),
        writable: stringsToResourceType(response.writable),
        removable: stringsToResourceType(response.removable),
    });
}

export interface IAvailableResourcesResponse {
    fullAccess: boolean,
    readable: string[],
    writable: string[],
    removable: string[]
}

function stringsToResourceType(resources: string[]) {
    const array = resources
        .map(value => AvailableResourceType[value as keyof typeof AvailableResourceType]);
    return List(array);
}