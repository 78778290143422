import {dispatcher} from "../../Dispatcher";
import {
    selectGlossaryPair,
    selectTMSuggest,
    setGlossaryPairs,
    setSearchResults,
    setSegment,
    setTargetEditor
} from "./SegmentEditorPayload";
import {findPairs} from "../../../api/GlossaryApi";
import {fetchTranslations} from "../../../api/PretranslateApi";
import {fetchSegments, getSegment, processTranslation, saveTranslation} from "../../../api/SegmentApi";
import {updateSegmentTranslationAction} from "../list/SegmentListActions";
import {Segment} from "../../../model/Segment";
import {List, Map} from "immutable";
import {fetchCommentsAction} from "../../comment/CommentListActions";
import {Pageable} from "../../../model/Pageable";
import segmentListStore from "../list/SegmentListStore";
import segmentEditorStore from "./SegmentEditorStore";
import segmentStore from "./SegmentEditorStore";
import {CatFile} from "../../../model/CatFile";
import GlossaryPairSearchResult from "../../../model/GlossaryPairSearchResult";
import {RefObject} from "react";
import Translation from "../../../model/Translation";
import {eventListActions} from "../../event/EventListActions";
import CatEventFilter from "../../event/CatEventFilter";
import {TargetDataMessage, TargetDataPayload} from "../../../api/windows/TargetDataMessage";
import {GetSegmentsPayload} from "../../../api/windows/GetSegmentsMessage";
import {PostSegmentsDataByIdsMessage, SegmentData} from "../../../api/windows/PostSegmentsDataByIdsMessage";
import {History} from "slate-history";
import {addEditorCleanupToHistory, hasServiceTags} from "../../../utils/slate/SlateUtils";
import TranslationMemorySearchResult from "../../../model/TranslationMemorySearchResult";
import slateSerializer from "../../../utils/slate/SlateSerializer";
import {NavigateFunction} from "react-router-dom";
import projectPageStore from "../../project/page/ProjectPageStore";
import {getSegmentPath} from "../../../routes/EditorRoute";
import {Editor} from "slate";
import {setIsSegmentSaving} from "../list/SegmentListPayload";

export enum Direction {
    Up,
    Down
}

const he = require('he');

export async function findPairsAction(text: string,
                                      sourceLanguage: string,
                                      targetLanguage: string,
                                      glossaryNames: List<string>) {
    const pairs = await findPairs(text, sourceLanguage, targetLanguage, glossaryNames);
    dispatcher.dispatch(setGlossaryPairs(pairs));
}

export async function saveTranslationAction(segment: Segment,
                                            languageCode: string,
                                            text: string,
                                            cursorPosition: number | null,
                                            history: History | null) {
    const translation = segment.translation;
    if (!translation)
        return;

    const isLastStep = translation.nextWorkflowStep.isEmpty();
    if (isLastStep && slateSerializer.decodeText(translation.target) === text)
        return;

    const workflowStepId = isLastStep ? translation.previousWorkflowStep.id : translation.workflowStep.id;
    if (!workflowStepId)
        return;

    await commonSaveTranslationAction(
        segment.id,
        languageCode,
        text,
        workflowStepId,
        translation,
        cursorPosition,
        false,
        history);
}

// TODO: Remove "navigate" from actions layer
export async function shiftSegmentAction(translationsRefs: Map<number, RefObject<HTMLTableRowElement>>,
                                         direction: Direction,
                                         navigate: NavigateFunction) {
    const listState = segmentListStore.getState();
    const filter = listState.filter;
    const editorState = segmentEditorStore.getState();
    const page = listState.page;
    const pageList = page.list;
    const pageable = page.pageable;

    const index = pageList.findIndex(value =>
        value.id === editorState.segment?.id);
    if (index === -1)
        return;

    let selected: Segment | undefined = undefined;
    if (direction === Direction.Down)
        selected = pageList.get(index + 1);
    else if (index > 0)
        selected = pageList.get(index - 1);

    if (selected) {
        navigate(getSegmentPath(filter, selected.order), {replace: true});
        const translationRef = translationsRefs.get(selected.id);
        if (translationRef)
            translationRef.current?.scrollIntoView({block: "center", inline: "nearest"});
    } else {
        const fetchedPageSettings = direction === Direction.Down ? pageable.next : pageable.prev;
        if (fetchedPageSettings.number === pageable.number)
            return;
        const nextPage = await fetchSegments(filter, fetchedPageSettings);
        const nextPageList = nextPage.list;
        const firstSegment = nextPageList.get(direction === Direction.Down
            ? 0
            : nextPageList.size - 1);
        if (!firstSegment)
            return;
        navigate(getSegmentPath(filter, firstSegment.order), {replace: true});
    }
}

export async function approveTranslationAction(segment: Segment,
                                               languageCode: string,
                                               text: string | null) {
    const translation = segment.translation;
    if (!translation)
        return false;

    const decodedTarget = slateSerializer.decodeText(translation.target)
    const isLastStep = translation.nextWorkflowStep.isEmpty();
    if (isLastStep && decodedTarget === text)
        return;

    const workflowStepId = isLastStep ? translation.previousWorkflowStep.id : translation.nextWorkflowStep.id;
    if (!workflowStepId)
        return;

    return await commonSaveTranslationAction(
        segment.id,
        languageCode,
        text === null ? decodedTarget : text,
        workflowStepId,
        translation,
        null,
        true,
        null);
}

export async function updateTranslationFromResourceAction(segment: Segment,
                                                          targetLanguage: string,
                                                          searchResult: ISearchResult,
                                                          editor: Editor | null) {
    const targetString = searchResult.target;
    const target = segment.translation;
    if (hasServiceTags(targetString) || !target)
        return;

    if (!editor)
        return;
    addEditorCleanupToHistory(editor);

    const state = segmentStore.getState();
    const updatedTranslation = target.set("target", targetString);
    const updatedSegment = segment.set("translation", updatedTranslation);

    setSegmentAction(updatedSegment, state.catFile, state.languageCode);
    await saveTranslationAction(
        segment,
        targetLanguage,
        targetString,
        null,
        editor.history);
}

export async function processTranslationAction(segment: Segment,
                                               languageCode: string,
                                               text: string,
                                               cursorPosition: number | null,
                                               history: History | null) {
    const segmentId = segment.id;
    const translation = await processTranslation(segmentId, languageCode, text);
    if (translation)
        updateSegmentTranslationAction(segmentId, translation.withCursorPosition(cursorPosition).withHistory(history));
}

export async function fetchTranslationsAction(source: string,
                                              sourceLanguage: string,
                                              targetLanguage: string,
                                              translationMemoryNames: List<string>,
                                              sourceId: string | null,
                                              previousSource: string | null,
                                              nextSource: string | null) {
    const state = segmentEditorStore.getState();
    if (!state.catFile)
        return;
    const page = await fetchTranslations(
        source,
        sourceLanguage,
        targetLanguage,
        translationMemoryNames,
        sourceId,
        previousSource,
        nextSource,
        state.catFile.tagResolver)

    dispatcher.dispatch(setSearchResults(page));
}

export function selectGlossaryPairAction(searchResult: GlossaryPairSearchResult | null) {
    dispatcher.dispatch(selectGlossaryPair(searchResult));
}

export function selectTMSuggestAction(tmSuggest: TranslationMemorySearchResult | null) {
    dispatcher.dispatch(selectTMSuggest(tmSuggest));
}

export async function setSegmentAction(segment: Segment, catFile: CatFile, languageCode: string) {
    const isNotChanged = segment.id == segmentEditorStore.getState().segment?.id;
    dispatcher.dispatch(setSegment(segment, catFile, languageCode));
    if (isNotChanged)
        return;

    fetchCommentsAction(segment.id, languageCode, new Pageable());

    const eventFilter = new CatEventFilter({
        segmentId: segment.id,
        languageCode: languageCode
    });
    eventListActions.fetch(eventFilter);
    selectTMSuggestAction(null);

    const project = projectPageStore.getState().project;
    findPairsAction(segment.source, project.source.id, languageCode, project.glossaries);
    fetchTranslationsAction(
        slateSerializer.decodeText(segment.source),
        project.source.id,
        languageCode,
        project.translationMemories,
        segment.sourceId,
        segment.previousSource,
        segment.nextSource);
}

export function setTargetEditorAction(targetEditor: Editor) {
    dispatcher.dispatch(setTargetEditor(targetEditor));
}

async function commonSaveTranslationAction(segmentId: number,
                                           languageCode: string,
                                           target: string,
                                           stepId: number,
                                           currentTranslation: Translation,
                                           cursorPosition: number | null,
                                           tryToApprove: boolean,
                                           history: History | null) {
    if (slateSerializer.decodeText(currentTranslation.target) === target
        && currentTranslation.workflowStep.id === stepId)
        return false;

    // TODO: remove Workaround
    // Workaround: if try to save from multiple spaces -> transaction error
    if (segmentListStore.getState().longTasks.isSegmentSaving)
        return false;

    dispatcher.dispatch(setIsSegmentSaving(true));
    const translation = await saveTranslation(segmentId, languageCode, target, stepId);

    if (translation) {
        updateSegmentTranslationAction(
            segmentId,
            translation
                .withCursorPosition(cursorPosition)
                .withHistory(history)
                .withTryToApprove(tryToApprove)
                .withMarkupErrors(translation.markupErrors));
        const payload: TargetDataPayload = {};
        payload[segmentId] = {
            text: he.decode(translation.target),
            isConfirmed: translation.nextWorkflowStep.isEmpty()
        }
        postTargetDataMessageAction(payload);
    } else
        updateSegmentTranslationAction(segmentId, currentTranslation.withTryToApprove(tryToApprove));

    dispatcher.dispatch(setIsSegmentSaving(false));
    return translation && translation.workflowStep.id === stepId;
}

export async function handleEditorMessageAction(event: MessageEvent) {
    if (event.data.name !== "getSegments")
        return;

    const getSegmentsPayload: GetSegmentsPayload = event.data.payload;
    const segmentsData: SegmentData[] = [];

    await Promise.all(getSegmentsPayload.segmentIds.map(segmentId =>
        getSegment(Number(segmentId), getSegmentsPayload.languageId).then(segment => {
            const translation = segment.translation;

            segmentsData.push({
                segmentId: segment.id,
                sourceText: segment.source,
                translation: he.decode(translation.target),
                isConfirmed: translation.nextWorkflowStep.isEmpty()
            });
        })
    ));
    postSegmentsDataByIdsMessageAction(segmentsData);
}

function postTargetDataMessageAction(payload: TargetDataPayload) {
    if (!window.opener)
        return;
    const response: TargetDataMessage = {
        name: "targetData",
        payload: payload
    }
    window.opener.postMessage(response, "*");
}

function postSegmentsDataByIdsMessageAction(payload: SegmentData[]) {
    if (!window.opener)
        return;
    const response: PostSegmentsDataByIdsMessage = {
        name: "postSegmentsDataByIds",
        payload: payload
    }
    window.opener.postMessage(response, "*");
}
