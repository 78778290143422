import Immutable from "immutable";
import Placeholder from "./Placeholder";

interface IPlaceholdersSet {
    id: number,
    name: string,
    placeholders: Immutable.List<Placeholder>
}

const Record = Immutable.Record<IPlaceholdersSet>({
    id: 0,
    name: "",
    placeholders: Immutable.List<Placeholder>()
});

export default class PlaceholdersSet extends Record {
}