import Immutable from "immutable";

interface ITenant {
    id: string,
    name: string,
}

const TenantRecord = Immutable.Record<ITenant>({
    name: '',
    id: '',
});

export class Tenant extends TenantRecord {
    get isEmpty() {
        return this.equals(new Tenant())
    }
}