import TranslationMemoryFilter from "../flux/translation-memory/TranslationMemoryFilter";
import {Pageable} from "../model/Pageable";
import {buildUrl, dispatchFetchPageError, toParameters} from "../globals/ApiGlobals";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {IProjectTranslationMemoryResponse, toProjectTranslationMemoryModel} from "./model/TranslationMemoryApiModel";
import {Page} from "../model/Page";
import {ProjectTranslationMemory} from "../model/TranslationMemory";
import axios, {AxiosResponse} from "axios";
import {List} from "immutable";

export function fetchProjectTranslationMemories(filter?: TranslationMemoryFilter, page?: Pageable) {
    const url = buildUrl(endpoint + "/search", {}, toParameters<TranslationMemoryFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IProjectTranslationMemoryResponse[]>>) => {
            const list = response.data._embedded.map(toProjectTranslationMemoryModel);
            return Page.fromBackendPage<ProjectTranslationMemory>(response.data.page).setList(List(list));
        })
        .catch(dispatchFetchPageError<ProjectTranslationMemory>);
}

const endpoint = "/api/projects/translation-memories"