import {Box, Tooltip, Typography} from "@mui/material";
import {Language} from "../model/Language";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import React from "react";

export function handleLanguageOptionRendered(props: React.HTMLAttributes<HTMLLIElement>, option: Language) {
    return <li {...props} key={"target-opt-" + option.name}>
        <Box flexGrow={1}>{option.name}</Box>
        {!option.languageToolSupported &&
            <Tooltip title={<React.Fragment>
                <Typography>Limitations in support for this language:</Typography>
                <Typography>1. Incorrect statistics if this language is selected as the source.</Typography>
                <Typography>2. Glossary matches without considering cases.</Typography>
                <Typography>3. No spell check.</Typography>
            </React.Fragment>} arrow={true}>
                <WarningRoundedIcon color={"warning"}/>
            </Tooltip>}
    </li>;
}