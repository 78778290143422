import {Record} from "immutable";

export enum BuildState {
    Available = 'Available',
    Created = 'Created',
    Pending = 'Pending',
    Error = 'Error'
}

interface IBuild {
    id: number | string,
    state: BuildState
}

const BuildRecord = Record<IBuild>({
    id: 0,
    state: BuildState.Available
})

export class Build extends BuildRecord {
    isAvailable(): boolean {
        return this.state === BuildState.Available;
    }

    isError(): boolean {
        return this.state === BuildState.Error;
    }
}