import PlaceholdersSet from "../../model/PlaceholdersSet";
import Placeholder from "../../model/Placeholder";
import {List} from "immutable";

export function toPlaceholderSetModel(response: IPlaceholdersSetResponse) {
    return new PlaceholdersSet({
        id: response.id,
        name: response.name,
        placeholders: List(response.placeholders.map(placeholderResponse =>
            toPlaceholderModel(placeholderResponse)))
    });
}

export function toPlaceholderModel(response: IPlaceholderResponse) {
    return new Placeholder({
        id: response.id,
        value: response.value
    });
}

export interface IPlaceholdersSetResponse {
    id: number,
    name: string,
    placeholders: IPlaceholderResponse[]
}

export interface IPlaceholderResponse {
    id: number,
    value: string
}
