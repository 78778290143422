import Immutable from "immutable";

interface IPlaceholder {
    id: number,
    value: string
}

const Record = Immutable.Record<IPlaceholder>({
    id: 0,
    value: ""
});

export default class Placeholder extends Record {
}