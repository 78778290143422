import WorkflowStep from "../../../model/WorkflowStep";
import NullFilter from "../../common/NullFilter";
import {CommonListActions, promiseWorkaround} from "../../common/list/CommonListActions";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import projectPageStore from "../../project/page/ProjectPageStore";
import {Page} from "../../../model/Page";
import {List} from "immutable";
import {AvailableResourceType} from "../../../model/AvailableResources";

// TODO make std fetch
async function fetchWorkflow() {
    await promiseWorkaround();
    const list = projectPageStore.getState().project.getWorkflowSteps();
    return new Page<WorkflowStep>()
        .setList(list)
        .setSize(list.size);
}

export const workflowSelectActions = new CommonListActions<WorkflowStep, NullFilter>(
    fetchWorkflow,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<WorkflowStep>();
    },
    AvailableResourceType.Projects
);