import {Record} from "immutable";

import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {IFilter} from "../../model/Filter";

export interface IVendorUsersListFilter {
    vendorId: number,
}

const VendorUsersListFilterRecord = Record<IVendorUsersListFilter>({
    vendorId: 0
})

export default class VendorUsersListFilter extends VendorUsersListFilterRecord implements IFilter<VendorUsersListFilter> {
    updateWithGridFilterModel(): VendorUsersListFilter {
        throw new Error("Unsupported")
    }

    updateWithGridSortingModel(): VendorUsersListFilter {
        throw new Error("Unsupported")
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }
}