import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {Checkbox, Chip, Container, FormControlLabel, Paper, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {SimpleButton} from "../../globals/CommonComponents";
import SelectPlaceholdersSet from "./SelectPlaceholdersSet";
import Project from "../../model/Project";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import placeholdersSetsListStore from "../../flux/placeholders-set/list/PlaceholdersSetListStore";
import {savePlaceholdersSetAction} from "../../flux/project/page/ProjectPageActions";
import {openPlaceholdersMenuAction} from "../../flux/placeholders-set/select/PlaceholdersSetSelectActions";

export default function PlaceholdersSetSelectionMenu() {

    const initialProjectState = projectPageStore.getState();
    const initialPlaceholdersSetListState = placeholdersSetsListStore.getState();

    const [project, setProject]
        = useState<Project | null>(initialProjectState.project);
    const [placeholdersSets, setPlaceholdersSets]
        = useState(initialPlaceholdersSetListState.placeholdersSets);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        const placeholdersSetsListListener = placeholdersSetsListStore
            .addListener(() => {
                const state = placeholdersSetsListStore.getState();
                setPlaceholdersSets(state.placeholdersSets);
            });

        return () => {
            projectPageListener.remove();
            placeholdersSetsListListener.remove();
        }
    });

    const handleSelectPlaceholdersSetClicked = () => {
        if (!project)
            return;
        openPlaceholdersMenuAction(project.placeholdersSetId);
    }

    const handlePlaceholdersSetDeleted = () => {
        if (!project)
            return;
        savePlaceholdersSetAction(project.id, null, project.refuseSaveSegmentsWithPlaceholderErrors);
    }

    const handleRefuseSaveSegmentsWithPlaceholderErrorsChecked = (checked: boolean) => {
        if (!project)
            return;
        savePlaceholdersSetAction(project.id, project.placeholdersSetId, checked);
    }

    const drawCurrentPlaceholdersSet = () => {
        const notFoundText = <Typography>None</Typography>;
        if (!project || !project.placeholdersSetId)
            return notFoundText;
        const foundPlaceholdersSet = placeholdersSets
            .list
            .find(placeholdersSet => placeholdersSet.id === project.placeholdersSetId);
        if (foundPlaceholdersSet)
            return <Chip label={foundPlaceholdersSet.name} variant={"outlined"} size={"small"}
                         onDelete={handlePlaceholdersSetDeleted}/>
        return notFoundText;
    }

    return (
        <Paper>
            <Toolbar>
                <Stack alignItems={"flex-start"}>
                    <Typography variant={"h6"}>Placeholders</Typography>
                    <SimpleButton variant={"contained"} size={"small"} onClick={handleSelectPlaceholdersSetClicked}>
                        Select
                    </SimpleButton>
                    <SelectPlaceholdersSet/>
                </Stack>
            </Toolbar>
            <Container sx={{mt: 1, mb: 1}} maxWidth={false}>
                <Stack direction={"row"} spacing={1}>
                    <strong>Selected: </strong>
                    {drawCurrentPlaceholdersSet()}
                </Stack>
                <FormControlLabel control={<Checkbox checked={project?.refuseSaveSegmentsWithPlaceholderErrors}
                                                     onChange={(_e, checked) =>
                                                         handleRefuseSaveSegmentsWithPlaceholderErrorsChecked(checked)}
                />}
                                  label={"Refuse to save segments with placeholder errors"}/>
            </Container>
        </Paper>
    );
}