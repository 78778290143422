import {fetchManagers} from "../../../api/UserApi";
import {dispatcher} from "../../Dispatcher";
import {setAdministrators, setUsersWithPermissions} from "./UsersListPayload";
import {fetchProjectUsers} from "../../../api/ProjectApi";

export function fetchManagersAction(): void {
    fetchManagers().then(administrators => dispatcher.dispatch(setAdministrators(administrators)));
}

export function fetchProjectUsersAction(projectId: number): void {
    fetchProjectUsers(projectId).then(users => dispatcher.dispatch(setUsersWithPermissions(users)));
}