import {CommonListActions} from "./CommonListActions";
import {Model} from "../../../model/IModel";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {List, Map, Set} from "immutable";
import {Pageable} from "../../../model/Pageable";
import {Page} from "../../../model/Page";
import {IFilter} from "../../../model/Filter";

export class GroupedListActions<
    Group extends Model,
    GroupFilter extends IFilter<GroupFilter>,
    Details extends Model,
    DetailsFilter extends IFilter<DetailsFilter>
> extends CommonListActions<Group, GroupFilter> {

    constructor(
        fetchModels: (filter?: GroupFilter, page?: Pageable) => Promise<Page<Group>>,
        deleteModels: (toDelete: Set<Group>) => Promise<void>,
        saveModels: (model: List<Group>) => Promise<List<Group>>,
        detailsBuilder: () =>
            CommonListActions<Details, DetailsFilter>,
        groupToDetailsFilter: (group: Group) => DetailsFilter,
        resource: AvailableResourceType) {

        super(fetchModels, deleteModels, saveModels, resource);
        this._detailsBuilder = detailsBuilder;
        this._groupToDetailsFilter = groupToDetailsFilter;
    }

    private _details: Map<Group, CommonListActions<Details, DetailsFilter>> = Map();
    private readonly _groupToDetailsFilter: (group: Group) => DetailsFilter
    private readonly _detailsBuilder: () => CommonListActions<Details, DetailsFilter>;

    async fetch(filter: GroupFilter, page?: Pageable): Promise<void> {
        await super.fetchPageable(filter, page);

        const groups: List<Group> = this.store.getState().page.list;
        for (const group of groups) {
            if (this._details.has(group))
                continue

            this._details = this._details.set(group, this._detailsBuilder());
        }
    }

    getDetails(group: Group) {
        let detailsActions = this._details.get(group);
        if (!detailsActions) {
            detailsActions = this._detailsBuilder();
            this._details = this._details.set(group, detailsActions);
        }

        detailsActions.fetch(this._groupToDetailsFilter(group));

        return detailsActions;
    }
}