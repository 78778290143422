import {AssigneeReportFilter, ReportFilters} from "../../model/ReportFilters";
import {Container} from "@mui/material";
import AssigneeReportFilterView from "./AssigneeReportFilterView";

interface IReportFilterProps {
    filter: ReportFilters
}

export default function ReportFilterView(props: IReportFilterProps) {
    if (props.filter instanceof AssigneeReportFilter)
        return <AssigneeReportFilterView/>;

    return <Container/>;
}