import {ReduceStore} from "flux/utils";
import {ProjectListAction, ProjectListPayload} from "./ProjectListPayload";
import {Record} from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import {Page} from "../../../model/Page";

import ProjectListItem from "../../../model/ProjectListItem";

interface IProjectListState {
    page: Page<ProjectListItem>
}

const ProjectListStateFactory = Record<IProjectListState>({
    page: new Page<ProjectListItem>()
});

class ProjectListState extends ProjectListStateFactory {
}

export class ProjectListStore extends ReduceStore<ProjectListState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): ProjectListState {
        return new ProjectListState();
    }

    reduce(state: ProjectListState, payload: ProjectListPayload): ProjectListState {
        switch (payload.action) {
            case ProjectListAction.SetList:
                return state.set("page", payload.page);
            default:
                return state;
        }
    }

}

const projectListStore = new ProjectListStore();
export default projectListStore;
