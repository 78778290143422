import {fetchEvents, rollbackEvent} from "../../api/EventApi";
import {dispatchUnsupportedOperation} from "../../globals/ApiGlobals";
import {List} from "immutable";
import {CommonListActions} from "../common/list/CommonListActions";
import {AvailableResourceType} from "../../model/AvailableResources";
import {CatEvent} from "../../model/CatEvent";
import CatEventFilter from "./CatEventFilter";
import {setRollbackInProgressAction} from "./rollback/RollbackActions";

export class EventListActions extends CommonListActions<CatEvent, CatEventFilter> {
    constructor() {
        super(fetchEvents,
            async () => dispatchUnsupportedOperation(),
            async () => {
                dispatchUnsupportedOperation();
                return List<CatEvent>();
            },
            AvailableResourceType.Events);
    }

    public rollback(event: CatEvent) {
        setRollbackInProgressAction(true);
        rollbackEvent(event);
    }
}

export function buildEventListActions() {
    return new EventListActions();
}

export const eventListActions = buildEventListActions();