import Immutable from "immutable";
import {IFilter} from "../../model/Filter";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";

export interface ICatEventGroupOperatorsFilter {
}

export interface ICatEventGroupFilter extends ICatEventGroupOperatorsFilter {
    from: Date | null
    to: Date | null
    projectId: number
    isRevertible: boolean | null
    orderBy: string | null
    orderDirection: string | null
}

const CatEventGroupFilterRecord = Immutable.Record<ICatEventGroupFilter>({
    from: null,
    to: null,
    projectId: 0,
    isRevertible: true,
    orderBy: "date",
    orderDirection: "desc",
})

export default class CatEventGroupFilter extends CatEventGroupFilterRecord implements IFilter<CatEventGroupFilter> {
    updateWithGridFilterModel(_model: GridFilterModel): CatEventGroupFilter {
        return this;
    }

    updateWithGridSortingModel(_model: GridSortModel): CatEventGroupFilter {
        return this;
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }
}