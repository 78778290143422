import {ReduceStore} from "flux/utils";
import Immutable from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import {Vendor} from "../../../model/Vendor";
import {VendorEditorAction, VendorEditorPayload} from "./VendorEditorPayload";

interface IVendorEditorState {
    vendor: Vendor,    
}

const VendorEditorStateFactory = Immutable.Record<IVendorEditorState>({
    vendor: new Vendor(),
});

class VendorEditorState extends VendorEditorStateFactory {
}

export class VendorEditorStore extends ReduceStore<VendorEditorState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): VendorEditorState {
        return new VendorEditorState();
    }

    reduce(state: VendorEditorState, payload: VendorEditorPayload): VendorEditorState {
        switch (payload.action) {
            case VendorEditorAction.SetVendor:
                return state.set("vendor", payload.vendor);
            default:
                return state;
        }
    }
}

const vendorEditorStore = new VendorEditorStore();
export default vendorEditorStore;