import Immutable from "immutable";

interface IGlossaryPair extends ISearchResult {
    glossary: string,
    comment: string
}

const GlossaryPairSearchResultRecord = Immutable.Record<IGlossaryPair>({
    source: "",
    target: "",
    glossary: "",
    comment: ""
});

export default class GlossaryPairSearchResult extends GlossaryPairSearchResultRecord {
}