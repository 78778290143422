import {Set} from "immutable";
import {Page} from "../../../model/Page";
import {Model} from "../../../model/IModel";
import {GridValidRowModel} from "@mui/x-data-grid";
import {IFilter} from "../../../model/Filter";
import {SelectionModel} from "../../../model/SelectionModel";

export enum CommonListAction {
    SetPage = "CommonListAction.SetPage",
    Select = "CommonListAction.Select",
    SetIsLoading = "CommonListAction.SetIsLoading",
    SelectionModelSelect = "CommonListAction.SelectionModelSelect",
    SelectionModelDeselect = "CommonListAction.SelectionModelDeselect",
    ToggleSelectAll = "CommonListAction.ToggleSelectAll",
    UpdateSelectionModel = "CommonListAction.UpdateSelectionModel"
}

interface ISetPage<M extends Model, F extends IFilter<F>> {
    action: CommonListAction.SetPage,
    page: Page<M>,
    filter: F,
    storeId: string,
    selected: SelectionModel
}

interface ISelect {
    action: CommonListAction.Select,
    selected: Set<GridValidRowModel>,
    storeId: string
}

interface ISetIsLoading {
    action: CommonListAction.SetIsLoading,
    isLoading: boolean,
    storeId: string
}

interface ISelectionModelSelect{
    action: CommonListAction.SelectionModelSelect,
    id: number | string,
    storeId: string
}


interface ISelectionModelDeselect {
    action: CommonListAction.SelectionModelDeselect,
    id: number|string,
    storeId: string
}

interface IToggleSelectAll {
    action: CommonListAction.ToggleSelectAll,
    storeId: string
}

interface IUpdateSelectionModel {
    action: CommonListAction.UpdateSelectionModel,
    storeId: string,
    selectedList: number|string [],
    unselectedList:number|string []
}

export type CommonListPayload = ISetPage<any, any>
    | ISelect
    | ISetIsLoading
    | ISelectionModelSelect
    | ISelectionModelDeselect
    | IToggleSelectAll
    | IUpdateSelectionModel;

export function setPagePayload<M extends Model, F extends IFilter<F>>(storeId: string,
                                                                      page: Page<M>,
                                                                      filter: F,
                                                                      selection : SelectionModel): ISetPage<M, F> {
    return {
        action: CommonListAction.SetPage,
        page: page,
        filter: filter,
        storeId: storeId,
        selected: selection
    }
}

export function setSelectedPayload(storeId: string, selected: Set<GridValidRowModel>): ISelect {
    return {
        action: CommonListAction.Select,
        selected: selected,
        storeId: storeId
    }
}

export function listIsLoadingPayload(storeId: string, isLoading: boolean): ISetIsLoading {
    return {
        action: CommonListAction.SetIsLoading,
        isLoading: isLoading,
        storeId: storeId
    }
}

export function selectionModelSelect(id: number|string,storeId:string): ISelectionModelSelect {
    return {
        action: CommonListAction.SelectionModelSelect,
        id: id,
        storeId: storeId
    }
}
export function selectionModelDeselect(id: number|string,storeId:string): ISelectionModelDeselect {
    return {
        action: CommonListAction.SelectionModelDeselect,
        id: id,
        storeId: storeId

    }
}
export function toggleSelectAll(storeId:string): IToggleSelectAll {
    return {
        action: CommonListAction.ToggleSelectAll,
        storeId: storeId
    }
}