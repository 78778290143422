import {Language} from "../../../model/Language";
import NullFilter from "../../common/NullFilter";
import {CommonListActions, promiseWorkaround} from "../../common/list/CommonListActions";
import projectPageStore from "../../project/page/ProjectPageStore";
import {Page} from "../../../model/Page";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {List} from "immutable";
import {AvailableResourceType} from "../../../model/AvailableResources";

// TODO make std fetch
async function fetchLanguages() {
    await promiseWorkaround();
    const languages = projectPageStore
        .getState()
        .project
        .targets;

    return new Page<Language>()
        .setList(languages)
        .setSize(languages.size);
}

export const projectLanguagesSelectActions = new CommonListActions<Language, NullFilter>(
    fetchLanguages,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<Language>();
    },
    AvailableResourceType.Projects);