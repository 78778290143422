import {User} from "../../../model/User";
import {CommonListActions} from "../../common/list/CommonListActions";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {fetchAllTenantsUsers, saveUsers} from "../../../api/AllTenantsUserApi";
import UsersListFilter from "../UsersListFilter";

export const adminUserListActions = new CommonListActions<User, UsersListFilter>(
    fetchAllTenantsUsers,
    async () => dispatchUnsupportedOperation(),
    saveUsers,
    AvailableResourceType.Admin
);