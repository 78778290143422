import axios, {AxiosResponse} from "axios";
import {
    buildUrl,
    dispatchError,
    dispatchFetchListError,
    dispatchFetchPageError,
    toParameters
} from "../globals/ApiGlobals";
import {List, Set} from "immutable";
import {Pageable} from "../model/Pageable";
import NullFilter from "../flux/common/NullFilter";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {Page} from "../model/Page";
import {IVendorResponse, toVendorModel, toVendorRequest} from "./model/VendorApiModel";
import {Vendor} from "../model/Vendor";
import {IUserResponse, toUserModel} from "./model/UserApiModel";
import {User} from "../model/User";
import VendorUsersListFilter from "../flux/vendor/VendorUsersListFilter";

export function fetchVendors(filter?: NullFilter,
                             page?: Pageable) {
    let url = buildUrl(
        endpoint,
        {},
        toParameters<NullFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IVendorResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toVendorModel(value));
            return Page
                .fromBackendPage<Vendor>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<Vendor>);
}

export function deleteVendors(toDelete: Set<Vendor>) {
    let url = buildUrl(
        endpoint,
        {},
        {ids: toDelete.map(value => value.id).toArray()});

    return axios
        .delete(url)
        .then(value => {})
        .catch(error => dispatchError(error));
}

export function fetchVendor(id: number) {
    let url = buildUrl(
        endpoint + "/:id",
        {id: id},
        {});

    return axios
        .get(url)
        .then((response: AxiosResponse<IVendorResponse>) => {
            const data = response.data;
            return toVendorModel(data);
        })
        .catch(error => {
            dispatchError(error);
            return new Vendor();
        });
}

export function fetchVendorLinguists(filter?: VendorUsersListFilter,
                                     page?: Pageable) {
    return vendorUsers(endpointLinguists, filter, page);
}

export function fetchVendorManagers(filter?: VendorUsersListFilter,
                                    page?: Pageable) {
    return vendorUsers(endpointManagers, filter, page);
}


export function saveVendorLinguists(vendorId: number, users: List<User>) {
    return saveVendorUsers(endpointLinguists, vendorId, users);
}

export function saveVendorManagers(vendorId: number, users: List<User>) {
    return saveVendorUsers(endpointManagers, vendorId, users);
}

export function deleteVendorLinguists(vendorId: number, userIds: List<string>) {
    return deleteVendorUsers(endpointLinguists, vendorId, userIds);
}

export function deleteVendorManagers(vendorId: number, userIds: List<string>) {
    return deleteVendorUsers(endpointManagers, vendorId, userIds);
}

export function saveVendor(vendors: List<Vendor>) {
    return axios
        .post(endpoint, vendors.map(value => toVendorRequest(value)).toJSON())
        .then((response: AxiosResponse<IVendorResponse[]>) => {
            return List(response.data.map(value => toVendorModel(value)));
        })
        .catch(dispatchFetchListError<Vendor>);
}

function saveVendorUsers(path: string, vendorId: number, users: List<User>) {
    let url = buildUrl(
        endpoint + "/:id" + path,
        {id: vendorId},
        {});

    return axios
        .post(url, users.map(u => u.id).toJSON())
        .then(() => List(users))
        .catch(dispatchFetchListError<User>);
}

function deleteVendorUsers(path: string, vendorId: number, userIds: List<string>) {
    let url = buildUrl(
        endpoint + "/:id" + path,
        {id: vendorId},
        {ids: userIds.toArray()});

    return axios
        .delete(url)
        .catch(dispatchError);
}

function vendorUsers(path: string,
                     filter?: VendorUsersListFilter,
                     page?: Pageable) {
    let url = buildUrl(
        endpoint + "/:id" + path,
        {id: filter?.vendorId},
        toParameters<VendorUsersListFilter>(undefined, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IUserResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toUserModel(value));
            return Page
                .fromBackendPage<User>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<User>);
}


const endpoint = "/api/common/vendors";
const endpointManagers = "/managers";
const endpointLinguists = "/linguists";