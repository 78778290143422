import {List, Map, Record} from "immutable";
import {Language} from "./Language";
import WorkflowStep from "./WorkflowStep";
import {Statistic} from "./Statistic";
import {StatisticMetric} from "./StatisticMetric";

interface IGroupedStatistic {
    statistic: List<Statistic>
}

const GroupedStatisticsRecord = Record<IGroupedStatistic>({
    statistic: List()
})

export class GroupedStatistic extends GroupedStatisticsRecord {

    constructor(values: Partial<IGroupedStatistic> | Iterable<[string, unknown]>) {
        super(values);

        let grouped = Map<Language, List<Statistic>>()
        const languages = this.statistic.map(value => value.language).toSet()
        grouped = grouped.set(Language.Empty, this.groupStatistic(this.statistic, Language.Empty));

        languages.forEach((language) => {
            grouped = grouped.set(language, this.groupStatistic(this.statistic, language));
        })

        this._grouped = grouped;
    }

    private readonly _grouped: Map<Language, List<Statistic>>;

    private groupStatistic(statistic: List<Statistic>, language: Language) {
        if (!language.isEmpty())
            statistic = statistic.filter(value => value.language.equals(language));

        let grouped = statistic
            .reduce((reduction, value) => reduction.set(value.workflow, new StatisticMetric()),
                Map<WorkflowStep, StatisticMetric>()
            );

        for (const current of statistic) {
            let metric = current.metric;
            const workflow = current.workflow;
            grouped = grouped.set(workflow, grouped.get(workflow)!.addTotalWords(metric))
            List(grouped.keys())
                .filter(value => value.order <= workflow.order)
                .forEach((value) => {
                    const statistic = grouped.get(value);
                    grouped = grouped.set(value, statistic!.addTranslated(metric));
                });
        }

        return grouped
            .map((value, key) => new Statistic({
                workflow: key,
                metric: value,
                language: language
            }))
            .toList()
            .sort((left, right) => {
                return left.workflow.order - right.workflow.order;
            });
    }

    public translatedByWorkflow(language?: Language) {
        if (!language)
            language = Language.Empty;

        const grouped = this
            ._grouped
            .get(language);

        if (!grouped)
            return List.of<Statistic>();

        return grouped;
    }

    public translatedByFirstWorkflow(language: Language) {
        const grouped = this
            ._grouped
            .get(language);
        if (!grouped)
            return new Statistic();

        const first = grouped.first();

        return first ? first : new Statistic();
    }
}