import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError} from "../globals/ApiGlobals";
import {ILocaleResponse} from "./model/LocaleApiModel";
import {Language} from "../model/Language";
import {List} from "immutable";

export function fetchSupportedLanguages() {
    const url = buildUrl(endpoint);
    return axios
        .get(url)
        .then((response: AxiosResponse<ILocaleResponse[]>) => {
            return List(response.data.map(value =>
                new Language({
                    id: value.code,
                    name: value.name,
                    languageToolSupported: value.languageToolSupported
                })));
        })
        .catch(error => {
            dispatchError(error);
            return List<Language>();
        });
}

const endpoint = "/api/common/locales";