import Immutable from "immutable";

interface IConcordanceSearchResult {
    source: string,
    target: string
}

const ConcordanceSearchResultRecord = Immutable.Record<IConcordanceSearchResult>({
    source: '',
    target: ''
});

export class ConcordanceSearchResult extends ConcordanceSearchResultRecord {
}