import {Record, Set} from "immutable";

enum LongTask {
    SegmentSaving = "LongTask.SegmentSaving",
    UpdateSegments = "LongTask.UpdateSegments",
}

interface ISegmentListLongTasks {
    tasks: Set<LongTask>
}

const SegmentListLongTasksRecord = Record<ISegmentListLongTasks>({
    tasks: Set<LongTask>(),
})

export default class SegmentListLongTasks extends SegmentListLongTasksRecord {
    get isSegmentSaving() {
        return this.get("tasks").has(LongTask.SegmentSaving);
    }

    get isUpdatingSegments() {
        return this.get("tasks").has(LongTask.UpdateSegments);
    }

    get tasks() {
        return this.get("tasks");
    }

    setIsSegmentSaving(isSegmentSaving: boolean) {
        if (isSegmentSaving)
            return this.set("tasks", this.tasks.add(LongTask.SegmentSaving));
        else
            return this.set("tasks", this.tasks.remove(LongTask.SegmentSaving));
    }

    setIsUpdatingSegments(isLocking: boolean) {
        if (isLocking)
            return this.set("tasks", this.tasks.add(LongTask.UpdateSegments));
        else
            return this.set("tasks", this.tasks.remove(LongTask.UpdateSegments));
    }
}