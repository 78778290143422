import {Stack} from "@mui/material";
import React from "react";
import PlaceholdersSetSelectionMenu from "../../../placeholders/PlaceholdersSetSelectionMenu";

export default function PlaceHolders() {
    return (
        <Stack>
            <PlaceholdersSetSelectionMenu/>
        </Stack>
    );
}