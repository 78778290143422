import SegmentsFiltersView from "./SegmentsFiltersView";
import SegmentList from "./SegmentList";
import {SelectChangeEvent, Stack} from "@mui/material";
import React, {RefObject, useEffect, useState} from "react";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import Project from "../../../../model/Project";
import {resetSegmentListEditor, setSegmentListPageAction} from "../../../../flux/segment/list/SegmentListActions";
import {AlertType} from "../../../../model/Alert";
import AlertView from "../../../AlertView";
import {styled} from "@mui/material/styles";
import CustomPagination from "../../../common/CustomPagination";
import {Segment} from "../../../../model/Segment";
import {Page} from "../../../../model/Page";
import {Map} from "immutable";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import SegmentListControls from "./SegmentListControls";

const SegmentListContainer = styled(Stack)({
    height: '100%',
    position: 'relative'
});

type SegmentListEditorProperties = {
    filter: SegmentListFilter
}

export default function SegmentListEditor(props: SegmentListEditorProperties) {
    const initialProjectState = projectPageStore.getState();

    const [project, setProject]
        = useState<Project | null>(initialProjectState.project);
    const [segments, setSegments]
        = useState(new Page<Segment>());
    const [currentSegment, setCurrentSegment]
        = useState<Segment | null>(null);
    const [translationsRefs, setTranslationsRefs]
        = useState<Map<number, RefObject<HTMLTableRowElement>>>(Map<number, RefObject<HTMLTableRowElement>>());

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            const segmentsPage = state.page;
            const segmentsList = segmentsPage.list;
            setSegments(segmentsPage);
            const updateRefs = !segmentsList.every(value => translationsRefs.has(value.id));
            if (updateRefs) {
                let refs = Map<number, RefObject<HTMLTableRowElement>>();
                segmentsList.forEach(value =>
                    refs = refs.set(value.id, React.createRef<HTMLTableRowElement>()));
                setTranslationsRefs(refs);
            }
        });

        const segmentListener = segmentStore.addListener(() => {
            const state = segmentStore.getState();
            setCurrentSegment(state.segment);
        });

        return () => {
            projectPageListener.remove();
            segmentListListener.remove();
            segmentListener.remove();
            resetSegmentListEditor();
        }
    }, []);

    useEffect(() => {
        if (!currentSegment)
            return;
        const translationRef = translationsRefs.get(currentSegment.id);
        if (translationRef)
            translationRef.current?.scrollIntoView({block: "center", inline: "nearest"});
    }, [translationsRefs]);

    return (
        <SegmentListContainer>
            <AlertView type={AlertType.Editor}/>
            <SegmentsFiltersView filter={props.filter}/>
            <SegmentListControls/>
            <SegmentList segments={segments}
                         filter={props.filter}
                         translationsRefs={translationsRefs}
                         currentSegment={currentSegment}/>
            <CustomPagination pageable={segments.pageable}
                              onChange={page => handlePageChanged(page, project, segments)}
                              onRowsPerPageChange={e => handleRowsPerPageChanged(e, segments)}
            />
        </SegmentListContainer>
    );
}

function handlePageChanged(page: number, project: Project | null, segments: Page<Segment>) {
    if (!project || !segments)
        return;

    if (segments.pageable.totalPages < page)
        return;

    setSegmentListPageAction(segments.pageable.set("number", page));
}

function handleRowsPerPageChanged(event: SelectChangeEvent<number>, segments: Page<Segment>) {
    setSegmentListPageAction(segments.pageable.set("size", Number(event.target.value)));
}