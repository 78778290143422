import {FormControl, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, Stack} from "@mui/material";
import {RowsPerPageOptionsDefault} from "../../globals/Constants";
import React from "react";
import {Pageable} from "../../model/Pageable";

type CustomPaginationProps = {
    pageable: Pageable,
    onChange: (page: number) => void,
    onRowsPerPageChange: (event: SelectChangeEvent<number>) => void
};

export default function CustomPagination(props: CustomPaginationProps) {
    return (
        <Stack direction={"row"} sx={{mt: 1, mb: 1}} justifyContent={"center"}>
            <Pagination count={props.pageable.totalPages}
                        page={props.pageable.number}
                        onChange={(_e, page) => props.onChange(page)}/>
            <FormControl sx={{minWidth: 120}} size={"small"}>
                <InputLabel>Rows per page</InputLabel>
                <Select label={"Rows per page"} value={props.pageable.size}
                        onChange={props.onRowsPerPageChange} variant={"outlined"}>
                    {RowsPerPageOptionsDefault.map(option =>
                        <MenuItem value={option} key={"rows-per-page-" + option}>{option}</MenuItem>)}
                </Select>
            </FormControl>
        </Stack>
    );
}