import {Record} from "immutable";
import Translation from "./Translation";

interface SegmentType {
    id: number,
    catFileId: number,
    source: string,
    previousSource: string | null,
    nextSource: string | null,
    sourceId: string,
    order: number,
    translation: Translation,
    commentsCount: number,
    note: string
}

const SegmentRecord = Record<SegmentType>({
    id: 0,
    catFileId: 0,
    source: '',
    previousSource: null,
    nextSource: null,
    sourceId: '',
    order: 0,
    translation: new Translation(),
    commentsCount: 0,
    note: ''
});

export class Segment extends SegmentRecord {
    incrementCommentsCount() {
        return this.set("commentsCount", this.commentsCount + 1);
    }

    decrementCommentsCount() {
        return this.set("commentsCount", this.commentsCount - 1);
    }
}

