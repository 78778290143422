import axios, {AxiosError, AxiosResponse} from "axios";
import {dispatchError} from "../globals/ApiGlobals";
import {IAvailableResourcesResponse, toModel} from "./model/AvailableResourcesModel";
import {AvailableResources} from "../model/AvailableResources";
import {IResponseData} from "../globals/ApiModels";

export async function fetchAvailableResources() {
    try {
        const common = await axios.get(endpointCommon) as AxiosResponse<IAvailableResourcesResponse>;
        const projects = await axios.get(endpointProjects) as AxiosResponse<IAvailableResourcesResponse>;
        const reports = await axios.get(endpointReports) as AxiosResponse<IAvailableResourcesResponse>;
        const glossary = await axios.get(endpointGlossary) as AxiosResponse<IAvailableResourcesResponse>;
        const pretranslate = await axios.get(endpointPretranslate) as AxiosResponse<IAvailableResourcesResponse>;
        return toModel(common.data)
            .add(toModel(projects.data))
            .add(toModel(reports.data))
            .add(toModel(pretranslate.data))
            .add(toModel(glossary.data));

    } catch (reason) {
        dispatchError(reason as AxiosError<IResponseData>);
        return new AvailableResources();
    }
}

const endpointProjects = "/api/projects/available-resources";
const endpointReports = "/api/reports/available-resources";
const endpointCommon = "/api/common/available-resources";
const endpointGlossary = "/api/glossaries/available-resources";
const endpointPretranslate = "/api/pretranslate/available-resources";