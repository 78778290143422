import {ReactNode, useEffect, useState} from "react";
import {AvailableResourceType} from "../model/AvailableResources";
import availableResourcesStore from "../flux/available-resources/AvailableResourcesStore";
import {List} from "immutable";

interface IPrivateComponentProps {
    resource?: AvailableResourceType,
    resources?: List<AvailableResourceType>,
    children: ReactNode,
    readAllow?: boolean,
    writeAllow?: boolean,
    deleteAllow?: boolean,
    fullHeight?: boolean
}

export default function PrivateComponent({
                                             resource,
                                             resources,
                                             children,
                                             readAllow = false,
                                             writeAllow = false,
                                             deleteAllow = false
                                         }: IPrivateComponentProps) {
    const state = availableResourcesStore.getState();
    const [availableResources, setAvailableResources] = useState(state.resources);

    useEffect(() => {
        const listener = availableResourcesStore.addListener(() => {
            const state = availableResourcesStore.getState();
            setAvailableResources(state.resources);
        });
        return () => listener.remove();
    }, []);

    let toCheck = List.of<AvailableResourceType>();
    if (resource)
        toCheck = toCheck.push(resource);

    if (resources)
        toCheck = toCheck.concat(resources);

    const available = availableResources.isAvailableResources(toCheck, readAllow, writeAllow, deleteAllow);
    if (!available)
        return null;

    return children;
}