import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import {useState} from "react";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import Project from "../../../../model/Project";
import styled from "@emotion/styled";
import {List} from "immutable";
import {GridFilterInputValueProps, GridFilterItem} from "@mui/x-data-grid";

const CustomFormControl = styled(FormControl)({
    margin: 1,
    width: '100%'
});

const CustomBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5
});

const CustomChip = styled(Chip)({
    margin: 1
})

export default function SelectCatFileStatus(props: GridFilterInputValueProps) {
    const filterValue = props.item.value ? props.item.value as List<number> : undefined;
    const initialProjectState = projectPageStore.getState();

    const [project] = useState<Project>(initialProjectState.project);

    return <Box>
        <CustomFormControl>
            <InputLabel id={"select-cat-file-status-label"}>Value</InputLabel>
            <Select labelId={"select-cat-file-status-label"} multiple
                    value={filterValue ? filterValue.toArray() : []}
                    onChange={e => handleSelectChanged(e, props.item, props.applyValue)}
                    input={<OutlinedInput label={"Value"}/>}
                    renderValue={selected => drawFilterElement(project, selected)}>
                {project.getWorkflowSteps().map(step => <MenuItem value={step.id}
                                                                  key={"select-cat-file-status-" + step.id}>
                    <Checkbox checked={filterValue ? filterValue.includes(step.id) : false}/>
                    <ListItemText primary={step.name}/>
                </MenuItem>)}
            </Select>
        </CustomFormControl>
    </Box>
}

function handleSelectChanged(e: SelectChangeEvent<number[]>,
                             item: GridFilterItem,
                             applyValue: (value: GridFilterItem) => void) {
    const selectValue = e.target.value;
    const updatedValue = List(typeof (selectValue) === 'string'
        ? selectValue.split(',').map(value => Number(value))
        : selectValue);
    applyValue({...item, value: updatedValue.isEmpty() ? undefined : updatedValue});
}

function drawFilterElement(project: Project, selected: number[]) {
    return <CustomBox>
        {selected.map(value => <CustomChip
            label={project.getWorkflowStepById(value)?.name}
            variant={"outlined"}
            key={"selected-cat-file-status-" + value} size={"small"}/>)}
    </CustomBox>;
}