import {Model} from "../../model/IModel";
import {MenuItem, TextField} from "@mui/material";
import React from "react";
import {IFilter} from "../../model/Filter";
import {CommonListActions} from "../../flux/common/list/CommonListActions";

interface IProps<M extends Model, F extends IFilter<F>> {
    value?:M,
    helperText?:string,
    error?: boolean,
    label:string,
    actions: CommonListActions<M, F>,
    onSelect: (selected: M) => void
    getPresentation: (model: M) => string
}

export default function TextFieldSelect<M extends Model, F extends IFilter<F>>(props: IProps<M, F>) {
    return (
        <TextField
            id={"select-" + props.label}
            select
            label={props.label}
            variant="standard"
            fullWidth={true}
            helperText={props.helperText}
            error={props.error}
            value={props.value?.id}
            onChange={event => {
                const value = findById(props, event.target.value)
                if (value)
                    props.onSelect(value);
            }}
        >
            {drawOptions(props)}
        </TextField>
    )
}

function drawOptions<M extends Model, F extends IFilter<F>>(props: IProps<M, F>) {
    const state = props.actions.store.getState();
    const filter = state.filter;
    // TODO implement as infinite
    const pageable = state.page.pageable.set("size", 100);
    props.actions.fetchPageable(filter, pageable);

    return props
        .actions
        .store
        .getState()
        .page
        .list
        .map(value =>
            <MenuItem key={value.id} value={value.id}>
                {props.getPresentation(value)}
            </MenuItem>
        );
}

function findById<M extends Model, F extends IFilter<F>>(props: IProps<M, F>, id: string) {
    return props
        .actions
        .store
        .getState()
        .page
        .list
        .filter(value => (value.id as String) === id)
        .map(value => value as M)
        .first();
}

