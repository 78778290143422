import {List, Record, Set} from "immutable";
import {Language} from "./Language";
import {BuildState} from "./Build";

interface TranslationMemoryType {
    id: string,
    name: string,
    sourceLanguage: Language | null,
    targetLanguages: List<Language>
}

interface ProjectTranslationMemoryType extends TranslationMemoryType {
    projectId: number,
    state: BuildState | null,
    writtenSegments: number | null,
    totalSegments: number | null,
    errors: string[] | null
}

interface TranslationMemoryGroupType {
    id: number,
    translationMemories: Set<TranslationMemoryType>;
}

const TranslationMemoryRecord = Record<TranslationMemoryType>({
    id: "",
    name: "",
    sourceLanguage: null,
    targetLanguages: List<Language>()
});

const ProjectTranslationMemoryRecord = Record<ProjectTranslationMemoryType>({
    id: "",
    name: "",
    sourceLanguage: null,
    targetLanguages: List<Language>(),
    projectId: 0,
    state: null,
    writtenSegments: null,
    totalSegments: null,
    errors: null
});

const TranslationMemoryGroupRecord = Record<TranslationMemoryGroupType>({
    id: 0,
    translationMemories: Set()
})

export class TranslationMemoryGroup extends TranslationMemoryGroupRecord {
    constructor(translationMemories: Set<TranslationMemory>) {
        super({
            id: translationMemories.hashCode(),
            translationMemories: translationMemories
        });
    }
}

export default class TranslationMemory extends TranslationMemoryRecord {
}

export class ProjectTranslationMemory extends ProjectTranslationMemoryRecord {
}