import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from "@mui/material";
import React, {useEffect, useState} from "react";
import placeholdersSetSelectStore from "../../flux/placeholders-set/select/PlaceholdersSetSelectStore";
import placeholdersSetsListStore from "../../flux/placeholders-set/list/PlaceholdersSetListStore";
import Typography from "@mui/material/Typography";
import PlaceholdersSet from "../../model/PlaceholdersSet";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import {saveAndClosePlaceholdersMenuAction} from "../../flux/placeholders-set/select/PlaceholdersSetSelectActions";
import Project from "../../model/Project";

export default function SelectPlaceholdersSet() {
    const initialProjectState = projectPageStore.getState();
    const initialPlaceholdersSetsListState = placeholdersSetsListStore.getState();

    const [open, setOpen] = useState(false);
    const [project, setProject] = useState(initialProjectState.project);
    const [placeholdersSets, setPlaceholdersSets]
        = useState(initialPlaceholdersSetsListState.placeholdersSets);
    const [selectedPlaceholdersSet, setSelectedPlaceholdersSet]
        = useState<number | null>(null);

    useEffect(() => {
        const placeholdersSetSelectListener = placeholdersSetSelectStore
            .addListener(() => {
                const state = placeholdersSetSelectStore.getState();
                setOpen(state.open);
                setSelectedPlaceholdersSet(state.placeholdersSet);
            });

        const placeholdersSetsListListener = placeholdersSetsListStore
            .addListener(() => {
                const state = placeholdersSetsListStore.getState();
                setPlaceholdersSets(state.placeholdersSets);
            });

        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        return () => {
            placeholdersSetSelectListener.remove();
            placeholdersSetsListListener.remove();
            projectPageListener.remove();
        }
    });

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Placeholders</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    {placeholdersSets.list.map(placeholdersSet =>
                        <Card key={"placeholders-set-" + placeholdersSet.id}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                <CardContent>
                                    <Typography>{placeholdersSet.name}</Typography>
                                </CardContent>
                                <CardActions>
                                    {drawSelectButton(
                                        placeholdersSet,
                                        selectedPlaceholdersSet,
                                        setSelectedPlaceholdersSet)}
                                </CardActions>
                            </Stack>
                        </Card>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={() => handleSaveClicked(project, selectedPlaceholdersSet)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function handleSelectClicked(placeholdersSet: PlaceholdersSet,
                             setSelectedPlaceholdersSet: (value: number | null) => void) {
    setSelectedPlaceholdersSet(placeholdersSet.id);
}

function handleUnselectClicked(setSelectedPlaceholdersSet: (value: number | null) => void) {
    setSelectedPlaceholdersSet(null);
}

function handleSaveClicked(project: Project, selectedPlaceholdersSet: number | null) {
    if (!project)
        return;
    saveAndClosePlaceholdersMenuAction(
        project.id,
        selectedPlaceholdersSet,
        project.refuseSaveSegmentsWithPlaceholderErrors);
}

function drawSelectButton(placeholdersSet: PlaceholdersSet,
                          selectedPlaceholdersSet: number | null,
                          setSelectedPlaceholdersSet: (value: number | null) => void) {
    const isSelected = placeholdersSet.id === selectedPlaceholdersSet
    if (!isSelected) {
        return <Button size={"small"}
                       onClick={() => handleSelectClicked(placeholdersSet, setSelectedPlaceholdersSet)}
                       variant={"contained"}>
            Select
        </Button>
    }
    return <Button size={"small"} onClick={() => handleUnselectClicked(setSelectedPlaceholdersSet)}>
        Cancel selection
    </Button>
}