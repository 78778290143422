import {Record} from "immutable";
import {CatEventType} from "./CatEventType";
import {CatEventObjectType} from "./CatEventObjectType";

interface ICatEventGroup {
    id: string,
    date: Date,
    userId: string,
    username: string,
    eventType: CatEventType,
    eventObjectType: CatEventObjectType,
    count: number,
    projectId: number,
    catFileId: number,
    catFileName: string,
    revertible: boolean
}

const CatEventGroupRecord = Record<ICatEventGroup>({
    id: "unused",
    catFileId: 0,
    catFileName: "",
    count: 0,
    date: new Date(),
    eventType: CatEventType.Undefined,
    eventObjectType: CatEventObjectType.Undefined,
    projectId: 0,
    userId: "",
    username: "",
    revertible: false
});

export class CatEventGroup extends CatEventGroupRecord {
}
