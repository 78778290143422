import VendorsList from "../../components/vendor/VendorsList";


export default function VendorsListRoute() {
    return (<VendorsList/>);
}

export function getVendorsListPath() {
    return VendorsListPath;
}

export const VendorsListPath = "/vendors";