import {Button, CircularProgress, Container, Grid, Stack, Tooltip} from "@mui/material";
import PageBreadcrumbs from "../PageBreadcrumbs";
import React, {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate, useSearchParams} from "react-router-dom";
import {NewProjectPath} from "../../routes/project/NewProjectRoute";
import projectListStore from "../../flux/project/list/ProjectListStore";
import {Page} from "../../model/Page";
import {projectListActions} from "../../flux/project/list/ProjectListActions";
import PrivateComponent from "../PrivateComponent";
import {AvailableResourceType} from "../../model/AvailableResources";
import ProjectListItem from "../../model/ProjectListItem";
import ListView from "../common/ListView";
import {getCatFilesPath} from "../../routes/project/tabs/CatFilesRoute";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import {getGridStringOperators, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {List} from "immutable";
import ProjectListFilter from "../../flux/project/list/ProjectListFilter";
import {getProjectsListPath} from "../../routes/project/ProjectsRoute";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import ProjectRowActions from "./ProjectRowActions";
import {GridRowAnyOfOperator, GridRowStringOperatorsDefault} from "../../globals/Constants";
import {CommonLink} from "../../globals/CommonComponents";

export default function ProjectList() {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [projects, setProjects] = useState(new Page<ProjectListItem>());
    const [filter, setFilter]
        = useState<ProjectListFilter | null>(null);

    useEffect(() => {
        const projectListListener = projectListStore.addListener(() => {
            const state = projectListStore.getState();
            setProjects(state.page);
        });
        return () => projectListListener.remove();
    }, [])

    useEffect(() => {
        const filter = ProjectListFilter.fromSearchParams(
            searchParams);
        setFilter(filter);
        projectListActions.setFilter(filter).then(() => projectListActions.fetch(filter));
    }, [projects]);

    const handleNewProjectClicked = () => {
        navigate(NewProjectPath);
    }

    return (
        <Container maxWidth={false} sx={{height: '100%'}}>
            <Stack spacing={2} sx={{height: '100%'}}>
                <Grid container justifyContent={"space-between"} alignItems={"start"}>
                    <PageBreadcrumbs/>
                    <PrivateComponent resource={AvailableResourceType.Projects} writeAllow={true}>
                        <Button variant="contained" startIcon={<AddIcon/>} onClick={handleNewProjectClicked}>
                            New Project
                        </Button>
                    </PrivateComponent>
                </Grid>
                {filter && <ListView actions={projectListActions}
                                     columns={getColumns(projects)}
                                     getRowId={(row: ProjectListItem) => row.id.toString() + row.source}
                                     navigateProps={{navigator: getProjectsListPath}}
                                     selection={false}
                                     initialFilter={filter}/>
                }
            </Stack>
        </Container>
    );
}

function getColumns(projects: Page<ProjectListItem> | null): GridColDef<ProjectListItem>[] {
    return [
        {
            field: 'project',
            headerName: 'Project',
            type: 'string',
            flex: 7,
            renderCell: (params: GridRenderCellParams<any, ProjectListItem>) => {
                const currentProject: ProjectListItem = params.row;
                const projectName = currentProject.name;
                return (
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <Tooltip title={projectName}>
                            <CommonLink
                                to={getCatFilesPath(new CatFilesFilter({projectId: currentProject.id}))}>
                                {projectName}
                            </CommonLink>
                        </Tooltip>
                    </Box>
                );
            },
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'status',
            sortable: false,
            headerName: 'Progress',
            type: 'string',
            renderCell: (params: GridRenderCellParams<ProjectListItem>) => {
                return (
                    <Stack direction={"column"} justifyContent={"space-between"} display={"flex"}>
                        {statisticPresentation(params.row)}
                    </Stack>
                )
            },
            flex: 4,
            filterable: false
        },
        {
            field: 'sourceLanguage',
            sortable: false,
            headerName: 'Source',
            type: 'string',
            flex: 2,
            valueGetter: (params: GridValueGetterParams<ProjectListItem>) => {
                return params.row.source
            },
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'targetLanguages',
            sortable: false,
            headerName: 'Target',
            type: 'string',
            flex: 6,
            renderCell: (params: GridRenderCellParams<ProjectListItem>) => {
                return (
                    <Box sx={{
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        overflow: 'visible'
                    }}>
                        {params.row.targets.join(",")}
                    </Box>
                );
            },
            filterOperators: getGridStringOperators()
                .filter(operator => GridRowAnyOfOperator.includes(operator.value))
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex: 1,
            renderCell: (params: GridRenderCellParams<ProjectListItem>) => {
                if (!projects)
                    return;
                return (
                    <ProjectRowActions renderCellParams={params}/>
                );
            }
        }
    ]
}

function statisticPresentation(project: ProjectListItem) {

    if (project.groupedStatistic.statistic.isEmpty())
        return List.of(<CircularProgress value={0} variant={"indeterminate"} size={24}
                                         key={"project-list-item-statistic-" + project.id + "-progress"}/>)
    else
        return project
            .groupedStatistic
            .translatedByWorkflow()
            .map(value => {
                return (
                    <Stack direction={"row"}
                           key={"project-list-item-statistic-" + project.id + "-workflow-" + value.presentationWithWorkflow}>
                        <CircularProgress value={value.percent} variant={"determinate"} size={14}/>
                        <Typography variant={"caption"} fontWeight={"bold"} marginLeft={1}>
                            {value.presentationWithWorkflow}
                        </Typography>
                    </Stack>
                )
            });
}