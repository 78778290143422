import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, Stack, TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import glossaryEditorStore from "../../flux/glossary/editor/GlossaryEditorStore";
import useFileUpload from "react-use-file-upload";
import {Description} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import {setEditedGlossaryAction, uploadGlossaryAction} from "../../flux/glossary/editor/GlossaryEditorActions";

interface IProps {
    open: boolean,
    onClose: () => void
}

export default function GlossaryEditor(props: IProps) {
    const state = glossaryEditorStore.getState()
    const [glossary, setGlossary] = useState(state.glossary);
    const [glossaryNameExists, setGlossaryNameExists] = useState(false);
    const openGlossaryFileElement = useRef<HTMLInputElement | null>(null);

    const {files, fileNames, clearAllFiles, handleDragDropEvent, setFiles} = useFileUpload();

    useEffect(() => {
        const glossaryEditorListener = glossaryEditorStore.addListener(() => {
            const state = glossaryEditorStore.getState();
            setGlossary(state.glossary);
            setGlossaryNameExists(state.glossaryIsExisted);
        });

        return () => glossaryEditorListener.remove();
    });

    useEffect(() => {
        if (fileNames.length > 0 && glossary.name === '') {
            const extensionIndex = fileNames[0].lastIndexOf(".");
            const _glossaryName = fileNames[0].slice(0, extensionIndex);
            setEditedGlossaryAction(glossary.set("name", _glossaryName));
        }
    }, [fileNames]);

    const handleDrop = (e: any) => {
        handleDragDropEvent(e);
        setFiles(e);
    }

    const handleOpenGlossaryClicked = () => {
        const current = openGlossaryFileElement.current;
        if (!current)
            return;
        current.click();
    }

    const handleClose = (): void => {
        clearAllFiles();
        props.onClose();
    }

    const handleSubmit = (): void => {
        uploadGlossaryAction(glossary.name, files[0]);
        handleClose();
    }

    const renderDropZoneText = (): JSX.Element => {
        if (fileNames.length > 0) {
            return (
                <>
                    <Description/>
                    <Typography>{fileNames[0]}</Typography>
                </>);
        } else {
            return (
                <>
                    <Typography>Drop *.xlsx file here</Typography>
                </>);
        }
    }

    return (
        <Dialog open={props.open} fullWidth={true}>
            <DialogTitle>Upload Glossary</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <TextField color={glossaryNameExists ? 'warning' : 'primary'}
                               helperText={glossaryNameExists ? "Glossary will be updated" : ""}
                               label={"Glossary name"} variant={"standard"}
                               onChange={(e) => {
                                   const name = e.target.value;
                                   setEditedGlossaryAction(glossary.set("name", name));
                               }}
                               value={glossary.name}/>
                    <Stack onDragEnter={(e: any) => handleDragDropEvent(e)}
                           onDragOver={(e: any) => handleDragDropEvent(e)} onDrop={handleDrop}
                           border={'1px dashed black'} height={'300px'} width={'100%'} alignItems={'center'}
                           justifyContent={'center'} overflow={'hidden'}>
                        {renderDropZoneText()}
                        <IconButton color={"primary"} aria-label={"add"} size={"small"}
                                    onClick={handleOpenGlossaryClicked}>
                            <Input type="file" inputProps={{accept: '.xlsx'}} inputRef={openGlossaryFileElement}
                                   onChange={(e: any) => setFiles(e)} style={{display: 'none'}}/>
                            <AddIcon/>
                        </IconButton>
                    </Stack>
                </Stack>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={files.length === 0} onClick={handleSubmit}>
                        {glossaryNameExists ? 'Update' : 'Upload'}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}