import {dispatcher} from "../../Dispatcher";
import {isGlossaryNameExisted, uploadGlossary} from "../../../api/GlossaryApi";
import {glossaryListActions} from "../list/GlossaryListActions";
import Glossary from "../../../model/Glossary";
import {setEditedGlossary, setIsGlossaryExistedPayload} from "./GlossaryEditorPayload";
import {glossarySelectActions} from "../select/GlossarySelectActions";

export async function uploadGlossaryAction(name: string, file: File) {
    glossaryListActions.setIsLoading();

    await uploadGlossary(name, file);
    await glossaryListActions.refresh();
    await glossarySelectActions.refresh();
}

export async function setEditedGlossaryAction(glossary: Glossary) {
    dispatcher.dispatch(setEditedGlossary(glossary));
    await checkGlossaryNameIsExisted(glossary.name);
}

async function checkGlossaryNameIsExisted(name: string) {
    const exist = await isGlossaryNameExisted(name);
    dispatcher.dispatch(setIsGlossaryExistedPayload(exist));
}