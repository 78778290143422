import {ReduceStore} from "flux/utils";
import {SegmentEditorPayload, SegmentPayloadAction} from "./SegmentEditorPayload";
import Immutable from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import {Segment} from "../../../model/Segment";
import {Page} from "../../../model/Page";
import GlossaryPairSearchResult from "../../../model/GlossaryPairSearchResult";
import {CatFile} from "../../../model/CatFile";
import TranslationMemorySearchResult from "../../../model/TranslationMemorySearchResult";
import {Editor} from "slate";

interface ISegmentState {
    searchResults: Page<TranslationMemorySearchResult>,
    tmSuggest: TranslationMemorySearchResult | null,
    glossaryPairs: Page<GlossaryPairSearchResult>,
    selectedGlossaryPair: GlossaryPairSearchResult | null,
    segment: Segment | null,
    catFile: CatFile,
    targetEditor: Editor | null,
    languageCode: string
}

const SegmentStateFactory = Immutable.Record<ISegmentState>({
    searchResults: new Page<TranslationMemorySearchResult>(),
    tmSuggest: new TranslationMemorySearchResult(),
    glossaryPairs: new Page<GlossaryPairSearchResult>(),
    selectedGlossaryPair: null,
    segment: null,
    catFile: new CatFile(),
    targetEditor: null,
    languageCode: ""
});

export class SegmentState extends SegmentStateFactory {
}

export class SegmentEditorStore extends ReduceStore<SegmentState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): SegmentState {
        return new SegmentState();
    }

    reduce(state: SegmentState, payload: SegmentEditorPayload): SegmentState {
        switch (payload.action) {
            case SegmentPayloadAction.SetSearchResults:
                return state.set("searchResults", payload.searchResults);
            case SegmentPayloadAction.SelectTMSuggest:
                return state.set("tmSuggest", payload.tmSuggest);
            case SegmentPayloadAction.SetGlossaryPairs:
                return state.set("glossaryPairs", payload.glossaryPairs);
            case SegmentPayloadAction.SetSegment:
                if (payload.segment.id === state.segment?.id)
                    return state.set("segment", payload.segment)
                return state
                    .set("segment", payload.segment)
                    .set("catFile", payload.catFile)
                    .set("languageCode", payload.languageCode)
                    .set("searchResults", new Page<TranslationMemorySearchResult>())
                    .set("glossaryPairs", new Page<GlossaryPairSearchResult>())
                    .set("selectedGlossaryPair", null);
            case SegmentPayloadAction.SetTargetEditor:
                return state.set("targetEditor", payload.targetEditor);
            case SegmentPayloadAction.SelectGlossaryPair:
                return state.set("selectedGlossaryPair", payload.glossaryPair);
            default:
                return state;
        }
    }
}

const segmentStore = new SegmentEditorStore();
export default segmentStore;