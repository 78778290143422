import {dispatcher} from "../../Dispatcher";
import {setSelectedPlaceholdersSetId, setSelectPlaceholdersSetMenuOpen} from "./PlaceholdersSetSelectPayload";
import {fetchPlaceholdersSetListAction} from "../list/PlaceholdersSetListActions";
import {savePlaceholdersSetAction} from "../../project/page/ProjectPageActions";


export async function openPlaceholdersMenuAction(placeholdersSetId: number | null) {
    await fetchPlaceholdersSetListAction();
    dispatcher.dispatch(setSelectPlaceholdersSetMenuOpen(true));
    dispatcher.dispatch(setSelectedPlaceholdersSetId(placeholdersSetId));
}

export async function saveAndClosePlaceholdersMenuAction(
    projectId: number,
    selectedPlaceholdersSet: number | null,
    refuseSaveSegmentsWithPlaceholderErrors: boolean) {
    dispatcher.dispatch(setSelectedPlaceholdersSetId(null));
    dispatcher.dispatch(setSelectPlaceholdersSetMenuOpen(false));
    await savePlaceholdersSetAction(projectId, selectedPlaceholdersSet, refuseSaveSegmentsWithPlaceholderErrors);
}