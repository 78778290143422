import {Record} from "immutable";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {IFilter} from "../../model/Filter";


interface IUsersListFilter {
    onlySubordinate: boolean
}

const UsersListFilterRecord = Record<IUsersListFilter>({
    onlySubordinate: true,
});

export default class UsersListFilter extends UsersListFilterRecord implements IFilter<UsersListFilter> {
    updateWithGridFilterModel(_model: GridFilterModel) {
        return this;
    }

    updateWithGridSortingModel(_model: GridSortModel) {
        return this;
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }
}