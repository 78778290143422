import {ReduceStore} from "flux/utils";
import {PlaceholdersSetEditorAction, PlaceholdersSetEditorPayload} from "./PlaceholdersSetEditorPayload";
import Immutable from "immutable";
import PlaceholdersSet from "../../../model/PlaceholdersSet";
import {dispatcher, Payload} from "../../Dispatcher";

interface IPlaceholdersSetEditorState {
    placeholdersSet: PlaceholdersSet | null
}

const PlaceholdersSetEditorStateFactory = Immutable.Record<IPlaceholdersSetEditorState>({
    placeholdersSet: null
});

class PlaceholdersSetEditorState extends PlaceholdersSetEditorStateFactory {
}

export class PlaceholdersSetEditorStore extends ReduceStore<PlaceholdersSetEditorState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): PlaceholdersSetEditorState {
        return new PlaceholdersSetEditorState();
    }

    reduce(state: PlaceholdersSetEditorState, payload: PlaceholdersSetEditorPayload): PlaceholdersSetEditorState {
        switch (payload.action) {
            case PlaceholdersSetEditorAction.SetPlaceholdersSet:
                return state.set("placeholdersSet", payload.placeholdersSet);
            default:
                return state;
        }
    }
}

const placeholdersSetEditorStore = new PlaceholdersSetEditorStore();
export default placeholdersSetEditorStore;