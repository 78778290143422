import RollbackFilter from "./RollbackFilter";

export enum RollbackPayloadAction {
    SetInProgress = "RollbackPayloadAction.SetInProgress",
    SetFilter = "RollbackPayloadAction.SetInFilter"
}

interface ISetRollbackInProgressPayload {
    action: RollbackPayloadAction.SetInProgress,
    inProgress: boolean
}

interface ISetRollbackFilterPayload {
    action: RollbackPayloadAction.SetFilter,
    filter: RollbackFilter
}

export type RollbackPayload = ISetRollbackInProgressPayload
    | ISetRollbackFilterPayload

export function rollbackInProgressPayload(inProgress: boolean): RollbackPayload {
    return {
        action: RollbackPayloadAction.SetInProgress,
        inProgress: inProgress
    }
}

export function setRollbackFilterPayload(filter: RollbackFilter): RollbackPayload {
    return {
        action: RollbackPayloadAction.SetFilter,
        filter: filter
    }
}
