import {CommonListActions} from "../common/list/CommonListActions";
import NullFilter from "../common/NullFilter";
import {AvailableResourceType} from "../../model/AvailableResources";
import {deleteVendors, fetchVendors, saveVendor} from "../../api/VendorApi";
import {Vendor} from "../../model/Vendor";

export const vendorListActions = new CommonListActions<Vendor, NullFilter>(
    fetchVendors,
    deleteVendors,
    saveVendor,
    AvailableResourceType.Vendors
);